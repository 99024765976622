
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { TrendingUp } from "lucide-react";

interface MonthlyPerformanceCardProps {
  totalSales: number;
  totalProfit: number;
  isLoading: boolean;
}

export const MonthlyPerformanceCard = ({ 
  totalSales, 
  totalProfit, 
  isLoading 
}: MonthlyPerformanceCardProps) => {
  if (isLoading) {
    return (
      <Card className="bg-dark-card border-dark-border h-full">
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-lg font-medium text-gray-400">
            Monthly Performance
          </CardTitle>
          <TrendingUp className="h-4 w-4 text-gray-400" />
        </CardHeader>
        <CardContent className="space-y-2">
          <div className="animate-pulse space-y-2">
            <div className="h-4 w-24 bg-gray-700 rounded" />
            <div className="h-4 w-24 bg-gray-700 rounded" />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="bg-dark-card border-dark-border h-full">
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="text-lg font-medium text-gray-400">
          Monthly Performance
        </CardTitle>
        <TrendingUp className="h-4 w-4 text-gray-400" />
      </CardHeader>
      <CardContent className="space-y-2">
        <div className="flex justify-between">
          <span className="text-gray-400">Sales</span>
          <span className="text-white">
            ${totalSales.toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-400">Profit</span>
          <span className={totalProfit >= 0 ? "text-green-500" : "text-red-500"}>
            ${Math.abs(totalProfit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};
