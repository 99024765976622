import { Tables } from "@/integrations/supabase/types";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { StreamSummaryMetric } from "./StreamSummaryMetric";
import { calculateStreamFinancials } from "@/utils/streamFinancials";

type Stream = Tables<"streams">;

interface StreamSummaryCardProps {
  streams: Stream[];
}

export const StreamSummaryCard = ({ streams }: StreamSummaryCardProps) => {
  const { data: settings } = useQuery({
    queryKey: ['user-settings'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: allStreamItems } = useQuery({
    queryKey: ['all-stream-items'],
    enabled: streams.length > 0,
    queryFn: async () => {
      const streamIds = streams.map(s => s.id);
      const { data, error } = await supabase
        .from('stream_items')
        .select(`
          *,
          inventory_item:inventory_items(*)
        `)
        .in('stream_id', streamIds);

      if (error) throw error;
      return data;
    },
  });

  const getStreamItems = (streamId: string) => {
    if (!allStreamItems) return [];
    return allStreamItems.filter(item => item.stream_id === streamId);
  };

  // Calculate financials for each stream using the same method as StreamMonthGroup
  const streamFinancials = streams.map(stream => {
    const streamItems = getStreamItems(stream.id);
    const totalProductCosts = streamItems.reduce((sum, item) => {
      const costBasis = Number(item.inventory_item?.cost_basis) || 0;
      return sum + ((Number(item.quantity_sold) || 0) * costBasis);
    }, 0);

    const streamDurationHours = stream.start_time && stream.end_time
      ? Math.ceil((new Date(stream.end_time).getTime() - new Date(stream.start_time).getTime()) / (1000 * 60 * 60))
      : 0;

    return {
      stream,
      financials: calculateStreamFinancials({
        totalSales: Number(stream.total_sales || 0),
        productCosts: totalProductCosts,
        platform: stream.platform,
        settings,
        streamDurationHours
      })
    };
  });

  // Calculate totals using the same method as StreamMonthGroup
  const totals = streamFinancials.reduce((acc, { stream, financials }) => ({
    totalSales: acc.totalSales + Number(stream.total_sales || 0),
    totalProductCosts: acc.totalProductCosts + financials.productCosts,
    netProfit: acc.netProfit + financials.netProfit
  }), { totalSales: 0, totalProductCosts: 0, netProfit: 0 });

  return (
    <div className="grid grid-cols-3 gap-6 p-6 bg-dark-card rounded-lg">
      <StreamSummaryMetric 
        label="Total Sales" 
        value={totals.totalSales} 
      />
      <StreamSummaryMetric 
        label="Total Product Cost" 
        value={totals.totalProductCosts} 
        type="negative" 
      />
      <StreamSummaryMetric 
        label="Net Profit" 
        value={totals.netProfit} 
        type={totals.netProfit >= 0 ? 'positive' : 'negative'} 
      />
    </div>
  );
};