
import { useState, useEffect } from "react";
import { WholesaleCustomer, WholesaleOrder, WholesaleOrderWithDetails, PaymentTerms, PaymentMethod, OrderStatus, PaymentStatus, WholesaleOrderItem } from "./types";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { 
  Select, 
  SelectTrigger, 
  SelectValue, 
  SelectContent, 
  SelectItem 
} from "@/components/ui/select";
import { InventorySelector } from "./InventorySelector";
import { InventoryItem } from "@/hooks/useInventoryData";
import { CustomerSelector } from "./order/CustomerSelector";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

interface OrderFormData {
  order: Partial<WholesaleOrder>;
  items?: Array<Partial<WholesaleOrderItem>>;
}

interface OrderFormProps {
  order?: WholesaleOrderWithDetails;
  customers: WholesaleCustomer[];
  onSubmit: (data: OrderFormData) => Promise<void>;
  onCancel: () => void;
}

export const OrderForm = ({ 
  order, 
  customers, 
  onSubmit, 
  onCancel 
}: OrderFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedItems, setSelectedItems] = useState<
    Array<{inventoryItem: InventoryItem, quantity: number, price: number}>
  >(
    order?.items?.map(item => ({
      inventoryItem: item.inventory_item as InventoryItem,
      quantity: item.quantity || 1,
      price: Number(item.price_per_unit) || 0
    })) || []
  );
  
  const [showCustomDueDate, setShowCustomDueDate] = useState(false);
  const [dueDate, setDueDate] = useState<Date | undefined>(undefined);
  const [calendarOpen, setCalendarOpen] = useState(false);

  // State for dropdowns
  const [paymentTermsOpen, setPaymentTermsOpen] = useState(false);
  const [paymentMethodOpen, setPaymentMethodOpen] = useState(false);
  const [paymentStatusOpen, setPaymentStatusOpen] = useState(false);

  const isEditing = !!order;

  const form = useForm<Partial<WholesaleOrder>>({
    defaultValues: {
      customer_id: order?.customer_id || "",
      payment_terms: order?.payment_terms || "net30",
      payment_method: order?.payment_method || "check",
      payment_status: order?.payment_status || "pending",
      tracking_number: order?.tracking_number || "",
      notes: order?.notes || "",
    },
  });

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === 'payment_terms') {
        setShowCustomDueDate(value.payment_terms === 'custom');
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const calculateTotal = () => {
    return selectedItems.reduce((sum, item) => {
      return sum + (item.quantity * item.price);
    }, 0);
  };

  const calculateProfit = () => {
    return selectedItems.reduce((sum, item) => {
      const costPerUnit = Number(item.inventoryItem.cost_basis) || 0;
      const salePrice = item.price;
      const profit = (salePrice - costPerUnit) * item.quantity;
      return sum + profit;
    }, 0);
  };

  const handleSubmit = async (formData: Partial<WholesaleOrder>) => {
    try {
      setIsSubmitting(true);

      const orderItems = selectedItems.map(item => ({
        inventory_item_id: item.inventoryItem.id,
        quantity: item.quantity,
        price_per_unit: item.price
      }));

      const total = calculateTotal();

      let finalFormData = { ...formData };
      if (formData.payment_terms === 'custom' && dueDate) {
        finalFormData.notes = `${finalFormData.notes || ''}\nDue date: ${format(dueDate, 'PP')}`;
      }

      await onSubmit({
        order: {
          ...finalFormData,
          total_amount: total,
          status: "confirmed"
        },
        items: orderItems
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-white">
            {isEditing ? "Edit Order" : "Create New Order"}
          </h2>

          <FormField
            control={form.control}
            name="customer_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Customer *</FormLabel>
                <FormControl>
                  <CustomerSelector
                    selectedCustomerId={field.value}
                    onSelect={field.onChange}
                    disabled={isSubmitting}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <FormField
              control={form.control}
              name="payment_terms"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Terms</FormLabel>
                  <Popover open={paymentTermsOpen} onOpenChange={setPaymentTermsOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={paymentTermsOpen}
                        onClick={() => setPaymentTermsOpen(!paymentTermsOpen)}
                        className="w-full justify-between bg-background border-input"
                        disabled={isSubmitting}
                      >
                        {field.value ? (
                          field.value.charAt(0).toUpperCase() + field.value.slice(1)
                        ) : (
                          "Select terms"
                        )}
                        <span className="sr-only">Toggle payment terms</span>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0 bg-popover border-input z-50">
                      <div className="w-full bg-background border-0 p-2 rounded-md">
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("net30");
                            setShowCustomDueDate(false);
                            setPaymentTermsOpen(false);
                          }}
                        >
                          Net 30
                        </div>
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("net14");
                            setShowCustomDueDate(false);
                            setPaymentTermsOpen(false);
                          }}
                        >
                          Net 14
                        </div>
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("net7");
                            setShowCustomDueDate(false);
                            setPaymentTermsOpen(false);
                          }}
                        >
                          Net 7
                        </div>
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("upfront");
                            setShowCustomDueDate(false);
                            setPaymentTermsOpen(false);
                          }}
                        >
                          Upfront Payment
                        </div>
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("custom");
                            setShowCustomDueDate(true);
                            setPaymentTermsOpen(false);
                          }}
                        >
                          Custom
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            {showCustomDueDate && (
              <FormItem className="flex flex-col">
                <FormLabel>Due Date</FormLabel>
                <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={`w-full justify-start text-left font-normal bg-background ${!dueDate && "text-muted-foreground"}`}
                      disabled={isSubmitting}
                      onClick={() => setCalendarOpen(!calendarOpen)}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {dueDate ? format(dueDate, "PPP") : "Select due date"}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 bg-popover border-input z-50" align="start">
                    <Calendar
                      mode="single"
                      selected={dueDate}
                      onSelect={(date) => {
                        setDueDate(date);
                        setCalendarOpen(false);
                      }}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </FormItem>
            )}

            <FormField
              control={form.control}
              name="payment_method"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Method</FormLabel>
                  <Popover open={paymentMethodOpen} onOpenChange={setPaymentMethodOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={paymentMethodOpen}
                        onClick={() => setPaymentMethodOpen(!paymentMethodOpen)}
                        className="w-full justify-between bg-background border-input"
                        disabled={isSubmitting}
                      >
                        {field.value ? (
                          field.value.charAt(0).toUpperCase() + field.value.slice(1)
                        ) : (
                          "Select method"
                        )}
                        <span className="sr-only">Toggle payment method</span>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0 bg-popover border-input z-50">
                      <div className="w-full bg-background border-0 p-2 rounded-md">
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("check");
                            setPaymentMethodOpen(false);
                          }}
                        >
                          Check
                        </div>
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("wire");
                            setPaymentMethodOpen(false);
                          }}
                        >
                          Wire Transfer
                        </div>
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("paypal");
                            setPaymentMethodOpen(false);
                          }}
                        >
                          PayPal
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="payment_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Payment Status</FormLabel>
                  <Popover open={paymentStatusOpen} onOpenChange={setPaymentStatusOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={paymentStatusOpen}
                        onClick={() => setPaymentStatusOpen(!paymentStatusOpen)}
                        className="w-full justify-between bg-background border-input"
                        disabled={isSubmitting}
                      >
                        {field.value ? (
                          field.value.charAt(0).toUpperCase() + field.value.slice(1)
                        ) : (
                          "Select status"
                        )}
                        <span className="sr-only">Toggle payment status</span>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0 bg-popover border-input z-50">
                      <div className="w-full bg-background border-0 p-2 rounded-md">
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("pending");
                            setPaymentStatusOpen(false);
                          }}
                        >
                          Pending
                        </div>
                        <div
                          className="cursor-pointer hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
                          onClick={() => {
                            field.onChange("paid");
                            setPaymentStatusOpen(false);
                          }}
                        >
                          Paid
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="tracking_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Tracking Number (Optional)</FormLabel>
                <FormControl>
                  <Input 
                    placeholder="Enter tracking number" 
                    {...field} 
                    disabled={isSubmitting}
                    className="bg-background"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Notes</FormLabel>
                <FormControl>
                  <Textarea 
                    placeholder="Order notes or special instructions" 
                    {...field} 
                    disabled={isSubmitting}
                    className="bg-background"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="mt-6">
            <h3 className="text-lg font-medium text-white mb-2">Order Items</h3>
            <InventorySelector 
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          </div>

          <div className="bg-dark-muted p-4 rounded-md space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-white font-medium">Order Total:</span>
              <span className="text-xl font-bold text-white">
                ${calculateTotal().toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-white font-medium">Estimated Profit:</span>
              <span className="text-lg font-semibold text-green-500">
                ${calculateProfit().toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <Button
            type="button"
            variant="outline"
            onClick={onCancel}
            disabled={isSubmitting}
            className="bg-background"
          >
            Cancel
          </Button>
          <Button 
            type="submit" 
            disabled={isSubmitting || selectedItems.length === 0 || !form.watch("customer_id")}
          >
            {isSubmitting 
              ? "Saving..." 
              : isEditing 
                ? "Update Order" 
                : "Create Order"
            }
          </Button>
        </div>
      </form>
    </Form>
  );
};
