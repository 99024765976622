import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { startOfMonth, endOfMonth } from "date-fns";
import { calculateStreamFinancials } from "@/utils/streamFinancials";

export const useMonthlyPerformance = () => {
  const { data: settings } = useQuery({
    queryKey: ['user-settings'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: monthlyPerformance, isLoading } = useQuery({
    queryKey: ['monthlyPerformance', settings],
    enabled: !!settings,
    queryFn: async () => {
      const now = new Date();
      const startDate = startOfMonth(now);
      const endDate = endOfMonth(now);

      // RLS policies will automatically filter streams based on user role
      const { data: streams, error: streamsError } = await supabase
        .from('streams')
        .select(`
          *,
          stream_items (
            quantity_sold,
            sale_price,
            inventory_item:inventory_items (
              cost_basis
            )
          )
        `)
        .gte('start_time', startDate.toISOString())
        .lte('start_time', endDate.toISOString());

      if (streamsError) throw streamsError;

      if (!streams?.length) {
        return { totalSales: 0, netProfit: 0 };
      }

      return streams.reduce((acc, stream) => {
        const totalSales = Number(stream.total_sales || 0);
        
        const productCosts = (stream.stream_items || []).reduce((sum, item) => {
          const costBasis = Number(item.inventory_item?.cost_basis || 0);
          const quantitySold = Number(item.quantity_sold || 0);
          return sum + (costBasis * quantitySold);
        }, 0);

        const streamDurationHours = stream.start_time && stream.end_time
          ? (new Date(stream.end_time).getTime() - new Date(stream.start_time).getTime()) / (1000 * 60 * 60)
          : 0;

        const financials = calculateStreamFinancials({
          totalSales,
          productCosts,
          platform: stream.platform,
          settings,
          streamDurationHours
        });

        return {
          totalSales: acc.totalSales + totalSales,
          netProfit: acc.netProfit + financials.netProfit
        };
      }, { totalSales: 0, netProfit: 0 });
    }
  });

  return { monthlyPerformance, isLoading };
};