import { Upload, Box, BarChart3, ClipboardCheck, Calculator, LineChart } from "lucide-react";
import { FeatureCard } from "./FeatureCard";

export const FeatureGrid = () => {
  const features = [
    {
      icon: Upload,
      title: "Smart Inventory Management",
      description: "Upload inventory via CSV, scan invoices with OCR, or add items manually. Automatically calculate average costs and track product history.",
      features: [
        "Bulk CSV imports",
        "OCR invoice scanning",
        "Automated cost averaging",
        "Category management",
        "Product history tracking"
      ]
    },
    {
      icon: Box,
      title: "Real-time Stream Integration",
      description: "Seamlessly manage inventory during streams. Automatic updates ensure your stock levels are always accurate.",
      features: [
        "Live inventory updates",
        "Automated stock deductions",
        "Platform fee calculations",
        "Shipping cost tracking",
        "Multi-platform support"
      ]
    },
    {
      icon: BarChart3,
      title: "Comprehensive Analytics",
      description: "Track performance metrics across your entire business. Identify trends and make data-driven decisions.",
      features: [
        "Best-selling products",
        "Top-performing streams",
        "Profit margin analysis",
        "Inventory turnover rates",
        "Sales trend tracking"
      ]
    },
    {
      icon: ClipboardCheck,
      title: "Streamlined Inventory Checks",
      description: "Make inventory audits a breeze with our guided checking system. Identify and resolve discrepancies quickly.",
      features: [
        "Guided inventory audits",
        "Discrepancy tracking",
        "Auto-archiving",
        "History logging"
      ]
    },
    {
      icon: Calculator,
      title: "Automated Calculations",
      description: "Let CardQuant handle the math. Automatic calculations for costs, fees, and profits across all your operations.",
      features: [
        "Average cost tracking",
        "Platform fee calculations",
        "Profit margin analysis",
        "Shipping cost management"
      ]
    },
    {
      icon: LineChart,
      title: "Business Intelligence",
      description: "Make informed decisions with comprehensive reporting and analytics tools designed for card breaking.",
      features: [
        "Performance dashboards",
        "Custom reports",
        "Trend analysis",
        "Inventory forecasting"
      ]
    }
  ];

  return (
    <div className="relative z-10 container mx-auto px-4 py-20 max-w-[1920px]">
      <h2 className="text-2xl md:text-3xl font-bold text-center mb-4">Everything You Need to Run Your Breaking Business</h2>
      <p className="text-gray-400 text-center mb-12">Powerful tools designed specifically for sports card breakers, streamers, and shop owners</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-8">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};
