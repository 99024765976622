
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useState } from "react";
import { 
  Table, TableBody, TableCell, TableHead, 
  TableHeader, TableRow 
} from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const SalesBreakdownTable = () => {
  const [sortColumn, setSortColumn] = useState("total_sales");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const { data: salesData, isLoading } = useQuery({
    queryKey: ['analytics-sales-breakdown'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('streams')
        .select(`
          id,
          start_time,
          streamer,
          platform,
          total_sales,
          stream_items (
            quantity_sold,
            inventory_item:inventory_items (
              id,
              name,
              cost_basis
            )
          )
        `)
        .order('start_time', { ascending: false });
      
      if (error) throw error;
      
      return data.map((stream: any) => {
        const itemsSold = stream.stream_items.reduce((sum: number, item: any) => {
          return sum + (Number(item.quantity_sold) || 0);
        }, 0);
        
        const productCosts = stream.stream_items.reduce((sum: number, item: any) => {
          const costBasis = Number(item.inventory_item?.cost_basis) || 0;
          return sum + ((Number(item.quantity_sold) || 0) * costBasis);
        }, 0);
        
        const profit = Number(stream.total_sales) - productCosts;
        
        return {
          id: stream.id,
          date: new Date(stream.start_time).toLocaleDateString(),
          streamer: stream.streamer,
          platform: stream.platform,
          itemsSold,
          total_sales: Number(stream.total_sales),
          productCosts,
          profit
        };
      });
    }
  });

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedData = salesData ? [...salesData].sort((a, b) => {
    const directionModifier = sortDirection === "asc" ? 1 : -1;
    
    if (a[sortColumn as keyof typeof a] < b[sortColumn as keyof typeof b]) {
      return -1 * directionModifier;
    }
    if (a[sortColumn as keyof typeof a] > b[sortColumn as keyof typeof b]) {
      return 1 * directionModifier;
    }
    return 0;
  }) : [];

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader>
        <CardTitle className="text-lg font-medium text-gray-400">
          Sales Breakdown
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer"
                  onClick={() => handleSort("date")}
                >
                  Date
                  {sortColumn === "date" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer"
                  onClick={() => handleSort("streamer")}
                >
                  Streamer
                  {sortColumn === "streamer" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer"
                  onClick={() => handleSort("platform")}
                >
                  Platform
                  {sortColumn === "platform" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("itemsSold")}
                >
                  Items Sold
                  {sortColumn === "itemsSold" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("total_sales")}
                >
                  Sales
                  {sortColumn === "total_sales" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("productCosts")}
                >
                  Costs
                  {sortColumn === "productCosts" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("profit")}
                >
                  Profit
                  {sortColumn === "profit" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-4">
                    <div className="flex justify-center">
                      <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : sortedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-4 text-gray-400">
                    No sales data available
                  </TableCell>
                </TableRow>
              ) : (
                sortedData.map((item) => (
                  <TableRow key={item.id} className="border-t border-dark-border">
                    <TableCell className="text-white">{item.date}</TableCell>
                    <TableCell className="text-white">{item.streamer}</TableCell>
                    <TableCell className="text-white">{item.platform}</TableCell>
                    <TableCell className="text-white text-right">{item.itemsSold}</TableCell>
                    <TableCell className="text-white text-right">
                      ${item.total_sales.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell className="text-red-500 text-right">
                      -${item.productCosts.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell className={`text-right ${item.profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      ${Math.abs(item.profit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};
