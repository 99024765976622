import { Button } from "@/components/ui/button";

interface LandingNavProps {
  onSignIn: () => void;
  onSignUp: () => void;
}

export const LandingNav = ({ onSignIn, onSignUp }: LandingNavProps) => {
  return (
    <nav className="fixed w-full bg-[#060606]/95 backdrop-blur-sm z-50">
      <div className="container mx-auto px-4 lg:px-8 max-w-[1920px]">
        <div className="flex justify-between items-center h-24">
          <div className="flex items-center">
            <img 
              src="/lovable-uploads/06222c28-dc26-4689-90c3-e5f51b382a30.png" 
              alt="CardQuant Logo" 
              className="h-16 w-auto object-contain" 
            />
          </div>
          <div className="flex items-center space-x-4 md:space-x-8">
            <button className="text-gray-300 hover:text-white hidden sm:block">Features</button>
            <button className="text-gray-300 hover:text-white hidden sm:block">Pricing</button>
            <button 
              onClick={onSignIn}
              className="text-gray-300 hover:text-white"
            >
              Sign in
            </button>
            <Button
              onClick={onSignUp}
              variant="outline"
              className="bg-white text-black hover:bg-gray-100"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
};