import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useEffect } from "react";
import { useProfile } from "./useProfile";

export const useDashboardData = () => {
  const queryClient = useQueryClient();
  const { data: profile, isLoading: isProfileLoading } = useProfile();

  useEffect(() => {
    console.log('Setting up dashboard data subscription...');
    const channel = supabase
      .channel('dashboard-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'inventory_items'
        },
        (payload) => {
          console.log('Inventory items changed:', payload);
          // Use invalidateQueries with exact: true to prevent unnecessary invalidations
          queryClient.invalidateQueries({
            queryKey: ['dashboard-inventory'],
            exact: true
          });
          queryClient.invalidateQueries({
            queryKey: ['inventory-items'],
            exact: true
          });
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'inventory_check_reports'
        },
        (payload) => {
          console.log('Inventory check reports changed:', payload);
          queryClient.invalidateQueries({
            queryKey: ['lastInventoryCheck'],
            exact: true
          });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [queryClient]);

  const { data: inventoryItems = [], error: inventoryError, isLoading: isInventoryLoading } = useQuery({
    queryKey: ['dashboard-inventory', profile?.organization_id],
    enabled: !!profile?.organization_id && !isProfileLoading,
    queryFn: async () => {
      try {
        console.log('Fetching inventory items for organization:', profile?.organization_id);

        const { data, error } = await supabase
          .from('inventory_items')
          .select('*')
          .eq('organization_id', profile?.organization_id);

        if (error) {
          console.error('Error fetching inventory items:', error);
          throw error;
        }

        console.log('Fetched inventory items:', data?.length || 0, 'items');
        return data || [];
      } catch (error) {
        console.error('Error fetching inventory:', error);
        throw error;
      }
    },
    staleTime: 30000, // 30 seconds
    gcTime: 3600000, // 1 hour
  });

  const { data: lastCheck, error: lastCheckError, isLoading: isLastCheckLoading } = useQuery({
    queryKey: ['lastInventoryCheck'],
    enabled: !isProfileLoading,
    queryFn: async () => {
      console.log('Fetching last inventory check across all organizations');

      const { data: reports, error } = await supabase
        .from('inventory_check_reports')
        .select('*')
        .order('report_date', { ascending: false })
        .limit(1);

      if (error) {
        console.error('Error fetching last check:', error);
        throw error;
      }

      const lastReport = reports?.[0] || null;
      console.log('Last check report:', lastReport);
      return lastReport;
    },
    staleTime: 30000, // 30 seconds
    gcTime: 3600000, // 1 hour
  });

  return {
    inventoryItems,
    lastCheck,
    inventoryError,
    lastCheckError,
    isLoading: isInventoryLoading || isLastCheckLoading || isProfileLoading
  };
};