
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { useState } from "react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useInventoryQueries } from "@/hooks/useInventoryQueries";
import { InventoryTableHeader } from "./table/InventoryTableHeader";
import { InventoryTableRow } from "./table/InventoryTableRow";
import { BulkActionsMenu } from "./table/BulkActionsMenu";
import { useInventoryData, InventoryItem } from "@/hooks/useInventoryData";
import { calculateCategoryValue } from "@/utils/inventoryCalculations";

interface InventoryTableProps {
  items: InventoryItem[];
  category: string;
  totalValue: number;
  onReorder?: (draggedCategory: string, targetCategory: string) => void;
  index?: number;
}

export const InventoryTable = ({ 
  items, 
  category, 
  totalValue,
  onReorder,
  index
}: InventoryTableProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const { invalidateAllInventoryQueries } = useInventoryQueries();
  const { data: inventoryData } = useInventoryData();

  // Log incoming items for debugging
  console.log('InventoryTable received items:', {
    category,
    itemCount: items.length,
    totalValue
  });

  // Filter items based on their status, but don't filter out archived items
  const displayItems = items.filter(item => {
    const shouldDisplay = item.status !== 'awaiting' && item.status !== 'category_marker';
    if (!shouldDisplay) {
      console.log('Item filtered out from display:', {
        name: item.name,
        status: item.status,
        reason: 'awaiting or category_marker'
      });
    }
    return shouldDisplay;
  });

  console.log('DisplayItems after filtering:', {
    category,
    displayItemCount: displayItems.length,
    items: displayItems.map(item => ({
      name: item.name,
      status: item.status,
      quantity: item.quantity,
      cost_basis: item.cost_basis,
      value: Number(item.quantity || 0) * Number(item.cost_basis || 0)
    }))
  });

  // Don't render the table if this is just a category marker with no items
  if (displayItems.length === 0) {
    console.log('Not rendering table for category:', category, 'because no display items');
    return null;
  }

  const calculateItemValue = (item: InventoryItem) => {
    return Number(item.quantity || 0) * Number(item.cost_basis || 0);
  };

  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData('text/plain', category);
    setIsDragging(true);
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.style.opacity = '0.5';
    }
  };

  const handleDragEnd = (e: React.DragEvent) => {
    setIsDragging(false);
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.style.opacity = '1';
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.style.borderTop = '2px solid #4f46e5';
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.style.borderTop = '';
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.currentTarget instanceof HTMLElement) {
      e.currentTarget.style.borderTop = '';
    }
    
    const draggedCategory = e.dataTransfer.getData('text/plain');
    if (draggedCategory !== category && onReorder) {
      onReorder(draggedCategory, category);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedItems(displayItems.map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (itemId: string, checked: boolean) => {
    if (checked) {
      setSelectedItems(prev => [...prev, itemId]);
    } else {
      setSelectedItems(prev => prev.filter(id => id !== itemId));
    }
  };

  const handleUpdateCategory = async (newCategory: string) => {
    setIsUpdating(true);
    try {
      const { error } = await supabase
        .from('inventory_items')
        .update({ category: newCategory })
        .in('id', selectedItems);

      if (error) throw error;

      await invalidateAllInventoryQueries();
      toast.success(`Updated category for ${selectedItems.length} items`);
      setSelectedItems([]);
    } catch (error) {
      console.error('Error updating categories:', error);
      toast.error('Failed to update categories');
    } finally {
      setIsUpdating(false);
    }
  };

  // Get all unique categories from all inventory items
  const allCategories = inventoryData?.items 
    ? Array.from(new Set(inventoryData.items.map(item => item.category || 'Uncategorized')))
    : [];

  // Recalculate the total value in case it wasn't calculated correctly
  // Fixed: Pass the correct type to calculateCategoryValue
  const recalculatedTotalValue = calculateCategoryValue(displayItems);

  return (
    <div 
      className={`rounded-lg bg-dark-card border border-dark-border p-4 mt-4 ${isDragging ? 'opacity-50' : ''}`}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <InventoryTableHeader
        category={category}
        itemCount={displayItems.length}
        totalValue={recalculatedTotalValue > 0 ? recalculatedTotalValue : totalValue}
        isExpanded={isExpanded}
        onToggleExpand={() => setIsExpanded(!isExpanded)}
        isDragging={isDragging}
      />

      {isExpanded && (
        <>
          <BulkActionsMenu
            selectedItems={selectedItems}
            categories={allCategories}
            onUpdateCategory={handleUpdateCategory}
            onClose={() => setSelectedItems([])}
          />

          <Table>
            <TableHeader>
              <TableRow className="border-dark-border">
                <TableHead className="w-12">
                  <Checkbox 
                    checked={selectedItems.length === displayItems.length}
                    onCheckedChange={handleSelectAll}
                    disabled={isUpdating}
                  />
                </TableHead>
                <TableHead className="text-white font-semibold">Name</TableHead>
                <TableHead className="text-white font-semibold">Quantity</TableHead>
                <TableHead className="text-white font-semibold">Cost</TableHead>
                <TableHead className="text-white font-semibold">Total Value</TableHead>
                <TableHead className="text-white font-semibold">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {displayItems.map((item) => (
                <InventoryTableRow
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  quantity={item.quantity}
                  costBasis={item.cost_basis}
                  totalValue={calculateItemValue(item)}
                  isSelected={selectedItems.includes(item.id)}
                  onSelect={(checked) => handleSelectItem(item.id, checked)}
                />
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </div>
  );
};
