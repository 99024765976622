import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { Tables } from "@/integrations/supabase/types";

type InventoryItem = Tables<"inventory_items">;

interface InventoryItemProps {
  item: InventoryItem;
  onAdd: (item: InventoryItem) => void;
}

export const InventoryItem = ({ item, onAdd }: InventoryItemProps) => {
  return (
    <div className="flex items-center justify-between p-3 bg-dark-card border border-dark-border rounded-lg">
      <div className="flex-1">
        <span className="text-white">{item.name}</span>
        <div className="text-sm text-gray-400">
          Stock: {item.quantity} | Cost: ${Number(item.cost_basis).toLocaleString()}
        </div>
      </div>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => onAdd(item)}
        className="text-white hover:text-white hover:bg-dark-muted"
      >
        <Plus className="w-4 h-4 mr-1" /> Add
      </Button>
    </div>
  );
};