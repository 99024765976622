
import { WholesaleOrderWithDetails, OrderStatus, PaymentStatus } from "./types";
import { Button } from "@/components/ui/button";
import { 
  Edit, ArrowLeft, CheckCircle, Clock, AlertCircle, 
  Truck, Package, ShoppingBag, DollarSign 
} from "lucide-react";
import { format } from "date-fns";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { useWholesaleOrders } from "@/hooks/useWholesaleOrders";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface OrderDetailsProps {
  order: WholesaleOrderWithDetails;
  onClose: () => void;
  onEdit: () => void;
}

export const OrderDetails = ({ 
  order, 
  onClose, 
  onEdit 
}: OrderDetailsProps) => {
  const { updateOrder } = useWholesaleOrders();
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingTrackingNumber, setUpdatingTrackingNumber] = useState(false);
  const [newStatus, setNewStatus] = useState<OrderStatus>(order.status as OrderStatus || 'draft');
  const [newPaymentStatus, setNewPaymentStatus] = useState<PaymentStatus>(order.payment_status as PaymentStatus || 'pending');
  const [newTrackingNumber, setNewTrackingNumber] = useState(order.tracking_number || '');

  // Format the customer's address from individual components
  const formatCustomerAddress = (customer: WholesaleOrderWithDetails['customer']) => {
    if (!customer) return null;

    const addressParts = [];
    if (customer.street_address) addressParts.push(customer.street_address);
    
    const cityStateZip = [];
    if (customer.city) cityStateZip.push(customer.city);
    if (customer.state) cityStateZip.push(customer.state);
    if (customer.zip_code) cityStateZip.push(customer.zip_code);
    
    if (cityStateZip.length > 0) {
      addressParts.push(cityStateZip.join(', '));
    }
    
    return addressParts.length > 0 ? addressParts.join('\n') : null;
  };

  const getStatusIcon = (status: OrderStatus) => {
    switch (status) {
      case "confirmed":
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case "shipped":
        return <Truck className="h-5 w-5 text-blue-500" />;
      case "delivered":
        return <Package className="h-5 w-5 text-green-700" />;
      case "cancelled":
        return <AlertCircle className="h-5 w-5 text-red-500" />;
      default:
        return <Clock className="h-5 w-5 text-gray-400" />;
    }
  };

  const getPaymentIcon = (status: PaymentStatus) => {
    switch (status) {
      case "paid":
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case "partial":
        return <DollarSign className="h-5 w-5 text-yellow-500" />;
      case "overdue":
        return <AlertCircle className="h-5 w-5 text-red-500" />;
      default:
        return <Clock className="h-5 w-5 text-gray-400" />;
    }
  };

  const handleUpdateStatus = async () => {
    try {
      setUpdatingStatus(true);
      await updateOrder.mutateAsync({
        orderId: order.id,
        orderData: {
          status: newStatus,
          payment_status: newPaymentStatus
        }
      });
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleUpdateTrackingNumber = async () => {
    try {
      setUpdatingTrackingNumber(true);
      await updateOrder.mutateAsync({
        orderId: order.id,
        orderData: {
          tracking_number: newTrackingNumber,
          // Auto-update status to shipped if tracking added
          status: newTrackingNumber && order.status !== 'shipped' && order.status !== 'delivered' 
            ? 'shipped' 
            : order.status
        }
      });
    } finally {
      setUpdatingTrackingNumber(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Button 
            variant="ghost" 
            size="icon"
            onClick={onClose}
            className="h-8 w-8"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h2 className="text-xl font-semibold text-white">
            Order Details
          </h2>
        </div>
        <Button 
          variant="outline" 
          size="sm"
          onClick={onEdit}
          className="gap-1 border-dark-border"
        >
          <Edit className="h-4 w-4" />
          <span>Edit Order</span>
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="bg-dark-muted p-4 rounded-lg space-y-2">
            <h3 className="text-white font-medium">Customer</h3>
            <p className="text-lg text-white">{order.customer?.name}</p>
            {order.customer?.email && (
              <p className="text-gray-400">{order.customer.email}</p>
            )}
            {order.customer?.phone && (
              <p className="text-gray-400">{order.customer.phone}</p>
            )}
            {formatCustomerAddress(order.customer) && (
              <p className="text-gray-400 whitespace-pre-line">{formatCustomerAddress(order.customer)}</p>
            )}
          </div>

          <div className="bg-dark-muted p-4 rounded-lg space-y-2">
            <h3 className="text-white font-medium">Order Information</h3>
            <div className="grid grid-cols-2 gap-2">
              <p className="text-gray-400">Order Date:</p>
              <p className="text-white">
                {format(new Date(order.order_date), "MMMM d, yyyy")}
              </p>
              
              <p className="text-gray-400">Order ID:</p>
              <p className="text-white font-mono text-sm">
                {order.id.substring(0, 8)}
              </p>
              
              <p className="text-gray-400">Payment Terms:</p>
              <p className="text-white">
                {order.payment_terms === 'upfront' ? 'Upfront Payment' :
                 order.payment_terms === 'net7' ? 'Net 7' :
                 order.payment_terms === 'net30' ? 'Net 30' :
                 order.payment_terms === 'custom' ? 'Custom' :
                 order.payment_terms || 'Not specified'}
              </p>
              
              <p className="text-gray-400">Payment Method:</p>
              <p className="text-white">
                {order.payment_method === 'cash' ? 'Cash' :
                 order.payment_method === 'check' ? 'Check' :
                 order.payment_method === 'paypal' ? 'PayPal' :
                 order.payment_method === 'wire' ? 'Wire Transfer' :
                 order.payment_method || 'Not specified'}
              </p>
            </div>
          </div>

          {order.notes && (
            <div className="bg-dark-muted p-4 rounded-lg space-y-2">
              <h3 className="text-white font-medium">Notes</h3>
              <p className="text-gray-400 whitespace-pre-line">{order.notes}</p>
            </div>
          )}
        </div>

        <div className="space-y-4">
          <div className="bg-dark-muted p-4 rounded-lg space-y-3">
            <h3 className="text-white font-medium">Status</h3>
            
            <div className="grid grid-cols-[auto_1fr] gap-4 items-center">
              <Label htmlFor="orderStatus">Order Status:</Label>
              <div className="flex gap-2 items-center">
                <Select 
                  value={newStatus} 
                  onValueChange={(value: OrderStatus) => setNewStatus(value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="draft">Draft</SelectItem>
                    <SelectItem value="confirmed">Confirmed</SelectItem>
                    <SelectItem value="shipped">Shipped</SelectItem>
                    <SelectItem value="delivered">Delivered</SelectItem>
                    <SelectItem value="cancelled">Cancelled</SelectItem>
                  </SelectContent>
                </Select>
                {getStatusIcon(newStatus as OrderStatus)}
              </div>
              
              <Label htmlFor="paymentStatus">Payment Status:</Label>
              <div className="flex gap-2 items-center">
                <Select 
                  value={newPaymentStatus} 
                  onValueChange={(value: PaymentStatus) => setNewPaymentStatus(value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select payment status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="pending">Pending</SelectItem>
                    <SelectItem value="partial">Partial</SelectItem>
                    <SelectItem value="paid">Paid</SelectItem>
                    <SelectItem value="overdue">Overdue</SelectItem>
                  </SelectContent>
                </Select>
                {getPaymentIcon(newPaymentStatus as PaymentStatus)}
              </div>
              
              <div className="col-span-2">
                <Button
                  className="w-full mt-2"
                  size="sm"
                  disabled={updatingStatus || (newStatus === order.status && newPaymentStatus === order.payment_status)}
                  onClick={handleUpdateStatus}
                >
                  {updatingStatus ? "Updating..." : "Update Status"}
                </Button>
              </div>
            </div>
          </div>

          <div className="bg-dark-muted p-4 rounded-lg space-y-3">
            <h3 className="text-white font-medium">Shipping</h3>
            <div className="space-y-2">
              <Label htmlFor="trackingNumber">Tracking Number:</Label>
              <div className="flex gap-2">
                <Input
                  id="trackingNumber"
                  value={newTrackingNumber}
                  onChange={(e) => setNewTrackingNumber(e.target.value)}
                  placeholder="Enter tracking number"
                />
                <Button 
                  variant="secondary" 
                  size="sm"
                  disabled={updatingTrackingNumber || newTrackingNumber === order.tracking_number}
                  onClick={handleUpdateTrackingNumber}
                >
                  {updatingTrackingNumber ? "Saving..." : "Save"}
                </Button>
              </div>
            </div>
          </div>

          <div className="bg-dark-muted p-4 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-white font-medium">Order Summary</h3>
              <div className="flex items-center gap-1 text-gray-400">
                <ShoppingBag className="h-4 w-4" />
                <span>{order.items?.length || 0} items</span>
              </div>
            </div>
            
            <div className="py-2 border-t border-b border-dark-border my-2">
              <div className="grid grid-cols-2 gap-2">
                <p className="text-gray-400">Subtotal:</p>
                <p className="text-white text-right">
                  ${Number(order.total_amount || 0).toFixed(2)}
                </p>
              </div>
            </div>
            
            <div className="flex justify-between items-center pt-2">
              <p className="text-white font-medium">Total:</p>
              <p className="text-xl font-bold text-white">
                ${Number(order.total_amount || 0).toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-dark-border pt-4">
        <h3 className="text-lg font-medium text-white mb-3">Order Items</h3>
        <div className="rounded-md border border-dark-border">
          <Table>
            <TableHeader className="bg-dark-muted">
              <TableRow>
                <TableHead className="text-white">Product</TableHead>
                <TableHead className="text-white">Category</TableHead>
                <TableHead className="text-white text-right">Quantity</TableHead>
                <TableHead className="text-white text-right">Price Each</TableHead>
                <TableHead className="text-white text-right">Total</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {order.items?.map((item) => (
                <TableRow key={item.id} className="border-dark-border">
                  <TableCell className="font-medium">
                    {item.inventory_item?.name || "Unknown Item"}
                  </TableCell>
                  <TableCell>
                    {item.inventory_item?.category || "Uncategorized"}
                  </TableCell>
                  <TableCell className="text-right">
                    {item.quantity}
                  </TableCell>
                  <TableCell className="text-right">
                    ${Number(item.price_per_unit || 0).toFixed(2)}
                  </TableCell>
                  <TableCell className="text-right">
                    ${(Number(item.price_per_unit || 0) * Number(item.quantity || 0)).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};
