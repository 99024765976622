export interface Permission {
  id: string;
  label: string;
  description: string;
  category: string;
}

export const permissions: Permission[] = [
  // Inventory Permissions
  {
    id: "view_inventory",
    label: "View Inventory",
    description: "Can view inventory items",
    category: "Inventory"
  },
  {
    id: "manage_inventory",
    label: "Manage Inventory",
    description: "Can add, edit, and delete inventory items",
    category: "Inventory"
  },
  {
    id: "perform_inventory_check",
    label: "Perform Inventory Check",
    description: "Can perform inventory checks",
    category: "Inventory"
  },
  {
    id: "view_inventory_reports",
    label: "View Inventory Reports",
    description: "Can view inventory check reports",
    category: "Inventory"
  },

  // Stream Permissions
  {
    id: "view_streams",
    label: "View Streams",
    description: "Can view streaming sessions",
    category: "Streams"
  },
  {
    id: "manage_streams",
    label: "Manage Streams",
    description: "Can create and manage streaming sessions",
    category: "Streams"
  },
  {
    id: "view_stream_analytics",
    label: "View Stream Analytics",
    description: "Can view stream performance and analytics",
    category: "Streams"
  },
  {
    id: "view_all_streams",
    label: "View All Streams",
    description: "Can view all organization streams",
    category: "Streams"
  },

  // Financial Permissions
  {
    id: "view_financial_reports",
    label: "View Financial Reports",
    description: "Can view financial reports and analytics",
    category: "Financial"
  },
  {
    id: "manage_pricing",
    label: "Manage Pricing",
    description: "Can set and modify pricing settings",
    category: "Financial"
  },

  // User Management
  {
    id: "view_users",
    label: "View Users",
    description: "Can view organization users",
    category: "Users"
  },
  {
    id: "manage_users",
    label: "Manage Users",
    description: "Can invite and manage organization users",
    category: "Users"
  },

  // Settings
  {
    id: "view_settings",
    label: "View Settings",
    description: "Can view organization settings",
    category: "Settings"
  },
  {
    id: "manage_settings",
    label: "Manage Settings",
    description: "Can modify organization settings",
    category: "Settings"
  }
];

export const rolePermissions = {
  admin: permissions.map(p => p.id), // Admins get all permissions
  manager: [
    // All permissions except user management
    "view_inventory",
    "manage_inventory",
    "perform_inventory_check",
    "view_inventory_reports",
    "view_streams",
    "manage_streams",
    "view_stream_analytics",
    "view_all_streams",
    "view_financial_reports",
    "manage_pricing",
    "view_users",
    "view_settings",
    "manage_settings"
  ],
  streamer: [
    "view_inventory",
    "view_streams",
    "manage_streams",
    "view_stream_analytics"
  ],
  user: [
    "view_inventory",
    "view_streams"
  ]
};