import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useInventoryData } from "@/hooks/useInventoryData";
import { Button } from "@/components/ui/button";
import { Plus, Pencil, Trash2 } from "lucide-react";
import { CategoryDialog } from "./CategoryDialog";
import { useState } from "react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";

interface InventorySearchProps {
  onSearch: (term: string) => void;
  onCategoryChange: (category: string) => void;
  onStatusChange: (status: 'active' | 'archived') => void;
  currentStatus: 'active' | 'archived';
}

export const InventorySearch = ({
  onSearch,
  onCategoryChange,
  onStatusChange,
  currentStatus
}: InventorySearchProps) => {
  const { data, refetch } = useInventoryData();
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [editingCategory, setEditingCategory] = useState<string | null>(null);
  const [deletingCategory, setDeletingCategory] = useState<string | null>(null);

  // Get unique categories from items
  const categories = data?.items
    ? Array.from(new Set(data.items.map(item => item.category || 'Uncategorized')))
    : [];

  const handleCategorySuccess = async () => {
    try {
      await refetch();
      setShowCategoryDialog(false);
      setEditingCategory(null);
    } catch (error) {
      console.error('Error refetching data:', error);
    }
  };

  const handleDeleteCategory = async (category: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast.error('Authentication required');
        return;
      }

      // First, update all items in this category to be uncategorized
      const { error: updateError } = await supabase
        .from('inventory_items')
        .update({ category: 'Uncategorized' })
        .eq('category', category)
        .eq('user_id', user.id)
        .neq('status', 'category_marker');

      if (updateError) {
        console.error('Update error:', updateError);
        toast.error('Failed to update items');
        return;
      }

      // Then, delete the category marker
      const { error: markerError } = await supabase
        .from('inventory_items')
        .delete()
        .eq('category', category)
        .eq('user_id', user.id)
        .eq('status', 'category_marker');

      if (markerError) {
        console.error('Delete error:', markerError);
        toast.error('Failed to delete category');
        return;
      }

      toast.success('Category deleted successfully');
      await refetch();
      setDeletingCategory(null);
      onCategoryChange('all');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Failed to delete category');
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="w-full md:w-1/2 lg:w-2/5">
          <Input
            type="text"
            placeholder="Search inventory..."
            className="w-full bg-dark-card border-dark-border text-white"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
          <Select
            defaultValue="all"
            onValueChange={onCategoryChange}
          >
            <SelectTrigger className="w-full sm:w-[180px] bg-dark-card border-dark-border text-white">
              <SelectValue placeholder="All Categories" />
            </SelectTrigger>
            <SelectContent className="bg-dark-card border-dark-border">
              <SelectItem value="all" className="text-white">All Categories</SelectItem>
              {categories.map((category) => (
                <SelectItem
                  key={category}
                  value={category}
                  className="text-white group relative"
                  onSelect={(e) => {
                    // Prevent selection if clicking delete or edit buttons
                    if ((e.target as HTMLElement).closest('button')) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="flex items-center justify-between w-full pr-8">
                    <span>{category}</span>
                    <div className="absolute right-0 flex gap-1" onClick={(e) => e.stopPropagation()}>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-6 w-6 p-0 text-gray-400 hover:text-white"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setEditingCategory(category);
                          setShowCategoryDialog(true);
                        }}
                      >
                        <Pencil className="h-3 w-3" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-6 w-6 p-0 text-gray-400 hover:text-red-500"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDeletingCategory(category);
                        }}
                      >
                        <Trash2 className="h-3 w-3" />
                      </Button>
                    </div>
                  </div>
                </SelectItem>
              ))}
              <div className="p-2 border-t border-dark-border">
                <Button
                  variant="ghost"
                  size="sm"
                  className="w-full justify-start text-white hover:bg-dark-muted"
                  onClick={() => {
                    setEditingCategory(null);
                    setShowCategoryDialog(true);
                  }}
                >
                  <Plus className="mr-2 h-4 w-4" />
                  Add New Category
                </Button>
              </div>
            </SelectContent>
          </Select>
          <Select
            value={currentStatus}
            onValueChange={onStatusChange}
          >
            <SelectTrigger className="w-full sm:w-[180px] bg-dark-card border-dark-border text-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-dark-card border-dark-border">
              <SelectItem value="active" className="text-white">Active Items</SelectItem>
              <SelectItem value="archived" className="text-white">Archived Items</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <CategoryDialog
        open={showCategoryDialog}
        onOpenChange={setShowCategoryDialog}
        editingCategory={editingCategory}
        setEditingCategory={setEditingCategory}
        onSuccess={handleCategorySuccess}
      />

      <AlertDialog open={!!deletingCategory} onOpenChange={() => setDeletingCategory(null)}>
        <AlertDialogContent className="bg-dark-card border-dark-border">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-white">Delete Category</AlertDialogTitle>
            <AlertDialogDescription className="text-gray-400">
              Are you sure you want to delete this category? All items in this category will be moved to "Uncategorized".
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="bg-dark-muted text-white hover:bg-dark-muted/90">Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-red-500 text-white hover:bg-red-600"
              onClick={() => deletingCategory && handleDeleteCategory(deletingCategory)}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};