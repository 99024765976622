
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { UseFormReturn } from "react-hook-form";
import { SettingsFormValues } from "./types";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";  // Added useQueryClient import
import { supabase } from "@/integrations/supabase/client";
import { useProfile } from "@/hooks/useProfile";
import { NoOrganizationAlert } from "./NoOrganizationAlert";
import { AddPlatformDialog } from "./platforms/AddPlatformDialog";
import { PlatformFeeInput } from "./platforms/PlatformFeeInput";
import { usePlatformMutations } from "./platforms/usePlatformMutations";

export const PlatformFeesCard = ({ form }: { form: UseFormReturn<SettingsFormValues> }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: profile, isLoading: profileLoading } = useProfile();
  const { updatePlatformMutation, addPlatformMutation, deletePlatformMutation } = usePlatformMutations();
  const queryClient = useQueryClient();  // Added queryClient initialization

  const { data: adminSettings } = useQuery({
    queryKey: ['admin-settings', profile?.organization_id],
    enabled: !!profile?.organization_id,
    queryFn: async () => {
      if (!profile?.organization_id) return null;

      const { data: adminProfiles, error: adminError } = await supabase
        .from('profiles')
        .select('id')
        .eq('organization_id', profile.organization_id)
        .eq('role', 'admin');

      if (adminError) throw adminError;
      if (!adminProfiles?.length) return null;

      const { data: settings, error: settingsError } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', adminProfiles[0].id)
        .eq('organization_id', profile.organization_id)
        .maybeSingle();

      if (settingsError) throw settingsError;
      return settings;
    }
  });

  const { data: platforms = [], isLoading: platformsLoading } = useQuery({
    queryKey: ['platforms', profile?.organization_id],
    queryFn: async () => {
      if (!profile?.organization_id) return [];

      const { data, error } = await supabase
        .from('platforms')
        .select('*')
        .eq('organization_id', profile.organization_id)
        .eq('status', 'active')
        .order('name');

      if (error) {
        console.error('Error fetching platforms:', error);
        throw error;
      }
      return data;
    },
    enabled: !!profile?.organization_id
  });

  const handleAddPlatform = (name: string, fee: number) => {
    addPlatformMutation.mutate({ name, fee }, {
      onSuccess: () => setIsOpen(false)
    });
  };

  const handleUpdatePlatformFee = (id: string, newFee: string) => {
    const feeValue = parseFloat(newFee);
    if (!isNaN(feeValue)) {
      updatePlatformMutation.mutate({ id, fee: feeValue });
    }
  };

  if (profileLoading) {
    return <div>Loading...</div>;
  }

  if (!profile?.organization_id) {
    return (
      <NoOrganizationAlert
        onOrganizationCreated={async () => {
          await queryClient.invalidateQueries({ queryKey: ['profile'] });
          await queryClient.invalidateQueries({ queryKey: ['platforms'] });
        }}
      />
    );
  }

  const isDisabled = profile.role !== 'admin';

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-white">Platform Fees</CardTitle>
        {!isDisabled && (
          <Button variant="outline" size="icon" className="h-8 w-8" onClick={() => setIsOpen(true)}>
            <Plus className="h-4 w-4" />
          </Button>
        )}
      </CardHeader>
      <CardContent className="space-y-4">
        {platforms.map((platform) => (
          <PlatformFeeInput
            key={platform.id}
            platform={platform}
            onUpdateFee={handleUpdatePlatformFee}
            onDelete={(id) => deletePlatformMutation.mutate(id)}
            isDisabled={isDisabled}
          />
        ))}
      </CardContent>
      <AddPlatformDialog
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        onAddPlatform={handleAddPlatform}
        isLoading={addPlatformMutation.isPending}
      />
    </Card>
  );
};
