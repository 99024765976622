import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useState } from "react";
import { useProfile } from "@/hooks/useProfile";

interface CheckResult {
  itemId: string;
  isCorrect: boolean;
  actualQuantity?: number;
  itemName?: string;
  expectedQuantity?: number;
}

interface InventoryCheckReportProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  results: CheckResult[];
  onSubmit: () => void;
  itemsMap: Record<string, { name: string; quantity: number; cost_basis: number }>;
}

export const InventoryCheckReport = ({ 
  open, 
  onOpenChange,
  results,
  onSubmit,
  itemsMap
}: InventoryCheckReportProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: profile } = useProfile();
  const incorrectItems = results.filter(result => !result.isCorrect);
  const correctItems = results.filter(result => result.isCorrect);

  const calculateDiscrepancyDetails = (result: CheckResult) => {
    const item = itemsMap[result.itemId];
    if (!item || !result.actualQuantity) return null;

    const missingQuantity = item.quantity - result.actualQuantity;
    const costPerItem = Number(item.cost_basis) || 0;
    const totalCost = missingQuantity * costPerItem;

    return {
      missingQuantity,
      costPerItem,
      totalCost
    };
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      // Calculate total missing value
      const totalMissingValue = incorrectItems.reduce((sum, result) => {
        const details = calculateDiscrepancyDetails(result);
        return sum + (details?.totalCost || 0);
      }, 0);

      // Insert report with organization_id
      const { error: reportError, data: reportData } = await supabase
        .from('inventory_check_reports')
        .insert({
          user_id: user.id,
          organization_id: profile?.organization_id,
          total_items_checked: results.length,
          correct_items: correctItems.length,
          incorrect_items: incorrectItems.length,
          total_missing_value: totalMissingValue,
          report_date: new Date().toISOString(),
        })
        .select()
        .single();

      if (reportError) throw reportError;

      // Insert all check results
      const { error } = await supabase
        .from('inventory_checks')
        .insert(results.map(result => ({
          user_id: user.id,
          inventory_item_id: result.itemId,
          actual_quantity: result.actualQuantity,
          is_correct: result.isCorrect,
          notes: result.isCorrect ? null : `Expected: ${itemsMap[result.itemId]?.quantity}, Found: ${result.actualQuantity}`
        })));

      if (error) throw error;

      toast.success('Inventory check completed successfully');
      onSubmit();
      onOpenChange(false);
    } catch (error) {
      console.error('Error saving inventory checks:', error);
      toast.error('Failed to save inventory checks');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button 
          className="bg-white text-black hover:bg-gray-100"
          disabled={results.length === 0}
        >
          Complete Check
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-dark-card border-dark-border">
        <DialogHeader>
          <DialogTitle className="text-white">Inventory Check Report</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-semibold text-white mb-2">Summary</h3>
            {results.length === 0 ? (
              <p className="text-gray-400">No items have been checked yet.</p>
            ) : (
              <p className="text-gray-400">
                {correctItems.length} items correct, {incorrectItems.length} items with discrepancies
              </p>
            )}
          </div>

          {incorrectItems.length > 0 ? (
            <div>
              <h3 className="text-lg font-semibold text-white mb-2">Discrepancies</h3>
              <div className="space-y-2">
                {incorrectItems.map((check) => {
                  const details = calculateDiscrepancyDetails(check);
                  return (
                    <div key={check.itemId} className="p-3 bg-dark-muted rounded-lg">
                      <p className="text-white font-medium">
                        {itemsMap[check.itemId]?.name}
                      </p>
                      <p className="text-sm text-gray-400">
                        Expected: {itemsMap[check.itemId]?.quantity}, 
                        Found: {check.actualQuantity}
                      </p>
                      {details && (
                        <div className="mt-1 text-sm">
                          <p className="text-red-400">
                            Missing: {details.missingQuantity} items
                          </p>
                          <p className="text-gray-400">
                            Cost per item: ${details.costPerItem.toFixed(2)}
                          </p>
                          <p className="text-red-400 font-semibold">
                            Total missing value: ${details.totalCost.toFixed(2)}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : results.length > 0 ? (
            <div className="p-3 bg-dark-muted rounded-lg">
              <p className="text-green-500">All items are correct!</p>
            </div>
          ) : null}

          {results.length > 0 && (
            <Button 
              onClick={handleSubmit}
              className="w-full bg-white text-black hover:bg-gray-100"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Report'}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};