import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Package } from "lucide-react";
import { useInventoryQueries } from "@/hooks/useInventoryQueries";

interface AwaitingItemsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const AwaitingItemsDialog = ({ open, onOpenChange }: AwaitingItemsDialogProps) => {
  const { invalidateAllInventoryQueries } = useInventoryQueries();

  const { data: awaitingItems = [], isLoading } = useQuery({
    queryKey: ['awaiting-items'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return [];

      const { data, error } = await supabase
        .from('inventory_items')
        .select('*')
        .eq('user_id', user.id)
        .eq('status', 'awaiting');

      if (error) {
        console.error('Error fetching awaiting items:', error);
        return [];
      }

      return data || [];
    },
    enabled: open,
  });

  const handleReceiveItem = async (itemId: string) => {
    try {
      const { error } = await supabase
        .from('inventory_items')
        .update({ status: 'active' })
        .eq('id', itemId);

      if (error) throw error;

      toast.success('Item marked as received');
      await invalidateAllInventoryQueries();
    } catch (error) {
      console.error('Error updating item status:', error);
      toast.error('Failed to update item status');
    }
  };

  const calculateItemValue = (quantity: number, cost: number) => {
    return Number(quantity || 0) * Number(cost || 0);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-dark-card border-dark-border max-w-3xl">
        <DialogHeader>
          <DialogTitle className="text-white flex items-center gap-2">
            <Package className="w-5 h-5" />
            Awaiting Items
          </DialogTitle>
          <DialogDescription className="text-gray-400">
            Items that are awaiting arrival or processing
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          {isLoading ? (
            <p className="text-gray-400 text-center py-8">Loading...</p>
          ) : awaitingItems.length === 0 ? (
            <p className="text-gray-400 text-center py-8">No items awaiting arrival</p>
          ) : (
            <div className="space-y-4">
              {awaitingItems.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between p-4 rounded-lg bg-dark-muted"
                >
                  <div className="space-y-1">
                    <h3 className="font-medium text-white">{item.name}</h3>
                    <p className="text-sm text-gray-400">
                      Quantity: {item.quantity} × ${Number(item.cost_basis).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-400">
                      Total Value: ${calculateItemValue(item.quantity, item.cost_basis).toLocaleString()}
                    </p>
                  </div>
                  <Button
                    variant="default"
                    className="bg-white text-black hover:bg-gray-100"
                    onClick={() => handleReceiveItem(item.id)}
                  >
                    Mark as Received
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};