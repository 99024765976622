
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { WholesaleOrder, WholesaleOrderItem, WholesaleOrderWithDetails } from "@/components/wholesale/types";

export const useWholesaleOrders = () => {
  const queryClient = useQueryClient();

  // Fetch all orders with customer details
  const ordersQuery = useQuery({
    queryKey: ['wholesale-orders'],
    queryFn: async () => {
      try {
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError || !user) {
          console.error('Authentication error:', authError);
          throw new Error('Please sign in to view orders');
        }

        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('organization_id')
          .eq('id', user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
          throw new Error('Error loading profile data');
        }

        let query = supabase
          .from('wholesale_orders')
          .select(`
            *,
            customer:customer_id(id, name, email, phone)
          `)
          .order('created_at', { ascending: false });

        if (profile?.organization_id) {
          query = query.eq('organization_id', profile.organization_id);
        } else {
          query = query.eq('user_id', user.id);
        }

        const { data: orders, error } = await query;

        if (error) {
          console.error('Error fetching orders:', error);
          throw error;
        }

        // Fetch order items for each order
        const ordersWithItems = await Promise.all(
          orders.map(async (order) => {
            const { data: items, error: itemsError } = await supabase
              .from('wholesale_order_items')
              .select(`
                *,
                inventory_item:inventory_item_id(id, name, category, cost_basis)
              `)
              .eq('order_id', order.id);

            if (itemsError) {
              console.error('Error fetching order items:', itemsError);
              return { ...order, items: [], total_items: 0 };
            }

            return { 
              ...order, 
              items: items || [],
              total_items: items?.length || 0
            };
          })
        );

        return ordersWithItems as WholesaleOrderWithDetails[];
      } catch (error: any) {
        console.error('Error in useWholesaleOrders:', error);
        throw error;
      }
    },
    staleTime: 30000,
  });

  // Fetch a single order with all details
  const getOrderById = async (orderId: string): Promise<WholesaleOrderWithDetails | null> => {
    try {
      const { data, error } = await supabase
        .from('wholesale_orders')
        .select(`
          *,
          customer:customer_id(*)
        `)
        .eq('id', orderId)
        .single();

      if (error) {
        console.error('Error fetching order:', error);
        throw error;
      }

      const { data: items, error: itemsError } = await supabase
        .from('wholesale_order_items')
        .select(`
          *,
          inventory_item:inventory_item_id(*)
        `)
        .eq('order_id', orderId);

      if (itemsError) {
        console.error('Error fetching order items:', itemsError);
        throw itemsError;
      }

      return { 
        ...data, 
        items: items || [],
        total_items: items?.length || 0
      } as WholesaleOrderWithDetails;
    } catch (error) {
      console.error('Error fetching order by ID:', error);
      return null;
    }
  };

  // Create new order
  const createOrder = useMutation({
    mutationFn: async (orderData: { 
      order: Partial<WholesaleOrder>, 
      items?: Array<Partial<WholesaleOrderItem>>
    }) => {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('You must be logged in to create an order');
      }

      const { data: profile } = await supabase
        .from('profiles')
        .select('organization_id')
        .eq('id', user.id)
        .maybeSingle();

      // Start a transaction by beginning with the order
      const { data: order, error: orderError } = await supabase
        .from('wholesale_orders')
        .insert({
          ...orderData.order, 
          user_id: user.id,
          organization_id: profile?.organization_id || null,
          status: orderData.order.status || 'draft',
          payment_status: orderData.order.payment_status || 'pending'
        })
        .select()
        .single();

      if (orderError) {
        console.error('Error creating order:', orderError);
        throw orderError;
      }

      // If there are items, add them
      if (orderData.items && orderData.items.length > 0) {
        const itemsWithOrderId = orderData.items.map(item => ({
          ...item,
          order_id: order.id
        }));

        const { error: itemsError } = await supabase
          .from('wholesale_order_items')
          .insert(itemsWithOrderId);

        if (itemsError) {
          console.error('Error adding order items:', itemsError);
          throw itemsError;
        }

        // Update inventory quantities
        for (const item of orderData.items) {
          if (item.inventory_item_id && item.quantity) {
            // Get current inventory item
            const { data: inventoryItem, error: fetchError } = await supabase
              .from('inventory_items')
              .select('quantity')
              .eq('id', item.inventory_item_id)
              .single();

            if (fetchError) {
              console.error('Error fetching inventory item:', fetchError);
              continue;
            }

            const currentQuantity = Number(inventoryItem.quantity) || 0;
            const newQuantity = Math.max(0, currentQuantity - Number(item.quantity));

            // Update inventory quantity
            const { error: updateError } = await supabase
              .from('inventory_items')
              .update({ quantity: newQuantity })
              .eq('id', item.inventory_item_id);

            if (updateError) {
              console.error('Error updating inventory quantity:', updateError);
            }
          }
        }
      }

      // Get the complete order with items
      return await getOrderById(order.id);
    },
    onSuccess: () => {
      toast.success('Order created successfully');
      queryClient.invalidateQueries({ queryKey: ['wholesale-orders'] });
      queryClient.invalidateQueries({ queryKey: ['dashboard-inventory'] });
    },
    onError: (error: any) => {
      toast.error(`Failed to create order: ${error.message}`);
    },
  });

  // Update existing order
  const updateOrder = useMutation({
    mutationFn: async ({ 
      orderId, 
      orderData,
      addItems = [],
      removeItemIds = []
    }: { 
      orderId: string; 
      orderData: Partial<WholesaleOrder>; 
      addItems?: Array<Partial<WholesaleOrderItem>>;
      removeItemIds?: string[];
    }) => {
      // Update order details
      const { error: orderError } = await supabase
        .from('wholesale_orders')
        .update(orderData)
        .eq('id', orderId);

      if (orderError) {
        console.error('Error updating order:', orderError);
        throw orderError;
      }

      // Add new items if any
      if (addItems.length > 0) {
        const itemsWithOrderId = addItems.map(item => ({
          ...item,
          order_id: orderId
        }));

        const { error: addItemsError } = await supabase
          .from('wholesale_order_items')
          .insert(itemsWithOrderId);

        if (addItemsError) {
          console.error('Error adding new order items:', addItemsError);
          throw addItemsError;
        }

        // Update inventory quantities for added items
        for (const item of addItems) {
          if (item.inventory_item_id && item.quantity) {
            // Get current inventory item
            const { data: inventoryItem, error: fetchError } = await supabase
              .from('inventory_items')
              .select('quantity')
              .eq('id', item.inventory_item_id)
              .single();

            if (fetchError) {
              console.error('Error fetching inventory item:', fetchError);
              continue;
            }

            const currentQuantity = Number(inventoryItem.quantity) || 0;
            const newQuantity = Math.max(0, currentQuantity - Number(item.quantity));

            // Update inventory quantity
            const { error: updateError } = await supabase
              .from('inventory_items')
              .update({ quantity: newQuantity })
              .eq('id', item.inventory_item_id);

            if (updateError) {
              console.error('Error updating inventory quantity:', updateError);
            }
          }
        }
      }

      // Remove items if any
      if (removeItemIds.length > 0) {
        // First, get the items being removed to restore inventory
        const { data: itemsToRemove, error: fetchError } = await supabase
          .from('wholesale_order_items')
          .select('inventory_item_id, quantity')
          .in('id', removeItemIds);

        if (fetchError) {
          console.error('Error fetching items to remove:', fetchError);
        } else {
          // Restore inventory quantities for removed items
          for (const item of itemsToRemove || []) {
            if (item.inventory_item_id && item.quantity) {
              // Get current inventory item
              const { data: inventoryItem, error: fetchItemError } = await supabase
                .from('inventory_items')
                .select('quantity')
                .eq('id', item.inventory_item_id)
                .single();

              if (fetchItemError) {
                console.error('Error fetching inventory item:', fetchItemError);
                continue;
              }

              const currentQuantity = Number(inventoryItem.quantity) || 0;
              const newQuantity = currentQuantity + Number(item.quantity);

              // Update inventory quantity
              const { error: updateError } = await supabase
                .from('inventory_items')
                .update({ quantity: newQuantity })
                .eq('id', item.inventory_item_id);

              if (updateError) {
                console.error('Error updating inventory quantity:', updateError);
              }
            }
          }
        }

        // Now delete the items
        const { error: removeItemsError } = await supabase
          .from('wholesale_order_items')
          .delete()
          .in('id', removeItemIds);

        if (removeItemsError) {
          console.error('Error removing order items:', removeItemsError);
          throw removeItemsError;
        }
      }

      // Get the updated order
      return await getOrderById(orderId);
    },
    onSuccess: () => {
      toast.success('Order updated successfully');
      queryClient.invalidateQueries({ queryKey: ['wholesale-orders'] });
      queryClient.invalidateQueries({ queryKey: ['dashboard-inventory'] });
    },
    onError: (error: any) => {
      toast.error(`Failed to update order: ${error.message}`);
    },
  });

  // Delete order
  const deleteOrder = useMutation({
    mutationFn: async (orderId: string) => {
      // First, get the items to restore inventory
      const { data: itemsToRestore, error: fetchError } = await supabase
        .from('wholesale_order_items')
        .select('inventory_item_id, quantity')
        .eq('order_id', orderId);

      if (fetchError) {
        console.error('Error fetching order items:', fetchError);
      } else {
        // Restore inventory quantities
        for (const item of itemsToRestore || []) {
          if (item.inventory_item_id && item.quantity) {
            // Get current inventory item
            const { data: inventoryItem, error: fetchItemError } = await supabase
              .from('inventory_items')
              .select('quantity')
              .eq('id', item.inventory_item_id)
              .single();

            if (fetchItemError) {
              console.error('Error fetching inventory item:', fetchItemError);
              continue;
            }

            const currentQuantity = Number(inventoryItem.quantity) || 0;
            const newQuantity = currentQuantity + Number(item.quantity);

            // Update inventory quantity
            const { error: updateError } = await supabase
              .from('inventory_items')
              .update({ quantity: newQuantity })
              .eq('id', item.inventory_item_id);

            if (updateError) {
              console.error('Error updating inventory quantity:', updateError);
            }
          }
        }
      }

      // Delete order items first
      const { error: itemsError } = await supabase
        .from('wholesale_order_items')
        .delete()
        .eq('order_id', orderId);

      if (itemsError) {
        console.error('Error deleting order items:', itemsError);
        throw itemsError;
      }

      // Then delete the order
      const { error } = await supabase
        .from('wholesale_orders')
        .delete()
        .eq('id', orderId);

      if (error) {
        console.error('Error deleting order:', error);
        throw error;
      }

      return orderId;
    },
    onSuccess: () => {
      toast.success('Order deleted successfully');
      queryClient.invalidateQueries({ queryKey: ['wholesale-orders'] });
      queryClient.invalidateQueries({ queryKey: ['dashboard-inventory'] });
    },
    onError: (error: any) => {
      toast.error(`Failed to delete order: ${error.message}`);
    },
  });

  return {
    orders: ordersQuery.data || [],
    isLoading: ordersQuery.isLoading,
    error: ordersQuery.error,
    createOrder,
    updateOrder,
    deleteOrder,
    getOrderById,
    refetch: ordersQuery.refetch,
  };
};
