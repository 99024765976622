
import { AppLayout } from "@/components/layout/AppLayout";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { StreamForm } from "@/components/streams/StreamForm";
import { StreamHistory } from "@/components/streams/StreamHistory";
import { useNavigate } from "react-router-dom";
import { useProfile } from "@/hooks/useProfile";

const Streams = () => {
  const navigate = useNavigate();
  const { data: profile } = useProfile();

  const { data: streams, isLoading, refetch } = useQuery({
    queryKey: ["streams", profile?.role, profile?.id, profile?.canViewAllStreams],
    enabled: !!profile,
    queryFn: async () => {
      let query = supabase
        .from("streams")
        .select("*")
        .order("created_at", { ascending: false });

      // If user can view all streams in organization
      if (profile?.canViewAllStreams && profile?.organization_id) {
        query = query.eq('organization_id', profile.organization_id);
      } 
      // Users who can only see their own streams
      else if (profile?.id) {
        query = query.eq('user_id', profile.id);
      }

      const { data, error } = await query;

      if (error) {
        console.error("Error fetching streams:", error);
        throw error;
      }

      return data;
    },
  });

  return (
    <AppLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">Stream Management</h1>
          <Button
            variant="outline"
            onClick={() => navigate("/")}
            className="border-dark-border text-white hover:text-white hover:bg-dark-muted"
          >
            Back to Dashboard
          </Button>
        </div>

        <Tabs defaultValue="current" className="space-y-6">
          <TabsList className="bg-dark-card border border-dark-border">
            <TabsTrigger
              value="current"
              className="text-gray-400 data-[state=active]:bg-dark-muted data-[state=active]:text-white"
            >
              Current Stream
            </TabsTrigger>
            <TabsTrigger
              value="history"
              className="text-gray-400 data-[state=active]:bg-dark-muted data-[state=active]:text-white"
            >
              Stream History
            </TabsTrigger>
          </TabsList>

          <TabsContent value="current">
            <StreamForm onSuccess={refetch} />
          </TabsContent>

          <TabsContent value="history">
            <StreamHistory streams={streams} isLoading={isLoading} />
          </TabsContent>
        </Tabs>
      </div>
    </AppLayout>
  );
};

export default Streams;
