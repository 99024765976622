import { Tables } from "@/integrations/supabase/types";
import { calculateStreamFinancials } from "@/utils/streamFinancials";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { differenceInHours } from "date-fns";

type Stream = Partial<Tables<"streams">>;
type StreamItem = {
  quantity_sold: number | null;
  sale_price?: number | null;
  inventory_item: {
    cost_basis: number | null;
  } | null;
};

interface StreamFinancialBreakdownProps {
  stream: Stream;
  streamItems: StreamItem[] | null;
  settings: any;
}

export const StreamFinancialBreakdown = ({ 
  stream, 
  streamItems, 
  settings 
}: StreamFinancialBreakdownProps) => {
  // Fetch custom platforms
  const { data: customPlatforms } = useQuery({
    queryKey: ['platforms'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('platforms')
        .select('name, fee')
        .order('name');
      
      if (error) throw error;
      return data || [];
    },
  });

  // Calculate total product cost from stream items using cost_basis
  const totalProductCost = streamItems?.reduce((sum, item) => {
    const costBasis = Number(item.inventory_item?.cost_basis) || 0;
    return sum + ((Number(item.quantity_sold) || 0) * costBasis);
  }, 0) || 0;

  // Calculate total sales from the stream's total_sales field
  const totalSales = Number(stream.total_sales || 0);

  // Calculate stream duration in hours
  const streamDurationHours = stream.start_time && stream.end_time
    ? differenceInHours(new Date(stream.end_time), new Date(stream.start_time))
    : 0;

  // Calculate financial breakdown using the utility
  const financials = calculateStreamFinancials({
    totalSales: totalSales,
    productCosts: totalProductCost,
    platform: stream.platform || '',
    settings,
    customPlatforms,
    streamDurationHours
  });

  // Calculate profit margins
  const grossProfitMargin = totalSales > 0 
    ? (financials.grossProfit / totalSales) * 100 
    : 0;

  const netProfitMargin = totalSales > 0 
    ? (financials.netProfit / totalSales) * 100 
    : 0;

  // Calculate streamer pay percentage label based on type
  const getStreamerPayLabel = () => {
    if (!settings?.streamer_pay_type || settings.streamer_pay_type === 'disabled') {
      return '';
    }
    const value = settings.streamer_pay_value || 0;
    return `(${value}% of ${settings.streamer_pay_type === 'percentage' ? 'Sales' : 'Profit'})`;
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <span className="text-gray-400">Total Sales</span>
        <span className="text-white">${totalSales.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
      </div>
      
      <div className="flex justify-between">
        <span className="text-gray-400">Product Cost</span>
        <span className="text-red-500">-${totalProductCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
      </div>
      
      <div className="flex justify-between border-t border-dark-border pt-2">
        <span className="text-gray-400">Gross Profit</span>
        <div className="text-right">
          <span className={financials.grossProfit >= 0 ? 'text-green-500' : 'text-red-500'}>
            ${Math.abs(financials.grossProfit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </span>
          <div className="text-sm text-gray-400">
            Margin: {grossProfitMargin.toFixed(2)}%
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-400">Platform Fee ({financials.platformFeePercentage}%)</span>
        <span className="text-red-500">-${financials.platformFee.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
      </div>

      {financials.shippingCost > 0 && (
        <div className="flex justify-between">
          <span className="text-gray-400">Shipping Cost</span>
          <span className="text-red-500">-${financials.shippingCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
        </div>
      )}

      {financials.sorterPay > 0 && (
        <div className="flex justify-between">
          <span className="text-gray-400">Sorter Pay ({streamDurationHours} hours)</span>
          <span className="text-red-500">-${financials.sorterPay.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
        </div>
      )}

      <div className="flex justify-between border-t border-dark-border pt-2">
        <span className="text-gray-400">Intermediate Profit</span>
        <span className={financials.intermediateProfit >= 0 ? 'text-green-500' : 'text-red-500'}>
          ${Math.abs(financials.intermediateProfit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </span>
      </div>

      <div className="flex justify-between">
        <span className="text-gray-400">Streamer Pay {getStreamerPayLabel()}</span>
        <span className="text-red-500">-${financials.streamerPay.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
      </div>

      <div className="flex justify-between border-t border-dark-border pt-2">
        <span className="font-medium text-white">Final Net Profit</span>
        <div className="text-right">
          <span className={financials.netProfit >= 0 ? 'text-green-500' : 'text-red-500'}>
            ${Math.abs(financials.netProfit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </span>
          <div className="text-sm text-gray-400">
            Margin: {netProfitMargin.toFixed(2)}%
          </div>
        </div>
      </div>
    </div>
  );
};