
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Tables } from "@/integrations/supabase/types";

export type ProfileWithOrganization = Tables<"profiles"> & { 
  canViewAllStreams?: boolean 
};

export const useProfile = (options = { withToast: false }) => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();

        if (sessionError || !session?.user) {
          console.log('No active session found');
          throw new Error('Not authenticated');
        }

        // First try to get existing profile
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('id, first_name, last_name, username, organization_id, role, organization_name, permissions')
          .eq('id', session.user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
          throw profileError;
        }

        // If no profile exists, create one
        if (!profile) {
          console.log('No profile found, creating new profile');
          const { data: newProfile, error: createError } = await supabase
            .from('profiles')
            .insert({
              id: session.user.id,
              username: session.user.email,
              first_name: session.user.user_metadata?.first_name || 'New',
              last_name: session.user.user_metadata?.last_name || 'User',
              role: 'user'
            })
            .select()
            .single();

          if (createError) {
            console.error('Error creating profile:', createError);
            throw createError;
          }

          return {
            ...newProfile,
            canViewAllStreams: false
          } as ProfileWithOrganization;
        }

        // Fetch user settings to determine if streamers can view all streams
        const { data: userSettings, error: settingsError } = await supabase
          .from('user_settings')
          .select('streamers_view_all')
          .eq('organization_id', profile.organization_id)
          .eq('user_id', profile.id)
          .maybeSingle();
          
        if (settingsError && !settingsError.message.includes('No rows found')) {
          console.error('Error fetching user settings:', settingsError);
        }

        // Calculate stream access permissions
        let canViewAllStreams = false;
        
        // Admins and managers can always see all streams
        if (profile.role === 'admin' || profile.role === 'manager') {
          canViewAllStreams = true;
        } 
        // Streamers can see all streams if the setting is enabled
        else if (profile.role === 'streamer' && userSettings?.streamers_view_all) {
          canViewAllStreams = true;
        }

        return {
          ...profile,
          canViewAllStreams
        } as ProfileWithOrganization;
      } catch (error: any) {
        console.error('Error in profile query:', error);
        // Only show toast for network or unexpected errors if withToast is true
        if (options.withToast && !error.message?.includes('Not authenticated')) {
          toast.error('Failed to load profile data');
        }
        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error?.message?.includes('Not authenticated')) {
        return false;
      }
      // Don't retry if we get a 400-level error
      if (error?.status >= 400 && error?.status < 500) {
        return false;
      }
      return failureCount < 2;
    },
    retryDelay: 1000,
    staleTime: 30000,
    gcTime: 3600000,
    refetchOnWindowFocus: false,
  });
};
