
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, BarChart, Bar, Legend
} from "recharts";
import { StreamerPerformanceChart } from "./charts/StreamerPerformanceChart";
import { PerformanceMetricsTable } from "./tables/PerformanceMetricsTable";

export const PerformanceAnalytics = () => {
  const { data: profitData, isLoading: profitLoading } = useQuery({
    queryKey: ['analytics-profit-trend'],
    queryFn: async () => {
      // Get user settings for profit calculation
      const { data: settings } = await supabase
        .from('user_settings')
        .select('*')
        .single();
      
      // Get streams with related items
      const { data: streams, error } = await supabase
        .from('streams')
        .select(`
          id,
          start_time,
          total_sales,
          platform,
          stream_items (
            quantity_sold,
            inventory_item:inventory_items (
              cost_basis
            )
          )
        `);
      
      if (error) throw error;
      
      // Calculate profit for each stream
      const streamsWithProfit = streams.map((stream: any) => {
        const productCosts = stream.stream_items.reduce((sum: number, item: any) => {
          const costBasis = Number(item.inventory_item?.cost_basis) || 0;
          return sum + ((Number(item.quantity_sold) || 0) * costBasis);
        }, 0);
        
        // Simple profit calculation for analytics
        const grossProfit = Number(stream.total_sales) - productCosts;
        const date = new Date(stream.start_time);
        const monthYear = date.toLocaleString('default', { month: 'short', year: 'numeric' });
        
        return {
          id: stream.id,
          month: monthYear,
          date: stream.start_time,
          sales: Number(stream.total_sales),
          cost: productCosts,
          profit: grossProfit
        };
      });
      
      // Group by month
      const profitByMonth = streamsWithProfit.reduce((acc: Record<string, any>, stream) => {
        if (!acc[stream.month]) {
          acc[stream.month] = {
            month: stream.month,
            sales: 0,
            cost: 0,
            profit: 0
          };
        }
        
        acc[stream.month].sales += stream.sales;
        acc[stream.month].cost += stream.cost;
        acc[stream.month].profit += stream.profit;
        
        return acc;
      }, {});
      
      // Convert to array and sort by date
      return Object.values(profitByMonth).sort((a: any, b: any) => {
        const dateA = new Date(a.month);
        const dateB = new Date(b.month);
        return dateA.getTime() - dateB.getTime();
      });
    }
  });

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card className="bg-dark-card border-dark-border">
          <CardHeader>
            <CardTitle className="text-lg font-medium text-gray-400">
              Profit vs Revenue Trend
            </CardTitle>
          </CardHeader>
          <CardContent className="h-[400px]">
            {profitLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={profitData || []}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                  <XAxis dataKey="month" stroke="#666" />
                  <YAxis stroke="#666" />
                  <Tooltip 
                    contentStyle={{ backgroundColor: '#222', border: '1px solid #444' }}
                    labelStyle={{ color: '#ddd' }}
                  />
                  <Legend />
                  <Bar dataKey="sales" stackId="a" fill="#8884d8" name="Revenue" />
                  <Bar dataKey="cost" stackId="a" fill="#82ca9d" name="Cost" />
                  <Bar dataKey="profit" fill="#ffc658" name="Profit" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>

        <StreamerPerformanceChart />
      </div>

      <PerformanceMetricsTable />
    </div>
  );
};
