
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { useState } from "react";

interface AddPlatformDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onAddPlatform: (name: string, fee: number) => void;
  isLoading: boolean;
}

export const AddPlatformDialog = ({
  isOpen,
  onOpenChange,
  onAddPlatform,
  isLoading
}: AddPlatformDialogProps) => {
  const [newPlatformName, setNewPlatformName] = useState("");
  const [newPlatformFee, setNewPlatformFee] = useState("");
  const { toast } = useToast();

  const handleAddPlatform = () => {
    if (!newPlatformName || !newPlatformFee) {
      toast({
        title: "Error",
        description: "Please fill in all fields",
        variant: "destructive",
      });
      return;
    }

    const feeValue = parseFloat(newPlatformFee);
    if (isNaN(feeValue)) {
      toast({
        title: "Error",
        description: "Fee must be a valid number",
        variant: "destructive",
      });
      return;
    }

    onAddPlatform(newPlatformName, feeValue);
    setNewPlatformName("");
    setNewPlatformFee("");
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="bg-dark-card border-dark-border">
        <DialogHeader>
          <DialogTitle className="text-white">Add New Platform</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <FormItem>
            <FormLabel className="text-gray-200">Platform Name</FormLabel>
            <FormControl>
              <Input
                value={newPlatformName}
                onChange={(e) => setNewPlatformName(e.target.value)}
                className="bg-dark-muted text-white border-dark-border"
              />
            </FormControl>
          </FormItem>
          <FormItem>
            <FormLabel className="text-gray-200">Fee Percentage</FormLabel>
            <FormControl>
              <Input
                type="number"
                step="0.01"
                value={newPlatformFee}
                onChange={(e) => setNewPlatformFee(e.target.value)}
                className="bg-dark-muted text-white border-dark-border"
              />
            </FormControl>
          </FormItem>
          <Button
            onClick={handleAddPlatform}
            className="w-full"
            disabled={isLoading}
          >
            Add Platform
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
