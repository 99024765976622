
import { useInventoryData } from "@/hooks/useInventoryData";
import { useState } from "react";
import { 
  Table, TableBody, TableCell, TableHead, 
  TableHeader, TableRow 
} from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const TopInventoryItemsTable = () => {
  const { data: inventoryData, isLoading } = useInventoryData();
  const [sortColumn, setSortColumn] = useState("totalValue");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  // Calculate metrics for each inventory item
  const itemsWithMetrics = inventoryData?.items.map(item => {
    const totalValue = (Number(item.market_value) || 0) * (Number(item.quantity) || 0);
    const totalCost = (Number(item.cost_basis) || 0) * (Number(item.quantity) || 0);
    const potentialProfit = totalValue - totalCost;
    const profitMargin = totalCost > 0 ? (potentialProfit / totalCost) * 100 : 0;
    
    return {
      id: item.id,
      name: item.name,
      category: item.category || 'Uncategorized',
      quantity: Number(item.quantity) || 0,
      costBasis: Number(item.cost_basis) || 0,
      marketValue: Number(item.market_value) || 0,
      totalCost,
      totalValue,
      potentialProfit,
      profitMargin
    };
  }) || [];

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedItems = itemsWithMetrics ? [...itemsWithMetrics].sort((a, b) => {
    const directionModifier = sortDirection === "asc" ? 1 : -1;
    
    if (a[sortColumn as keyof typeof a] < b[sortColumn as keyof typeof b]) {
      return -1 * directionModifier;
    }
    if (a[sortColumn as keyof typeof a] > b[sortColumn as keyof typeof b]) {
      return 1 * directionModifier;
    }
    return 0;
  }).slice(0, 10) : [];

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader>
        <CardTitle className="text-lg font-medium text-gray-400">
          Top Inventory Items by Value
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer"
                  onClick={() => handleSort("name")}
                >
                  Item Name
                  {sortColumn === "name" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer"
                  onClick={() => handleSort("category")}
                >
                  Category
                  {sortColumn === "category" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("quantity")}
                >
                  Quantity
                  {sortColumn === "quantity" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("totalValue")}
                >
                  Total Value
                  {sortColumn === "totalValue" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("totalCost")}
                >
                  Total Cost
                  {sortColumn === "totalCost" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("potentialProfit")}
                >
                  Potential Profit
                  {sortColumn === "potentialProfit" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center py-4">
                    <div className="flex justify-center">
                      <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : sortedItems.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center py-4 text-gray-400">
                    No inventory data available
                  </TableCell>
                </TableRow>
              ) : (
                sortedItems.map((item) => (
                  <TableRow key={item.id} className="border-t border-dark-border">
                    <TableCell className="text-white">{item.name}</TableCell>
                    <TableCell className="text-white">{item.category}</TableCell>
                    <TableCell className="text-white text-right">{item.quantity}</TableCell>
                    <TableCell className="text-white text-right">
                      ${item.totalValue.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell className="text-white text-right">
                      ${item.totalCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell className={`text-right ${item.potentialProfit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      ${Math.abs(item.potentialProfit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};
