import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Form } from "@/components/ui/form";
import { PlatformFeesCard } from "./PlatformFeesCard";
import { CostTypeCard } from "./CostTypeCard";
import { UseFormReturn } from "react-hook-form";
import { SettingsFormValues } from "./types";
import { UsersTab } from "./UsersTab";

interface SettingsTabsProps {
  form: UseFormReturn<SettingsFormValues>;
}

export const SettingsTabs = ({ form }: SettingsTabsProps) => {
  return (
    <Tabs defaultValue="platform" className="space-y-6">
      <TabsList className="bg-dark-card border border-dark-border p-1 rounded-lg grid w-full grid-cols-2">
        <TabsTrigger 
          value="platform" 
          className="data-[state=active]:bg-dark-muted data-[state=active]:text-white text-gray-400"
        >
          Platform Settings
        </TabsTrigger>
        <TabsTrigger 
          value="users" 
          className="data-[state=active]:bg-dark-muted data-[state=active]:text-white text-gray-400"
        >
          Users
        </TabsTrigger>
      </TabsList>

      <TabsContent value="platform" className="space-y-6">
        <Form {...form}>
          <div className="space-y-6">
            <PlatformFeesCard form={form} />
            <CostTypeCard 
              form={form}
              title="Shipping Cost"
              typeField="shipping_cost_type"
              valueField="shipping_cost_value"
            />
            <CostTypeCard 
              form={form}
              title="Streamer Pay"
              typeField="streamer_pay_type"
              valueField="streamer_pay_value"
            />
            <CostTypeCard 
              form={form}
              title="Sorter Pay"
              typeField="sorter_pay_type"
              valueField="sorter_pay_value"
            />
          </div>
        </Form>
      </TabsContent>

      <TabsContent value="users">
        <UsersTab />
      </TabsContent>
    </Tabs>
  );
};