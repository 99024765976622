import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

interface FormData {
  category: string;
}

interface CategoryFormProps {
  form: UseFormReturn<FormData>;
  onSubmit: (e: React.FormEvent) => void;
  initialCategory: string | null;
  isLoading: boolean;
}

export const CategoryForm = ({
  form,
  onSubmit,
  initialCategory,
  isLoading,
}: CategoryFormProps) => {
  const { register, setValue, watch } = form;
  const categoryName = watch("category");

  useEffect(() => {
    if (initialCategory) {
      setValue("category", initialCategory);
    }
  }, [initialCategory, setValue]);

  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="category" className="text-white">Category Name</Label>
        <Input
          id="category"
          {...register("category", { required: true })}
          className="bg-dark-card border-dark-border text-white"
          placeholder="Enter category name"
          disabled={isLoading}
          autoComplete="off"
        />
      </div>
      <div className="flex justify-end gap-2">
        <Button
          type="submit"
          disabled={isLoading || !categoryName?.trim()}
          className="bg-white text-black hover:bg-gray-100"
        >
          {isLoading ? 'Saving...' : initialCategory ? 'Update' : 'Create'}
        </Button>
      </div>
    </form>
  );
};