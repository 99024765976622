import { toast } from "sonner";
import { useDashboardData } from "@/hooks/useDashboardData";
import { calculateInventoryValues } from "@/utils/inventoryCalculations";
import { InventoryValueCard } from "./InventoryValueCard";
import { LastCheckCard } from "./LastCheckCard";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";

export const DashboardMetrics = () => {
  const { 
    inventoryItems, 
    lastCheck, 
    inventoryError, 
    lastCheckError,
    isLoading 
  } = useDashboardData();

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 gap-6">
        <div className="h-32 bg-dark-card animate-pulse rounded-lg"></div>
        <div className="h-32 bg-dark-card animate-pulse rounded-lg"></div>
      </div>
    );
  }

  if (inventoryError || lastCheckError) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          There was a problem loading your dashboard data. Please try refreshing the page.
        </AlertDescription>
      </Alert>
    );
  }

  // Filter active items and calculate values
  const activeItems = inventoryItems?.filter(item => item.status === 'active') || [];
  const { totalInventoryValue, awaitingInventoryValue } = calculateInventoryValues(activeItems);

  // Log values for debugging
  console.log('Dashboard metrics - Active items:', activeItems.length);
  console.log('Dashboard metrics - Total value:', totalInventoryValue);
  console.log('Dashboard metrics - Awaiting value:', awaitingInventoryValue);
  console.log('Last check data:', lastCheck);

  return (
    <div className="grid grid-cols-2 gap-6">
      <InventoryValueCard 
        totalValue={totalInventoryValue} 
        awaitingValue={awaitingInventoryValue} 
      />
      <LastCheckCard />
    </div>
  );
};