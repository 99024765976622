
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { UserPlus, Shield, User, Trash2 } from "lucide-react";
import { InviteUserDialog } from "./dialogs/InviteUserDialog";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { RolesManagementCard } from "./RolesManagementCard";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Avatar } from "@/components/ui/avatar";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "sonner";
import { permissions, rolePermissions } from "./types/permissions";
import { Checkbox } from "@/components/ui/checkbox";
import { useProfile } from "@/hooks/useProfile";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";

export const UsersTab = () => {
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const { data: currentProfile } = useProfile();
  const queryClient = useQueryClient();
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const { data: users, isLoading } = useQuery({
    queryKey: ['organization-users', currentProfile?.organization_id],
    enabled: !!currentProfile?.organization_id,
    queryFn: async () => {
      console.log('Fetching users for organization:', currentProfile?.organization_id);
      
      const { data: orgUsers, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('organization_id', currentProfile?.organization_id);

      if (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to fetch organization users');
        throw error;
      }

      console.log('Found users:', orgUsers);
      return orgUsers;
    },
    refetchOnWindowFocus: true,
    staleTime: 0
  });

  const updateUserMutation = useMutation({
    mutationFn: async ({ userId, role }: { userId: string, role: string }) => {
      console.log('Updating user role:', { userId, role });
      
      const defaultPermissions = rolePermissions[role as keyof typeof rolePermissions] || [];
      console.log('Default permissions for role:', defaultPermissions);
      
      const { error } = await supabase
        .from('profiles')
        .update({ 
          role,
          permissions: defaultPermissions
        })
        .eq('id', userId);

      if (error) {
        console.error('Update error:', error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization-users'] });
      toast.success('User updated successfully');
    },
    onError: (error) => {
      console.error('Error updating user:', error);
      toast.error('Failed to update user');
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: async (userId: string) => {
      setDeleteError(null);
      const { data, error } = await supabase.functions.invoke('delete-user', {
        body: { userId }
      });

      if (error) {
        console.error('Delete user error response:', error);
        let errorMessage = 'Failed to delete user';
        
        try {
          // Try to parse the error message from the response
          const errorBody = JSON.parse(error.message);
          if (errorBody.error) {
            errorMessage = errorBody.error;
          } else if (error.message) {
            errorMessage = error.message;
          }
        } catch (e) {
          console.error('Error parsing error response:', e);
        }
        
        setDeleteError(errorMessage);
        throw new Error(errorMessage);
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization-users'] });
      toast.success('User deleted successfully');
      setDeleteDialogOpen(false);
      setUserToDelete(null);
      setDeleteError(null);
    },
    onError: (error: Error) => {
      console.error('Error deleting user:', error);
      toast.error(error.message);
      // Don't close the dialog on error
    },
  });

  const handleRoleChange = async (userId: string, newRole: string) => {
    try {
      await updateUserMutation.mutateAsync({
        userId,
        role: newRole
      });
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      await deleteUserMutation.mutateAsync(userId);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  // Filter out admin role from selection unless current user is admin
  const availableRoles = currentProfile?.role === 'admin' 
    ? ['admin', 'manager', 'streamer']
    : ['manager', 'streamer'];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium">Users</h3>
          <p className="text-sm text-muted-foreground">
            Manage users in your organization.
          </p>
        </div>
        <Button 
          onClick={() => setIsInviteDialogOpen(true)}
          className="gap-2"
        >
          <UserPlus className="h-4 w-4" />
          Invite User
        </Button>
      </div>

      <div className="grid gap-6">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold flex items-center gap-2">
              <User className="h-5 w-5" />
              Organization Users
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {isLoading ? (
                <div className="text-sm text-muted-foreground">Loading users...</div>
              ) : !users || users.length === 0 ? (
                <div className="text-sm text-muted-foreground">
                  No users found for organization ID: {currentProfile?.organization_id}
                </div>
              ) : (
                <div className="grid gap-4">
                  {users.map((user) => (
                    <div 
                      key={user.id}
                      className="space-y-4"
                    >
                      <div className="flex items-center justify-between p-4 rounded-lg bg-card border">
                        <div className="flex items-center gap-3">
                          <Avatar>
                            <div className="flex h-full w-full items-center justify-center bg-muted">
                              <User className="h-4 w-4" />
                            </div>
                          </Avatar>
                          <div>
                            <div className="font-medium">{user.full_name}</div>
                            <div className="text-sm text-muted-foreground">{user.username}</div>
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <Select
                            value={user.role}
                            onValueChange={(value) => handleRoleChange(user.id, value)}
                            disabled={user.id === currentProfile?.id || (currentProfile?.role !== 'admin' && user.role === 'admin')}
                          >
                            <SelectTrigger className="w-[140px]">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {availableRoles.map(role => (
                                <SelectItem key={role} value={role} className="capitalize">
                                  {role}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>

                          {currentProfile?.role === 'admin' && user.id !== currentProfile.id && (
                            <AlertDialog 
                              open={deleteDialogOpen && userToDelete === user.id} 
                              onOpenChange={(open) => {
                                setDeleteDialogOpen(open);
                                if (!open) {
                                  setUserToDelete(null);
                                  setDeleteError(null);
                                }
                              }}
                            >
                              <AlertDialogTrigger asChild>
                                <Button
                                  variant="destructive"
                                  size="sm"
                                  onClick={() => setUserToDelete(user.id)}
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </AlertDialogTrigger>
                              <AlertDialogContent>
                                <AlertDialogHeader>
                                  <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                  <AlertDialogDescription className="space-y-2">
                                    <p>
                                      This action cannot be undone. This will permanently delete the user
                                      and all of their data.
                                    </p>
                                    {deleteError && (
                                      <p className="text-destructive font-medium">
                                        Error: {deleteError}
                                      </p>
                                    )}
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                                  <AlertDialogAction
                                    onClick={() => handleDeleteUser(user.id)}
                                    className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                                  >
                                    Delete
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          )}
                        </div>
                      </div>

                      {selectedUserId === user.id && (
                        <div className="p-4 rounded-lg bg-card border">
                          <div className="flex justify-between items-center mb-4">
                            <h4 className="text-sm font-medium">Role Permissions</h4>
                          </div>
                          <div className="grid grid-cols-2 gap-4">
                            {permissions.map((permission) => (
                              <div key={permission.id} className="flex items-start space-x-2">
                                <Checkbox
                                  id={`${user.id}-${permission.id}`}
                                  checked={(user.permissions || []).includes(permission.id)}
                                  disabled
                                />
                                <div className="grid gap-1.5 leading-none">
                                  <label
                                    htmlFor={`${user.id}-${permission.id}`}
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                  >
                                    {permission.label}
                                  </label>
                                  <p className="text-sm text-muted-foreground">
                                    {permission.description}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        <RolesManagementCard organizationId={currentProfile?.organization_id} />
      </div>

      <InviteUserDialog 
        open={isInviteDialogOpen} 
        onOpenChange={setIsInviteDialogOpen} 
      />
    </div>
  );
};
