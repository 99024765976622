
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useEffect } from "react";

interface StreamBasicInfoProps {
  platform?: string;
  streamer?: string;
  sorter?: string;
  onPlatformChange: (value: string) => void;
  onStreamerChange: (value: string) => void;
  onSorterChange: (value: string) => void;
}

export const StreamBasicInfo = ({
  platform = "",
  streamer = "",
  sorter = "",
  onPlatformChange,
  onStreamerChange,
  onSorterChange,
}: StreamBasicInfoProps) => {
  const { data: profile } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();
      if (error) throw error;
      return data;
    },
  });

  // Fetch platforms from the organization
  const { data: platforms = [] } = useQuery({
    queryKey: ['platforms', profile?.organization_id],
    queryFn: async () => {
      console.log('Fetching platforms for organization:', profile?.organization_id);
      if (!profile?.organization_id) return [];

      // Fetch all active platforms for the organization
      const { data, error: platformError } = await supabase
        .from('platforms')
        .select('*')
        .eq('organization_id', profile.organization_id)
        .eq('status', 'active')
        .order('name');

      if (platformError) {
        console.error('Error fetching platforms:', platformError);
        throw platformError;
      }
      
      console.log('Fetched platforms:', data);
      return data || [];
    },
    enabled: !!profile?.organization_id
  });

  // Auto-fill streamer name when profile loads, only if streamer name is empty
  useEffect(() => {
    if (profile?.first_name && profile?.last_name && !streamer) {
      const fullName = `${profile.first_name} ${profile.last_name}`;
      onStreamerChange(fullName);
    }
  }, [profile, onStreamerChange, streamer]);

  // Reset platform if it's not in the current organization's platform list
  useEffect(() => {
    if (platform && platforms.length > 0) {
      const platformExists = platforms.some(p => p.name === platform);
      if (!platformExists) {
        onPlatformChange("");
      }
    }
  }, [platform, platforms, onPlatformChange]);

  return (
    <div className="space-y-4">
      <div>
        <Label htmlFor="platform">Platform</Label>
        <Select value={platform} onValueChange={onPlatformChange}>
          <SelectTrigger id="platform">
            <SelectValue placeholder="Select platform" />
          </SelectTrigger>
          <SelectContent>
            {platforms.length > 0 ? (
              platforms.map((platform) => (
                <SelectItem key={platform.id} value={platform.name}>
                  {platform.name}
                </SelectItem>
              ))
            ) : (
              <SelectItem value="no-platforms" disabled>
                No platforms available
              </SelectItem>
            )}
          </SelectContent>
        </Select>
      </div>

      <div>
        <Label htmlFor="streamer">Streamer</Label>
        <Input
          id="streamer"
          value={streamer}
          onChange={(e) => onStreamerChange(e.target.value)}
          placeholder={profile ? `${profile.first_name} ${profile.last_name}` : "Enter streamer name"}
        />
      </div>

      <div>
        <Label htmlFor="sorter">Sorter (Optional)</Label>
        <Input
          id="sorter"
          value={sorter}
          onChange={(e) => onSorterChange(e.target.value)}
          placeholder="Enter sorter name"
        />
      </div>
    </div>
  );
};
