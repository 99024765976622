import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Package } from "lucide-react";

interface InventoryValueCardProps {
  totalValue: number;
  awaitingValue: number;
}

export const InventoryValueCard = ({ totalValue, awaitingValue }: InventoryValueCardProps) => {
  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="text-lg font-medium text-gray-400">
          Total Inventory Value
        </CardTitle>
        <Package className="h-4 w-4 text-gray-400" />
      </CardHeader>
      <CardContent>
        <div className="text-3xl font-bold text-white">${totalValue.toLocaleString()}</div>
        <p className="text-sm text-gray-400 mt-1">Awaiting: ${awaitingValue.toLocaleString()}</p>
      </CardContent>
    </Card>
  );
};