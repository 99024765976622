import { useCallback, memo, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useCategory } from "@/hooks/useCategory";
import { CategoryForm } from "./category/CategoryForm";
import { toast } from "sonner";

interface CategoryDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  editingCategory?: string | null;
  setEditingCategory?: (category: string | null) => void;
  onSuccess?: () => void;
}

interface FormData {
  category: string;
}

export const CategoryDialog = memo(({
  open,
  onOpenChange,
  editingCategory,
  setEditingCategory,
  onSuccess,
}: CategoryDialogProps) => {
  const { manageCategory, isSubmitting } = useCategory();
  const [isProcessing, setIsProcessing] = useState(false);
  const form = useForm<FormData>({
    defaultValues: {
      category: editingCategory || "",
    },
  });

  const handleClose = useCallback(() => {
    if (!isProcessing && !isSubmitting) {
      form.reset();
      if (setEditingCategory) {
        setEditingCategory(null);
      }

      onOpenChange(false);
    }
  }, [isProcessing, isSubmitting, form, setEditingCategory, onOpenChange]);
  useEffect(() => {
    if (open) {
      document.body.style.pointerEvents = 'auto'; // Allow pointer events
    }
    return () => {
      document.body.style.pointerEvents = ''; // Reset pointer events on close
    };
  }, [open]);

  const onSubmit = async (data: FormData) => {
    if (isProcessing || isSubmitting) {
      console.log('Preventing duplicate submission');
      return;
    }

    try {
      setIsProcessing(true);
      console.log("Starting category submission:", data);

      if (!data.category.trim()) {
        toast.error("Category name cannot be empty");
        return;
      }

      const success = await manageCategory(data.category, editingCategory);

      if (success) {
        console.log("Category created/updated successfully");
        if (onSuccess) {
          await onSuccess();
        }
        handleClose();
        toast.success(editingCategory ? "Category updated successfully" : "Category created successfully");
      }
    } catch (error) {
      console.error('Error submitting category:', error);
      toast.error("An error occurred while managing the category");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={handleClose}
    >
      <DialogContent className="bg-dark-card border-dark-border text-white">
        <DialogHeader>
          <DialogTitle className="text-white">
            {editingCategory ? 'Edit Category' : 'Add Category'}
          </DialogTitle>
          <DialogDescription className="text-gray-400">
            {editingCategory ? 'Update an existing category' : 'Create a new category for your inventory items'}
          </DialogDescription>
        </DialogHeader>
        <CategoryForm
          form={form}
          onSubmit={form.handleSubmit(onSubmit)}
          initialCategory={editingCategory}
          isLoading={isProcessing || isSubmitting}
        />
      </DialogContent>
    </Dialog>
  );
});

CategoryDialog.displayName = 'CategoryDialog';