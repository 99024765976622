
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Tables } from "@/integrations/supabase/types";
import { toast } from "sonner";
import { useEffect, useRef } from "react";

export type InventoryItem = Tables<"inventory_items">;

export interface InventoryDataResponse {
  items: InventoryItem[];
  total: number;
  pageSize: number;
  currentPage: number;
}

// Reduce page size for faster initial loading
const PAGE_SIZE = 50;

export const useInventoryData = (page = 0) => {
  const channelRef = useRef<ReturnType<typeof supabase.channel> | null>(null);

  const query = useQuery<InventoryDataResponse>({
    queryKey: ['dashboard-inventory', page],
    queryFn: async () => {
      try {
        console.log('Starting inventory data fetch for page:', page);
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError || !user) {
          console.error('Authentication error:', authError);
          toast.error('Please sign in to view inventory');
          return { items: [], total: 0, pageSize: PAGE_SIZE, currentPage: page };
        }

        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('organization_id, role')
          .eq('id', user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
          toast.error('Error loading profile data');
          return { items: [], total: 0, pageSize: PAGE_SIZE, currentPage: page };
        }

        // Calculate pagination range
        const from = page * PAGE_SIZE;
        const to = from + PAGE_SIZE - 1;

        let query = supabase
          .from('inventory_items')
          .select('*', { count: 'exact' });

        if (profile?.organization_id) {
          query = query.eq('organization_id', profile.organization_id);
        } else {
          query = query.eq('user_id', user.id);
        }

        // Add pagination for faster initial load
        // Only paginate if page > 0 to maintain compatibility
        if (page > 0) {
          query = query.range(from, to);
        } else {
          // For first page, limit results to improve performance
          query = query.limit(PAGE_SIZE);
        }

        const { data: items, error: itemsError, count } = await query;

        if (itemsError) {
          console.error('Error fetching inventory items:', itemsError);
          toast.error('Error loading inventory items');
          return { items: [], total: 0, pageSize: PAGE_SIZE, currentPage: page };
        }

        console.log('Inventory fetch complete:', items?.length || 0, 'items');
        return {
          items: items as InventoryItem[] || [],
          total: count || 0,
          pageSize: PAGE_SIZE,
          currentPage: page
        };
      } catch (error: any) {
        console.error('Error in useInventoryData:', error);
        toast.error('Failed to load inventory data');
        return { items: [], total: 0, pageSize: PAGE_SIZE, currentPage: page };
      }
    },
    staleTime: 60000, // Increase stale time to 60 seconds to reduce refetches
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // Clean up any existing subscription first
    if (channelRef.current) {
      supabase.removeChannel(channelRef.current);
      channelRef.current = null;
    }

    const setupRealtimeSubscription = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        const { data: profile } = await supabase
          .from('profiles')
          .select('organization_id')
          .eq('id', user.id)
          .single();

        const channelName = profile?.organization_id 
          ? `inventory_org_${profile.organization_id}`
          : `inventory_user_${user.id}`;

        // Use a more efficient filter to reduce unnecessary events
        const filter = profile?.organization_id 
          ? `organization_id=eq.${profile.organization_id}`
          : `user_id=eq.${user.id}`;

        channelRef.current = supabase
          .channel(channelName)
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: 'inventory_items',
              filter
            },
            (payload) => {
              console.log('Real-time update received:', payload.eventType);
              // Only refetch when needed
              query.refetch();
            }
          )
          .subscribe();

      } catch (error) {
        console.error('Error setting up realtime subscription:', error);
      }
    };

    setupRealtimeSubscription();

    return () => {
      if (channelRef.current) {
        console.log('Cleaning up real-time subscription');
        supabase.removeChannel(channelRef.current);
        channelRef.current = null;
      }
    };
  }, [query]);

  return query;
};
