
import { WholesaleCustomer } from "./types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Edit, Trash2, Phone, Mail } from "lucide-react";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { CustomerForm } from "./CustomerForm";
import { useWholesaleCustomers } from "@/hooks/useWholesaleCustomers";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";

interface CustomersListProps {
  customers: WholesaleCustomer[];
  isLoading: boolean;
}

export const CustomersList = ({ customers, isLoading }: CustomersListProps) => {
  const [selectedCustomer, setSelectedCustomer] = useState<WholesaleCustomer | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { updateCustomer, deleteCustomer } = useWholesaleCustomers();

  const handleEdit = (customer: WholesaleCustomer) => {
    setSelectedCustomer(customer);
    setIsEditDialogOpen(true);
  };

  const handleDelete = (customer: WholesaleCustomer) => {
    setSelectedCustomer(customer);
    setIsDeleteDialogOpen(true);
  };

  // Format full address from individual components
  const formatAddress = (customer: WholesaleCustomer): string => {
    const parts = [];
    if (customer.street_address) parts.push(customer.street_address);
    
    const cityState = [];
    if (customer.city) cityState.push(customer.city);
    if (customer.state) cityState.push(customer.state);
    if (cityState.length > 0) parts.push(cityState.join(', '));
    
    if (customer.zip_code) parts.push(customer.zip_code);
    
    return parts.length > 0 ? parts.join(', ') : '—';
  };

  if (isLoading) {
    return (
      <Card className="bg-dark-card border-dark-border">
        <CardHeader>
          <CardTitle>Customers</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="animate-pulse space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-12 bg-dark-muted rounded-md" />
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card className="bg-dark-card border-dark-border">
        <CardHeader>
          <CardTitle>Customers ({customers.length})</CardTitle>
        </CardHeader>
        <CardContent>
          {customers.length === 0 ? (
            <div className="text-center py-6">
              <p className="text-gray-400">No customers found</p>
              <p className="text-sm text-gray-500 mt-1">
                Add your first customer to get started
              </p>
            </div>
          ) : (
            <div className="rounded-md border border-dark-border">
              <Table>
                <TableHeader className="bg-dark-muted">
                  <TableRow>
                    <TableHead className="text-white">Name</TableHead>
                    <TableHead className="text-white">Contact</TableHead>
                    <TableHead className="text-white">Address</TableHead>
                    <TableHead className="text-white">Notes</TableHead>
                    <TableHead className="text-white text-right">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {customers.map((customer) => (
                    <TableRow key={customer.id} className="border-dark-border">
                      <TableCell className="font-medium">{customer.name}</TableCell>
                      <TableCell>
                        <div className="flex flex-col space-y-1">
                          {customer.email && (
                            <div className="flex items-center gap-1">
                              <Mail className="h-3 w-3 text-gray-400" />
                              <span className="text-sm">{customer.email}</span>
                            </div>
                          )}
                          {customer.phone && (
                            <div className="flex items-center gap-1">
                              <Phone className="h-3 w-3 text-gray-400" />
                              <span className="text-sm">{customer.phone}</span>
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="max-w-[250px] truncate">
                        {formatAddress(customer)}
                      </TableCell>
                      <TableCell className="max-w-[250px] truncate">
                        {customer.notes || "—"}
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex justify-end gap-2">
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleEdit(customer)}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleDelete(customer)}
                            className="text-red-500 hover:text-red-600"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Edit Customer Dialog */}
      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent className="sm:max-w-[600px] bg-[#121212] border-gray-800">
          <DialogTitle className="text-white">Edit Customer</DialogTitle>
          {selectedCustomer && (
            <CustomerForm
              customer={selectedCustomer}
              onSubmit={async (data) => {
                await updateCustomer.mutateAsync({
                  id: selectedCustomer.id,
                  ...data,
                });
                setIsEditDialogOpen(false);
              }}
              onCancel={() => setIsEditDialogOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent className="bg-dark-card border-dark-border">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-white">Confirm Deletion</AlertDialogTitle>
            <AlertDialogDescription className="text-gray-400">
              Are you sure you want to delete{" "}
              <span className="font-medium text-white">{selectedCustomer?.name}</span>?
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="border-dark-border text-white">Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-red-500 hover:bg-red-600"
              onClick={async () => {
                if (selectedCustomer) {
                  await deleteCustomer.mutateAsync(selectedCustomer.id);
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
