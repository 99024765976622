import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronRight } from "lucide-react";
import { CollapsibleTrigger } from "@/components/ui/collapsible";

interface CategoryHeaderProps {
  category: string;
  itemCount: number;
  isExpanded: boolean;
}

export const CategoryHeader = ({ category, itemCount, isExpanded }: CategoryHeaderProps) => {
  return (
    <div className="flex items-center gap-2 py-2 px-1">
      <CollapsibleTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="p-0 hover:bg-transparent"
        >
          {isExpanded ? (
            <ChevronDown className="h-4 w-4 text-gray-400" />
          ) : (
            <ChevronRight className="h-4 w-4 text-gray-400" />
          )}
        </Button>
      </CollapsibleTrigger>
      <h5 className="text-sm font-medium text-gray-400">{category}</h5>
      <span className="text-xs text-gray-500">({itemCount} items)</span>
    </div>
  );
};