import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronRight, GripVertical } from "lucide-react";

interface InventoryTableHeaderProps {
  category: string;
  itemCount: number;
  totalValue: number;
  isExpanded: boolean;
  onToggleExpand: () => void;
  isDragging: boolean;
}

export const InventoryTableHeader = ({
  category,
  itemCount,
  totalValue,
  isExpanded,
  onToggleExpand,
  isDragging,
}: InventoryTableHeaderProps) => {
  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center gap-2">
        <Button 
          variant="ghost" 
          size="sm" 
          className="text-white hover:text-white"
          onClick={onToggleExpand}
        >
          {isExpanded ? (
            <ChevronDown className="w-4 h-4" />
          ) : (
            <ChevronRight className="w-4 h-4" />
          )}
        </Button>
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="sm"
            className="text-white hover:text-white cursor-move"
          >
            <GripVertical className="w-4 h-4" />
          </Button>
          <span className="text-white font-semibold">{category}</span>
          <span className="text-white">({itemCount} items)</span>
        </div>
      </div>
      <div className="text-right">
        <p className="text-2xl font-bold text-white">${totalValue.toLocaleString()}</p>
        <p className="text-sm text-white">Total Value</p>
      </div>
    </div>
  );
};