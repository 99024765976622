
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Squares } from "@/components/ui/squares-background";
import { AuthForm } from "@/components/auth/AuthForm";
import { LandingNav } from "@/components/landing/LandingNav";
import { LandingHero } from "@/components/landing/LandingHero";
import { FeatureGrid } from "@/components/landing/FeatureGrid";
import { LandingFooter } from "@/components/landing/LandingFooter";
import { SignUpForm } from "@/components/auth/SignUpForm";
import { toast } from "sonner";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  useEffect(() => {
    let mounted = true;

    const checkSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Session check error:', error);
          toast.error('Error checking authentication status');
          if (mounted) {
            setLoading(false);
          }
          return;
        }

        if (mounted) {
          if (session?.user) {
            console.log('Active session found, redirecting to home');
            navigate('/', { replace: true });
          } else {
            console.log('No active session found');
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Session check error:', error);
        toast.error('Error checking authentication status');
        if (mounted) {
          setLoading(false);
        }
      }
    };

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (!mounted) return;
      
      console.log('Auth state changed:', event);
      if (session) {
        console.log('New session detected, redirecting to home');
        navigate('/', { replace: true });
      }
    });

    checkSession();

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [navigate]);

  const handleShowAuth = () => {
    setShowAuthForm(true);
    setShowSignUpForm(false);
  };

  const handleShowSignUp = () => {
    setShowSignUpForm(true);
    setShowAuthForm(false);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-dark">
        <div className="animate-pulse text-white">Loading...</div>
      </div>
    );
  }

  if (showAuthForm) {
    return (
      <div className="flex min-h-screen w-full items-center justify-center bg-dark p-4">
        <div className="absolute inset-0 z-0">
          <Squares 
            direction="diagonal"
            speed={0.5}
            squareSize={40}
            borderColor="#333" 
            hoverFillColor="#222"
          />
        </div>
        <AuthForm />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#060606] text-white relative">
      <div className="absolute inset-0 z-0">
        <Squares 
          direction="diagonal"
          speed={0.5}
          squareSize={40}
          borderColor="#333" 
          hoverFillColor="#222"
        />
      </div>
      
      <LandingNav 
        onSignIn={handleShowAuth}
        onSignUp={handleShowSignUp}
      />
      
      <LandingHero 
        onGetStarted={handleShowSignUp}
        onSignIn={handleShowAuth}
      />
      
      <FeatureGrid />
      
      <LandingFooter />

      <SignUpForm 
        open={showSignUpForm} 
        onClose={() => setShowSignUpForm(false)} 
      />
    </div>
  );
};

export default Login;
