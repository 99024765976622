
import { useState } from "react";
import { AppLayout } from "@/components/layout/AppLayout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { CustomersList } from "@/components/wholesale/CustomersList";
import { OrdersList } from "@/components/wholesale/OrdersList";
import { PlusCircle } from "lucide-react";
import { CustomerForm } from "@/components/wholesale/CustomerForm";
import { OrderForm } from "@/components/wholesale/OrderForm";
import { useWholesaleCustomers } from "@/hooks/useWholesaleCustomers";
import { useWholesaleOrders } from "@/hooks/useWholesaleOrders";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";

const Wholesale = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("orders");
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  
  const { 
    customers, 
    isLoading: customersLoading, 
    createCustomer 
  } = useWholesaleCustomers();
  
  const { 
    orders, 
    isLoading: ordersLoading, 
    createOrder 
  } = useWholesaleOrders();

  const handleOpenOrderDialog = () => {
    setIsOrderDialogOpen(true);
  };

  const handleCloseOrderDialog = () => {
    setIsOrderDialogOpen(false);
  };

  const handleOpenCustomerDialog = () => {
    setIsCustomerDialogOpen(true);
  };

  const handleCloseCustomerDialog = () => {
    setIsCustomerDialogOpen(false);
  };

  return (
    <AppLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">Wholesale Management</h1>
          <div className="flex gap-2">
            <Button
              variant="outline"
              onClick={() => navigate("/streams")}
              className="border-dark-border text-white hover:text-white hover:bg-dark-muted"
            >
              Back to Streams
            </Button>
          </div>
        </div>

        <Tabs defaultValue="orders" value={activeTab} onValueChange={setActiveTab} className="space-y-6">
          <div className="flex items-center justify-between">
            <TabsList className="bg-dark-card border border-dark-border">
              <TabsTrigger
                value="orders"
                className="text-gray-400 data-[state=active]:bg-dark-muted data-[state=active]:text-white"
              >
                Orders
              </TabsTrigger>
              <TabsTrigger
                value="customers"
                className="text-gray-400 data-[state=active]:bg-dark-muted data-[state=active]:text-white"
              >
                Customers
              </TabsTrigger>
            </TabsList>
            
            {activeTab === "orders" && (
              <Button 
                onClick={handleOpenOrderDialog}
                className="gap-2"
              >
                <PlusCircle className="h-4 w-4" />
                New Order
              </Button>
            )}
            
            {activeTab === "customers" && (
              <Button 
                onClick={handleOpenCustomerDialog}
                className="gap-2"
              >
                <PlusCircle className="h-4 w-4" />
                New Customer
              </Button>
            )}
          </div>

          <TabsContent value="orders" className="space-y-4">
            <OrdersList orders={orders} isLoading={ordersLoading} />
          </TabsContent>

          <TabsContent value="customers" className="space-y-4">
            <CustomersList customers={customers} isLoading={customersLoading} />
          </TabsContent>
        </Tabs>
      </div>

      {/* Customer Dialog */}
      <Dialog open={isCustomerDialogOpen} onOpenChange={setIsCustomerDialogOpen}>
        <DialogContent className="sm:max-w-[600px] bg-[#121212] border-gray-800">
          <DialogTitle className="text-white">Add New Customer</DialogTitle>
          <CustomerForm 
            onSubmit={async (data) => {
              await createCustomer.mutateAsync(data);
              handleCloseCustomerDialog();
            }}
            onCancel={handleCloseCustomerDialog}
          />
        </DialogContent>
      </Dialog>

      {/* Order Dialog */}
      <Dialog open={isOrderDialogOpen} onOpenChange={setIsOrderDialogOpen}>
        <DialogContent className="sm:max-w-[900px] max-h-[90vh] overflow-y-auto bg-[#121212] border-gray-800">
          <DialogTitle className="text-white">Create New Order</DialogTitle>
          <OrderForm 
            customers={customers || []}
            onSubmit={async (data) => {
              await createOrder.mutateAsync(data);
              handleCloseOrderDialog();
            }}
            onCancel={handleCloseOrderDialog}
          />
        </DialogContent>
      </Dialog>
    </AppLayout>
  );
};

export default Wholesale;
