import { LucideIcon } from "lucide-react";

interface FeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  features: string[];
}

export const FeatureCard = ({ icon: Icon, title, description, features }: FeatureCardProps) => (
  <div className="bg-[#111111] rounded-lg p-6 xl:p-8 space-y-4 h-full">
    <div className="bg-[#222222] w-10 h-10 rounded-lg flex items-center justify-center">
      <Icon className="w-5 h-5 text-white" />
    </div>
    <h3 className="text-lg xl:text-xl font-semibold text-white">{title}</h3>
    <p className="text-gray-400">{description}</p>
    <ul className="space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="text-gray-400 flex items-center space-x-2">
          <span className="w-1 h-1 bg-gray-400 rounded-full"></span>
          <span>{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);