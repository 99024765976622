
import {
  LayoutDashboard, Package, Search, Bell, LogOut,
  Radio, ClipboardCheck, Settings, ShoppingBag, BarChart2
} from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

const menuItems = [
  { title: "Dashboard", icon: LayoutDashboard, url: "/" },
  { title: "Inventory", icon: Package, url: "/inventory" },
  { title: "Inventory Check", icon: ClipboardCheck, url: "/inventory-check" },
  { title: "Streams", icon: Radio, url: "/streams" },
  { title: "Wholesale", icon: ShoppingBag, url: "/wholesale" },
  { title: "Analytics", icon: BarChart2, url: "/analytics" },
  { title: "Settings", icon: Settings, url: "/settings" },
];

export function AppSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      queryClient.clear();
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      toast.success("Successfully signed out");
      navigate('/login');
    } catch (error: any) {
      console.error('Error signing out:', error);
      toast.error(error.message || "Error signing out. Please try again.");
    }
  };
  const isCurrentRoute = (url: string) => {
    return location.pathname === url;
  };

  return (
    <Sidebar className="flex-1 h-[calc(100vh-4rem)] border-r border-dark-border bg-background">
      <SidebarContent>
        <div className="h-16 bg-dark-card z-50 border-b border-dark-border flex items-center px-6">
          <img
            src="/lovable-uploads/06222c28-dc26-4689-90c3-e5f51b382a30.png"
            alt="CardQuant Logo"
            className="h-8 w-auto object-contain"
          />
        </div>
        <div className="px-4 py-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="w-full px-4 py-3 pl-12 bg-dark-card border border-dark-border text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-primary/20 transition-all"
            />
            <Search className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 transform -translate-y-1/2" />
          </div>
        </div>

        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <Link
                      to={item.url}
                      className={`flex items-center gap-4 px-4 py-3 rounded-lg transition-colors ${isCurrentRoute(item.url)
                        ? 'text-white bg-primary/20'
                        : 'text-gray-400 hover:text-white hover:bg-primary/10'
                        }`}
                    >
                      <item.icon className="w-5 h-5" />
                      <span className="text-base font-medium">{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>

        <div className="absolute bottom-0 left-0 right-0 p-4 space-y-4 border-t border-dark-border bg-dark-card">
          <button className="w-full flex items-center gap-4 px-4 py-3 text-gray-400 hover:text-white rounded-lg hover:bg-primary/10 transition-colors">
            <Bell className="w-5 h-5" />
            <span className="text-base font-medium">Notifications</span>
            <span className="ml-auto bg-primary text-white text-xs px-2 py-1 rounded-full">3</span>
          </button>

          <div className="flex items-center gap-3 px-4 py-3">
            <div className="w-10 h-10 rounded-full bg-gradient-to-r from-primary to-secondary flex items-center justify-center text-white">
              A
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-white font-medium truncate">Admin User</p>
              <p className="text-sm text-gray-400">Admin</p>
            </div>
          </div>

          <button
            onClick={handleLogout}
            className="w-full flex items-center gap-4 px-4 py-3 text-gray-400 hover:text-white rounded-lg hover:bg-primary/10 transition-colors"
          >
            <LogOut className="w-5 h-5" />
            <span className="text-base font-medium">Sign Out</span>
          </button>
        </div>
      </SidebarContent>
    </Sidebar>
  );
}
