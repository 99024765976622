
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Trophy, Eye } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { calculateStreamFinancials } from "@/utils/streamFinancials";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { StreamDetailsDialog } from "@/components/streams/history/StreamDetailsDialog";
import { Tables } from "@/integrations/supabase/types";
import { TimeRangeSelector, TimeRange } from "./TimeRangeSelector";
import { startOfMonth, subDays } from "date-fns";
import { useProfile } from "@/hooks/useProfile";

type Stream = Tables<"streams">;

interface StreamData {
  id: string;
  streamer: string;
  date: string;
  platform: string;
  sales: number;
  profit: number;
  raw_stream: Stream;
}

const StreamItem = ({ 
  stream,
  onViewDetails,
  showDetails = false
}: { 
  stream: StreamData;
  onViewDetails: (stream: Stream) => void;
  showDetails?: boolean;
}) => (
  <div className="p-4 bg-dark-muted rounded-lg">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-white font-medium">{stream.streamer}</p>
        <p className="text-sm text-gray-400">
          {stream.date}
        </p>
        <p className="text-sm text-gray-400">{stream.platform}</p>
      </div>
      <div className="flex items-center gap-4">
        <div className="text-right">
          <p className="text-white">Sales: ${stream.sales.toLocaleString()}</p>
          <p className={stream.profit >= 0 ? "text-green-500" : "text-red-500"}>
            Profit: ${Math.abs(stream.profit).toLocaleString()}
          </p>
        </div>
        {showDetails && (
          <Button
            variant="ghost"
            size="icon"
            onClick={(e) => {
              e.stopPropagation();
              onViewDetails(stream.raw_stream);
            }}
            className="text-gray-400 hover:text-white"
          >
            <Eye className="h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  </div>
);

export const TopPerformingStreams = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedStreamForDetails, setSelectedStreamForDetails] = useState<Stream | null>(null);
  const [timeRange, setTimeRange] = useState<TimeRange>('current_month');
  const queryClient = useQueryClient();

  useEffect(() => {
    const channel = supabase
      .channel('dashboard-stream-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'streams'
        },
        () => {
          console.log('Streams changed, invalidating query');
          queryClient.invalidateQueries({ queryKey: ['topPerformingStreams'] });
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'stream_items'
        },
        () => {
          console.log('Stream items changed, invalidating query');
          queryClient.invalidateQueries({ queryKey: ['topPerformingStreams'] });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [queryClient]);

  const { data: currentUser } = useProfile();

  const { data: settings } = useQuery({
    queryKey: ['userSettings', currentUser?.organization_id],
    enabled: !!currentUser?.organization_id && !!currentUser?.id,
    queryFn: async () => {
      try {
        if (!currentUser?.organization_id || !currentUser?.id) {
          throw new Error('Missing required IDs');
        }

        const { data: adminProfiles, error: adminError } = await supabase
          .from('profiles')
          .select('id')
          .eq('organization_id', currentUser.organization_id)
          .eq('role', 'admin');

        if (adminError) {
          console.error('Error fetching admin profiles:', adminError);
          throw adminError;
        }

        let adminSettings = null;
        if (adminProfiles && adminProfiles.length > 0) {
          for (const adminProfile of adminProfiles) {
            if (!adminProfile.id) continue;
            
            const { data: settings, error: settingsError } = await supabase
              .from('user_settings')
              .select('*')
              .eq('user_id', adminProfile.id)
              .eq('organization_id', currentUser.organization_id)
              .maybeSingle();
            
            if (settingsError) {
              console.error('Error fetching admin settings:', settingsError);
              continue;
            }
            
            if (settings) {
              adminSettings = settings;
              break;
            }
          }
        }

        if (!adminSettings) {
          const { data: userSettings, error: userSettingsError } = await supabase
            .from('user_settings')
            .select('*')
            .eq('user_id', currentUser.id)
            .eq('organization_id', currentUser.organization_id)
            .maybeSingle();

          if (userSettingsError) {
            console.error('Error fetching user settings:', userSettingsError);
          } else {
            adminSettings = userSettings;
          }
        }

        return adminSettings || {
          tiktok_fee: 0,
          fanatics_fee: 0,
          shipping_cost_type: 'disabled',
          shipping_cost_value: 0,
          streamer_pay_type: 'disabled',
          streamer_pay_value: 0,
          sorter_pay_type: 'disabled',
          sorter_pay_value: 0
        };
      } catch (error) {
        console.error('Error fetching settings:', error);
        return {
          tiktok_fee: 0,
          fanatics_fee: 0,
          shipping_cost_type: 'disabled',
          shipping_cost_value: 0,
          streamer_pay_type: 'disabled',
          streamer_pay_value: 0,
          sorter_pay_type: 'disabled',
          sorter_pay_value: 0
        };
      }
    },
  });

  const { data: streams = [] } = useQuery({
    queryKey: ['topPerformingStreams', settings, timeRange, currentUser?.organization_id, currentUser?.id, currentUser?.canViewAllStreams],
    enabled: !!settings && !!currentUser,
    queryFn: async () => {
      console.log('Fetching top performing streams for organization:', currentUser?.organization_id);
      
      let startDate;
      const now = new Date();
      
      switch (timeRange) {
        case 'current_month':
          startDate = startOfMonth(now);
          break;
        case 'last_7_days':
          startDate = subDays(now, 7);
          break;
        case 'last_30_days':
          startDate = subDays(now, 30);
          break;
        case 'all_time':
          startDate = new Date(0);
          break;
        default:
          startDate = startOfMonth(now);
      }

      let query = supabase
        .from('streams')
        .select(`
          *,
          stream_items (
            quantity_sold,
            sale_price,
            inventory_items (
              cost_basis
            )
          )
        `)
        .gte('start_time', startDate.toISOString())
        .order('start_time', { ascending: false });

      if (currentUser?.canViewAllStreams && currentUser?.organization_id) {
        query = query.eq('organization_id', currentUser.organization_id);
      } 
      else if (currentUser?.id) {
        query = query.eq('user_id', currentUser.id);
      }

      const { data: streamsData, error } = await query;

      if (error) {
        console.error('Error fetching streams:', error);
        throw error;
      }

      console.log('Raw streams data:', streamsData);

      if (!streamsData) return [];

      const streamsWithFinancials = streamsData.map(stream => {
        const totalSales = Number(stream.total_sales || 0);
        
        const productCosts = stream.stream_items?.reduce((sum, item) => {
          const costBasis = Number(item.inventory_items?.cost_basis || 0);
          return sum + (Number(item.quantity_sold || 0) * costBasis);
        }, 0) || 0;

        const financials = calculateStreamFinancials({
          totalSales,
          productCosts,
          platform: stream.platform,
          settings,
          streamDurationHours: stream.end_time ? 
            (new Date(stream.end_time).getTime() - new Date(stream.start_time).getTime()) / (1000 * 60 * 60) : 
            0
        });

        return {
          id: stream.id,
          streamer: stream.streamer,
          date: format(new Date(stream.start_time), "MMM d (yyyy)"),
          platform: stream.platform,
          sales: totalSales,
          profit: financials.netProfit,
          raw_stream: stream
        };
      });

      return streamsWithFinancials
        .sort((a, b) => b.profit - a.profit)
        .slice(0, 10);
    },
  });

  return (
    <>
      <Card 
        className="bg-dark-card border-dark-border cursor-pointer hover:bg-dark-card/90 transition-colors"
        onClick={() => setIsDialogOpen(true)}
      >
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle className="text-xl font-semibold text-white flex items-center gap-2">
            <Trophy className="h-5 w-5 text-yellow-500" />
            Top Performing Streams
          </CardTitle>
          <TimeRangeSelector
            selectedRange={timeRange}
            onRangeChange={setTimeRange}
          />
        </CardHeader>
        <CardContent className="space-y-4">
          {streams.slice(0, 3).map((stream) => (
            <StreamItem 
              key={stream.id} 
              stream={stream}
              onViewDetails={(stream) => setSelectedStreamForDetails(stream)}
              showDetails={false}
            />
          ))}
          {streams.length === 0 && (
            <div className="text-gray-400 text-center py-4">
              No streams found for the selected time period
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="bg-dark-card border-dark-border text-white max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold flex items-center gap-2">
              <Trophy className="h-5 w-5 text-yellow-500" />
              Top 10 Performing Streams
            </DialogTitle>
            <DialogDescription className="text-gray-400">
              View details of your best performing streaming sessions
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 mt-4">
            {streams.map((stream) => (
              <StreamItem 
                key={stream.id} 
                stream={stream}
                onViewDetails={(stream) => setSelectedStreamForDetails(stream)}
                showDetails={true}
              />
            ))}
            {streams.length === 0 && (
              <div className="text-gray-400 text-center py-4">
                No streams found for the selected time period
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <StreamDetailsDialog
        stream={selectedStreamForDetails}
        onClose={() => setSelectedStreamForDetails(null)}
      />
    </>
  );
};
