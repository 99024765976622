
import { useInventoryData } from "@/hooks/useInventoryData";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, PieChart, Pie, Cell, Legend 
} from "recharts";
import { InventoryValueDistribution } from "./charts/InventoryValueDistribution";
import { TopInventoryItemsTable } from "./tables/TopInventoryItemsTable";

export const InventoryAnalytics = () => {
  const { data: inventoryData, isLoading } = useInventoryData();

  // Calculate inventory by category
  const inventoryByCategory = inventoryData?.items.reduce((acc: Record<string, any>, item) => {
    const category = item.category || 'Uncategorized';
    
    if (!acc[category]) {
      acc[category] = {
        category,
        count: 0,
        value: 0
      };
    }
    
    acc[category].count += Number(item.quantity) || 0;
    acc[category].value += (Number(item.market_value || 0) * Number(item.quantity || 0));
    
    return acc;
  }, {});
  
  const categoryData = inventoryByCategory ? Object.values(inventoryByCategory) : [];

  // Colors for pie chart
  const COLORS = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658'];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card className="bg-dark-card border-dark-border">
          <CardHeader>
            <CardTitle className="text-lg font-medium text-gray-400">
              Inventory by Category
            </CardTitle>
          </CardHeader>
          <CardContent className="h-[400px]">
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={categoryData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                  <XAxis dataKey="category" stroke="#666" />
                  <YAxis stroke="#666" />
                  <Tooltip 
                    contentStyle={{ backgroundColor: '#222', border: '1px solid #444' }}
                    labelStyle={{ color: '#ddd' }}
                  />
                  <Bar dataKey="count" fill="#8884d8" name="Item Count" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>

        <InventoryValueDistribution />
      </div>

      <TopInventoryItemsTable />
    </div>
  );
};
