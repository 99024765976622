
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { TimeRangeSelector } from "@/components/dashboard/TimeRangeSelector";
import { useState } from "react";
import { TimeRange } from "@/components/dashboard/TimeRangeSelector";

export const AnalyticsHeader = () => {
  const navigate = useNavigate();
  const [timeRange, setTimeRange] = useState<TimeRange>("current_month");

  return (
    <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
      <div>
        <h1 className="text-2xl font-bold text-white">Analytics Dashboard</h1>
        <p className="text-gray-400 mt-1">
          Comprehensive analysis of your business data
        </p>
      </div>
      <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center">
        <TimeRangeSelector 
          selectedRange={timeRange} 
          onRangeChange={setTimeRange} 
        />
        <Button
          variant="outline"
          onClick={() => navigate("/streams")}
          className="border-dark-border text-white hover:text-white hover:bg-dark-muted"
        >
          View Streams
        </Button>
      </div>
    </div>
  );
};
