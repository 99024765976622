import React from 'react';  

const Loading: React.FC = () => {  
  return (  
    <div className="flex items-center justify-center min-h-screen bg-background">  
      <div className="flex flex-col items-center">  
        <svg  
          className="animate-spin h-8 w-8 text-foreground mb-4"  
          xmlns="http://www.w3.org/2000/svg"  
          fill="none"  
          viewBox="0 0 24 24"  
          stroke="currentColor"  
        >  
          <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4" />  
          <path  
            className="opacity-75"  
            fill="currentColor"  
            d="M4 12a8 8 0 018-8v8H4z"  
          />  
        </svg>  
        <div className="animate-pulse text-foreground text-lg font-semibold">  
          Loading...  
        </div>  
      </div>  
    </div>  
  );  
};  

export default Loading;  