import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

export const useCategory = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const manageCategory = async (categoryName: string, editingCategory: string | null = null) => {
    if (isSubmitting) {
      console.log('Preventing duplicate submission');
      return false;
    }

    if (!categoryName.trim()) {
      toast.error('Category name cannot be empty');
      return false;
    }

    setIsSubmitting(true);

    try {
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      
      if (authError || !user) {
        throw new Error('Authentication required');
      }

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('organization_id')
        .eq('id', user.id)
        .maybeSingle();

      if (profileError) {
        console.error('Profile fetch error:', profileError);
        throw new Error('Failed to load profile');
      }

      if (editingCategory) {
        const { error: updateError } = await supabase
          .from('inventory_items')
          .update({ category: categoryName })
          .eq('category', editingCategory)
          .eq('user_id', user.id);

        if (updateError) {
          console.error('Update error:', updateError);
          throw new Error('Failed to update category');
        }
      } else {
        const { error: insertError } = await supabase
          .from('inventory_items')
          .insert({
            user_id: user.id,
            organization_id: profile?.organization_id,
            category: categoryName,
            name: `${categoryName} (Category)`,
            status: 'category_marker',
            quantity: 0,
            cost_basis: 0
          });

        if (insertError) {
          console.error('Insert error:', insertError);
          throw new Error('Failed to create category');
        }
      }

      // Invalidate queries immediately
      await queryClient.invalidateQueries({ queryKey: ['inventory-items'] });
      await queryClient.invalidateQueries({ queryKey: ['dashboard-inventory'] });

      return true;
    } catch (error: any) {
      console.error('Category management error:', error);
      toast.error(error.message || 'Failed to manage category');
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    manageCategory,
    isSubmitting
  };
};