import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { UseFormReturn } from "react-hook-form";
import { SettingsFormValues, CostType } from "./types";

type CostTypeCardProps = {
  form: UseFormReturn<SettingsFormValues>;
  title: string;
  typeField: keyof SettingsFormValues;
  valueField: keyof SettingsFormValues;
};

export const CostTypeCard = ({ form, title, typeField, valueField }: CostTypeCardProps) => {
  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader>
        <CardTitle className="text-white">{title}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <FormField
          control={form.control}
          name={typeField}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-gray-200">{title} Type</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  value={field.value as CostType}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="disabled" className="border-gray-400" />
                    </FormControl>
                    <FormLabel className="font-normal text-gray-200">
                      Disabled
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="fixed" className="border-gray-400" />
                    </FormControl>
                    <FormLabel className="font-normal text-gray-200">
                      {typeField === 'sorter_pay_type' ? 'Hourly Rate' : 'Fixed Amount'}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="percentage" className="border-gray-400" />
                    </FormControl>
                    <FormLabel className="font-normal text-gray-200">
                      Percentage of Sale
                    </FormLabel>
                  </FormItem>
                  {typeField === 'streamer_pay_type' && (
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="profit_percentage" className="border-gray-400" />
                      </FormControl>
                      <FormLabel className="font-normal text-gray-200">
                        Percentage of Profit
                      </FormLabel>
                    </FormItem>
                  )}
                </RadioGroup>
              </FormControl>
              <FormMessage className="text-red-400" />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={valueField}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-gray-200">
                {title} {typeField === 'sorter_pay_type' && form.watch(typeField) === 'fixed' ? '($/hr)' : form.watch(typeField) === 'percentage' || form.watch(typeField) === 'profit_percentage' ? '(%)' : '($)'}
              </FormLabel>
              <FormControl>
                <Input type="number" step="0.01" {...field} className="bg-dark-muted text-white border-dark-border" />
              </FormControl>
              <FormMessage className="text-red-400" />
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
};