import { Skeleton } from "@/components/ui/skeleton";

export const InventorySkeleton = () => {
  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <Skeleton className="h-12 w-1/3" />
        <Skeleton className="h-8 w-1/4" />
        <div className="space-y-2">
          <Skeleton className="h-24 w-full" />
          <Skeleton className="h-24 w-full" />
          <Skeleton className="h-24 w-full" />
        </div>
      </div>
      <div className="flex justify-center">
        <p className="text-gray-400">Loading inventory items...</p>
      </div>
    </div>
  );
};