import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Package } from "lucide-react";
import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { ProductItem } from "./products/ProductItem";
import { ProductsDialog } from "./products/ProductsDialog";
import { useProductSales } from "@/hooks/useProductSales";
import { TimeRangeSelector, TimeRange } from "./TimeRangeSelector";

export const BestSellingProducts = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sortByValue, setSortByValue] = useState(false);
  const [timeRange, setTimeRange] = useState<TimeRange>('current_month');
  const queryClient = useQueryClient();

  // Set up real-time subscription
  useEffect(() => {
    console.log('Setting up real-time subscription for dashboard changes');
    const channel = supabase
      .channel('dashboard-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'stream_items'
        },
        () => {
          console.log('Stream items changed, invalidating query');
          queryClient.invalidateQueries({ queryKey: ['bestSellingProducts'] });
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'inventory_items'
        },
        () => {
          console.log('Inventory items changed, invalidating query');
          queryClient.invalidateQueries({ queryKey: ['bestSellingProducts'] });
        }
      )
      .subscribe();

    return () => {
      console.log('Cleaning up real-time subscription');
      supabase.removeChannel(channel);
    };
  }, [queryClient]);

  const { data: products = [], isLoading } = useProductSales(sortByValue, timeRange);

  if (isLoading) {
    return (
      <Card className="bg-dark-card border-dark-border">
        <CardHeader>
          <CardTitle className="text-xl font-semibold text-white flex items-center gap-2">
            <Package className="h-5 w-5" />
            Loading...
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-700 rounded w-3/4" />
            <div className="h-4 bg-gray-700 rounded w-1/2" />
            <div className="h-4 bg-gray-700 rounded w-2/3" />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card 
        className="bg-dark-card border-dark-border cursor-pointer hover:bg-dark-card/90 transition-colors"
        onClick={() => setIsDialogOpen(true)}
      >
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle className="text-xl font-semibold text-white flex items-center gap-2">
            <Package className="h-5 w-5" />
            Best Selling Products
            <span className="text-sm font-normal text-gray-400">
              (by {sortByValue ? 'total value' : 'quantity'})
            </span>
          </CardTitle>
          <TimeRangeSelector
            selectedRange={timeRange}
            onRangeChange={setTimeRange}
          />
        </CardHeader>
        <CardContent className="space-y-6">
          {products.slice(0, 3).map((product, index) => (
            <ProductItem key={product.name} product={product} rank={index + 1} />
          ))}
          {products.length === 0 && (
            <p className="text-gray-400">No products data available</p>
          )}
        </CardContent>
      </Card>

      <ProductsDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        products={products}
        sortByValue={sortByValue}
        setSortByValue={setSortByValue}
      />
    </>
  );
};