
import { useForm } from "react-hook-form";
import { SettingsFormValues, CostType } from "@/components/settings/types";
import { SettingsTabs } from "@/components/settings/SettingsTabs";
import { AppLayout } from "@/components/layout/AppLayout";
import { useProfile } from "@/hooks/useProfile";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Save, AlertTriangle } from "lucide-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { useNavigate } from "react-router-dom";

const defaultSettings: SettingsFormValues = {
  shipping_cost_type: "disabled",
  shipping_cost_value: 0,
  streamer_pay_type: "disabled",
  streamer_pay_value: 0,
  sorter_pay_type: "disabled",
  sorter_pay_value: 0,
};

const Settings = () => {
  const { data: profile, isLoading: profileLoading } = useProfile();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: settings, isLoading: settingsLoading } = useQuery({
    queryKey: ['user-settings', profile?.id],
    queryFn: async () => {
      if (!profile?.id) return null;

      // First try to get existing settings
      const { data: existingSettings, error: fetchError } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', profile.id)
        .maybeSingle();

      if (fetchError) {
        console.error('Error fetching settings:', fetchError);
        throw fetchError;
      }

      // If no settings exist, create them
      if (!existingSettings) {
        const { data: newSettings, error: createError } = await supabase
          .from('user_settings')
          .insert([{
            user_id: profile.id,
            organization_id: profile.organization_id,
            ...defaultSettings
          }])
          .select()
          .single();

        if (createError) {
          console.error('Error creating settings:', createError);
          throw createError;
        }

        return newSettings;
      }

      return existingSettings;
    },
    enabled: !!profile?.id
  });

  const form = useForm<SettingsFormValues>({
    defaultValues: defaultSettings
  });

  // Update form values when settings are loaded
  useEffect(() => {
    if (settings) {
      form.reset({
        shipping_cost_type: (settings.shipping_cost_type as CostType) || "disabled",
        shipping_cost_value: settings.shipping_cost_value || 0,
        streamer_pay_type: (settings.streamer_pay_type as CostType) || "disabled",
        streamer_pay_value: settings.streamer_pay_value || 0,
        sorter_pay_type: (settings.sorter_pay_type as CostType) || "disabled",
        sorter_pay_value: settings.sorter_pay_value || 0,
      });
    }
  }, [settings, form]);

  const onSubmit = async (data: SettingsFormValues) => {
    try {
      console.log('Saving settings:', data);
      const { error } = await supabase
        .from('user_settings')
        .upsert({
          user_id: profile?.id,
          organization_id: profile?.organization_id,
          ...data
        }, {
          onConflict: 'user_id'
        });

      if (error) throw error;

      // Invalidate the settings query to trigger a refetch
      await queryClient.invalidateQueries({ queryKey: ['user-settings', profile?.id] });
      
      toast.success("Settings saved successfully");
    } catch (error) {
      console.error('Error saving settings:', error);
      toast.error("Failed to save settings");
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const { error } = await supabase.functions.invoke('delete-user', {
        body: { userId: profile?.id }
      });

      if (error) throw error;

      // Sign out after successful deletion
      await supabase.auth.signOut();
      navigate('/');
      toast.success('Account deleted successfully');
    } catch (error: any) {
      console.error('Error deleting account:', error);
      toast.error(error.message || 'Failed to delete account');
    }
  };

  if (profileLoading || settingsLoading) {
    return (
      <AppLayout>
        <div className="container mx-auto py-6">
          <div>Loading...</div>
        </div>
      </AppLayout>
    );
  }

  if (!profile) {
    return (
      <AppLayout>
        <div className="container mx-auto py-6">
          <div>Please log in to access settings</div>
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <div className="container mx-auto py-6">
        <div className="flex flex-col gap-8">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-3xl font-bold tracking-tight">Settings</h2>
              <p className="text-muted-foreground">
                Manage your platform settings
              </p>
            </div>
            <div className="flex gap-4">
              <Button
                onClick={form.handleSubmit(onSubmit)}
                className="gap-2"
              >
                <Save className="h-4 w-4" />
                Save Changes
              </Button>

              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="destructive" className="gap-2">
                    <AlertTriangle className="h-4 w-4" />
                    Delete Account
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will permanently delete your account
                      {profile.role === 'admin' && ' and your organization'}, and remove all associated data from our servers.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={handleDeleteAccount}
                      className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                    >
                      Delete Account
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </div>

          <SettingsTabs form={form} />
        </div>
      </div>
    </AppLayout>
  );
};

export default Settings;
