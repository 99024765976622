
import { useEffect, useState, useRef } from "react";
import { Navigate, useLocation, Routes, Route } from "react-router-dom";
import { Toaster } from "sonner";
import { supabase } from "./integrations/supabase/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { toast } from "sonner";
import { AuthError } from "@supabase/supabase-js";
// Pages
import Loading from "./components/ui/loading";
import Login from "./pages/Login";
import Inventory from "./pages/Inventory";
import Streams from "./pages/Streams";
import Wholesale from "./pages/Wholesale";
import Settings from "./pages/Settings";
import InventoryCheck from "./pages/InventoryCheck";
import Index from "./pages/Index";
import Analytics from "./pages/Analytics";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 30000, // 30 seconds
      gcTime: 3600000, // 1 hour garbage collection
      refetchInterval: false // Disable automatic refetching
    },
  },
});

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const subscriptionRef = useRef<{ unsubscribe: () => void } | null>(null);

  useEffect(() => {
    let mounted = true;

    const initializeAuth = async () => {
      try {
        // Clear any potentially invalid session data
        const currentSession = localStorage.getItem('cardquant-auth');
        if (currentSession) {
          try {
            JSON.parse(currentSession);
          } catch (e) {
            console.log('Invalid session data found, clearing...');
            localStorage.removeItem('cardquant-auth');
            await supabase.auth.signOut();
          }
        }

        // Get fresh session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) {
          console.error('Session check error:', sessionError);
          await supabase.auth.signOut();
          if (mounted) {
            setIsAuthenticated(false);
            setIsLoading(false);
          }
          return;
        }

        if (!session?.user?.id) {
          console.log('No valid session found');
          await supabase.auth.signOut();
          if (mounted) {
            setIsAuthenticated(false);
            setIsLoading(false);
          }
          return;
        }

        // Verify the user's profile exists
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', session.user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Profile verification error:', profileError);
          if (profileError.message.includes('violates row-level security policy')) {
            const { error: createError } = await supabase
              .from('profiles')
              .insert([
                {
                  id: session.user.id,
                  username: session.user.email,
                  first_name: session.user.user_metadata?.first_name || 'New',
                  last_name: session.user.user_metadata?.last_name || 'User',
                  role: 'user'
                }
              ]);

            if (createError) {
              console.error('Failed to create profile:', createError);
              await supabase.auth.signOut();
              if (mounted) {
                setIsAuthenticated(false);
                setIsLoading(false);
              }
              return;
            }
          } else {
            await supabase.auth.signOut();
            if (mounted) {
              setIsAuthenticated(false);
              setIsLoading(false);
            }
            return;
          }
        }

        if (mounted) {
          setIsAuthenticated(true);
          setIsLoading(false);
          queryClient.invalidateQueries();
        }

        // Listen for auth changes
        const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
          if (!mounted) return;

          console.log('Auth state changed:', event, 'Session exists:', !!session);

          if (event === 'SIGNED_OUT') {
            setIsAuthenticated(false);
            queryClient.clear();
            localStorage.removeItem('cardquant-auth');
          } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
            if (session?.user?.id) {
              const { data: profile, error: profileError } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', session.user.id)
                .maybeSingle();

              if (profile && !profileError) {
                setIsAuthenticated(true);
                queryClient.invalidateQueries();
              } else {
                console.error('Profile verification failed:', profileError);
                await supabase.auth.signOut();
                setIsAuthenticated(false);
              }
            }
          }
        });

        subscriptionRef.current = subscription;
      } catch (error) {
        console.error('Auth initialization error:', error instanceof AuthError ? error.message : error);
        await supabase.auth.signOut();
        localStorage.removeItem('cardquant-auth');
        if (mounted) {
          setIsAuthenticated(false);
          setIsLoading(false);
          toast.error("Authentication failed. Please try logging in again.");
        }
      }
    };

    initializeAuth();

    return () => {
      mounted = false;
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-background">
        <div className="animate-pulse text-foreground"><Loading></Loading></div>
      </div>
    );
  }

  if (!isAuthenticated && location.pathname !== '/login') {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && location.pathname === '/login') {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="min-h-screen bg-background text-foreground w-full">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/inventory" element={<ProtectedRoute><Inventory /></ProtectedRoute>} />
          <Route path="/streams" element={<ProtectedRoute><Streams /></ProtectedRoute>} />
          <Route path="/wholesale" element={<ProtectedRoute><Wholesale /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/inventory-check" element={<ProtectedRoute><InventoryCheck /></ProtectedRoute>} />
          <Route path="/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
          <Route path="*" element={<ProtectedRoute><Index /></ProtectedRoute>} />
        </Routes>
        <Toaster position="top-right" />
      </div>
    </QueryClientProvider>
  );
};

export default App;
