
import { useProfile, ProfileWithOrganization } from './useProfile';

/**
 * @deprecated Use useProfile() instead
 */
export { type ProfileWithOrganization } from './useProfile';

/**
 * @deprecated Use useProfile() instead
 */
export const useProfileWithOrganization = () => {
  return useProfile({ withToast: true });
};
