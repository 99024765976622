import { Tables } from "@/integrations/supabase/types";
import { format } from "date-fns";
import { useState } from "react";
import { StreamSummaryCard } from "./history/StreamSummaryCard";
import { StreamDetailsDialog } from "./history/StreamDetailsDialog";
import { StreamMonthGroup } from "./history/StreamMonthGroup";

type Stream = Tables<"streams">;

interface StreamHistoryProps {
  streams?: Stream[];
  isLoading: boolean;
}

export const StreamHistory = ({ streams = [], isLoading }: StreamHistoryProps) => {
  const [selectedStream, setSelectedStream] = useState<Stream | null>(null);

  if (isLoading) {
    return <div className="text-gray-400">Loading streams...</div>;
  }

  if (!streams?.length) {
    return <div className="text-gray-400">No streams found.</div>;
  }

  // Group streams by month
  const groupedStreams = streams.reduce((acc, stream) => {
    const monthYear = format(new Date(stream.start_time), 'MMMM yyyy');
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(stream);
    return acc;
  }, {} as Record<string, Stream[]>);

  return (
    <div className="space-y-8">
      <StreamSummaryCard streams={streams} />

      {Object.entries(groupedStreams).map(([monthYear, monthStreams]) => (
        <StreamMonthGroup
          key={monthYear}
          monthYear={monthYear}
          streams={monthStreams}
          onStreamSelect={setSelectedStream}
        />
      ))}

      <StreamDetailsDialog
        stream={selectedStream}
        onClose={() => setSelectedStream(null)}
      />
    </div>
  );
};