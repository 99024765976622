
import { useMemo } from "react";
import { InventoryItem } from "./useInventoryData";

export const useInventoryFilters = (
  items: InventoryItem[] | undefined,
  searchTerm: string,
  selectedCategory: string,
  status: 'active' | 'archived' = 'active'
) => {
  return useMemo(() => {
    if (!items || items.length === 0) return {};

    // Convert searchTerm to lowercase once for efficiency
    const lowercaseSearch = searchTerm.toLowerCase();
    
    // First, separate category markers from regular items
    const categoryMarkers = items.filter(item => item.status === 'category_marker');
    const regularItems = items.filter(item => item.status !== 'category_marker');

    // Ensure all items have complete data by filling in defaults where needed
    const normalizedItems = regularItems.map(item => ({
      ...item,
      category: item.category || 'Uncategorized',
      quantity: typeof item.quantity === 'number' ? item.quantity : 0,
      cost_basis: typeof item.cost_basis === 'number' ? item.cost_basis : 0,
      market_value: typeof item.market_value === 'number' ? item.market_value : 0,
      status: item.status || 'active'
    }));

    // Filter regular items based on search term, selected category, and status
    const filteredRegularItems = normalizedItems.filter(item => {
      // Search term filtering - only do toLowerCase() once per item
      const itemName = (item.name || '').toLowerCase();
      const matchesSearch = lowercaseSearch === "" || itemName.includes(lowercaseSearch);
      
      // Category filtering
      const itemCategory = item.category || 'Uncategorized';
      const matchesCategory = selectedCategory === "all" || itemCategory === selectedCategory;
      
      // Status filtering
      const itemStatus = item.status || 'active';
      // Use string comparison for status check
      const matchesStatus = status === itemStatus;

      return matchesSearch && matchesCategory && matchesStatus;
    });

    // Get all categories efficiently
    const categorySet = new Set<string>();
    
    // Add categories from markers
    categoryMarkers.forEach(item => {
      if (item.category) categorySet.add(item.category);
    });
    
    // Always include Uncategorized if there are items with no category
    const hasUncategorizedItems = normalizedItems.some(item => !item.category || item.category === 'Uncategorized');
    if (hasUncategorizedItems) {
      categorySet.add('Uncategorized');
    }
    
    // Add categories from regular items (only if showing all or filtering is needed)
    if (selectedCategory === "all") {
      normalizedItems.forEach(item => {
        const category = item.category || 'Uncategorized';
        categorySet.add(category);
      });
    } else {
      categorySet.add(selectedCategory);
    }
    
    const categories = Array.from(categorySet);

    // Group items by category
    const groupedItems: Record<string, InventoryItem[]> = {};
    
    categories.forEach(category => {
      const categoryMarker = categoryMarkers.find(item => 
        (item.category || 'Uncategorized') === category
      );
      
      const itemsInCategory = filteredRegularItems.filter(item => 
        (item.category || 'Uncategorized') === category
      );
      
      if (categoryMarker) {
        groupedItems[category] = [categoryMarker, ...itemsInCategory];
      } else if (itemsInCategory.length > 0) {
        groupedItems[category] = itemsInCategory;
      }
    });
    
    return groupedItems;
  }, [items, searchTerm, selectedCategory, status]);
};
