import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useProfileWithOrganization } from "@/hooks/useProfileWithOrganization";
import { toast } from "sonner";
import { startOfMonth, subDays } from "date-fns";
import { TimeRange } from "@/components/dashboard/TimeRangeSelector";

interface InventoryItem {
  id: string;
  name: string;
}

interface StreamItem {
  inventory_item_id: string;
  quantity_sold: number;
  sale_price: number;
  inventory_items: InventoryItem;
}

interface Stream {
  id: string;
  stream_items: StreamItem[];
}

export const useProductSales = (sortByValue: boolean, timeRange: TimeRange = 'current_month') => {
  const { data: profile } = useProfileWithOrganization();

  return useQuery({
    queryKey: ['bestSellingProducts', profile?.organization_id, sortByValue, timeRange],
    enabled: !!profile?.organization_id,
    queryFn: async () => {
      try {
        console.log('Starting best selling products query');
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError) {
          console.error('Auth error:', authError);
          throw new Error('Authentication error');
        }
        
        if (!user) {
          console.error('No authenticated user found');
          throw new Error('Not authenticated');
        }

        console.log('Authenticated user:', user.id);

        // Calculate the date range
        let startDate;
        const now = new Date();
        
        switch (timeRange) {
          case 'current_month':
            startDate = startOfMonth(now);
            break;
          case 'last_7_days':
            startDate = subDays(now, 7);
            break;
          case 'last_30_days':
            startDate = subDays(now, 30);
            break;
          case 'all_time':
            startDate = new Date(0); // Beginning of time
            break;
          default:
            startDate = startOfMonth(now);
        }

        // Query will be filtered by RLS policies based on user role
        const { data: streams, error: streamsError } = await supabase
          .from('streams')
          .select(`
            id,
            stream_items (
              inventory_item_id,
              quantity_sold,
              sale_price,
              inventory_items (
                id,
                name
              )
            )
          `)
          .gte('start_time', startDate.toISOString()) as { data: Stream[], error: any };

        if (streamsError) {
          console.error('Error fetching streams:', streamsError);
          throw streamsError;
        }

        console.log('Fetched streams:', streams);

        // Aggregate sales by product
        const productSales = (streams || []).reduce((acc, stream) => {
          stream.stream_items?.forEach(item => {
            if (!item.inventory_items?.name) return;

            const productName = item.inventory_items.name;
            if (!acc[productName]) {
              acc[productName] = {
                name: productName,
                totalQuantity: 0,
                totalValue: 0,
                totalSales: 0,
              };
            }

            const quantity = Number(item.quantity_sold || 0);
            const price = Number(item.sale_price || 0);
            acc[productName].totalQuantity += quantity;
            acc[productName].totalValue += price * quantity;
            acc[productName].totalSales += 1;
          });
          return acc;
        }, {} as Record<string, any>);

        // Convert to array and sort based on selected criteria
        const sortedProducts = Object.values(productSales)
          .map((data: any) => ({
            name: data.name,
            unitsSold: data.totalQuantity,
            avgPrice: data.totalValue / data.totalQuantity || 0,
            totalValue: data.totalValue,
            progress: 0, // Will be calculated below
          }))
          .sort((a, b) => sortByValue ? 
            b.totalValue - a.totalValue : 
            b.unitsSold - a.unitsSold
          )
          .slice(0, 10);

        // Calculate progress percentages
        const maxValue = sortByValue ? 
          Math.max(...sortedProducts.map(p => p.totalValue)) : 
          Math.max(...sortedProducts.map(p => p.unitsSold));

        return sortedProducts.map(product => ({
          ...product,
          progress: sortByValue ? 
            (product.totalValue / maxValue) * 100 : 
            (product.unitsSold / maxValue) * 100
        }));
      } catch (error: any) {
        console.error('Error in bestSellingProducts query:', error);
        toast.error('Failed to load best selling products');
        throw error;
      }
    },
  });
};