
import { useInventoryData } from "@/hooks/useInventoryData";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";

export const InventoryValueDistribution = () => {
  const { data: inventoryData, isLoading } = useInventoryData();

  // Calculate value distribution by category
  const valueByCategory = inventoryData?.items.reduce((acc: Record<string, any>, item) => {
    const category = item.category || 'Uncategorized';
    
    if (!acc[category]) {
      acc[category] = {
        name: category,
        value: 0
      };
    }
    
    acc[category].value += (Number(item.market_value || 0) * Number(item.quantity || 0));
    
    return acc;
  }, {});
  
  const categoryValueData = valueByCategory ? Object.values(valueByCategory) : [];

  // Colors for pie chart
  const COLORS = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658'];

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader>
        <CardTitle className="text-lg font-medium text-gray-400">
          Inventory Value Distribution
        </CardTitle>
      </CardHeader>
      <CardContent className="h-[400px]">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={categoryValueData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                nameKey="name"
                label={(entry) => entry.name}
              >
                {categoryValueData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value) => [`$${value.toLocaleString()}`, 'Value']}
                contentStyle={{ backgroundColor: '#222', border: '1px solid #444' }}
                labelStyle={{ color: '#ddd' }}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};
