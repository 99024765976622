import { Card } from "@/components/ui/card";
import { InventoryCheckItem } from "./InventoryCheckItem";
import { InventoryItem } from "@/hooks/useInventoryData";
import { useState } from "react";
import { ChevronDown, ChevronRight, CheckSquare } from "lucide-react";
import { Button } from "@/components/ui/button";

interface InventoryCheckCategoryProps {
  category: string;
  items: InventoryItem[];
  onItemCheck: (itemId: string, isCorrect: boolean, actualQuantity?: number) => void;
}

export const InventoryCheckCategory = ({ 
  category, 
  items,
  onItemCheck
}: InventoryCheckCategoryProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [lastBulkUpdate, setLastBulkUpdate] = useState(0);

  // Filter out category markers and only show actual products
  const productItems = items.filter(item => item.status !== 'category_marker');

  const handleSelectAllCorrect = () => {
    productItems.forEach(item => {
      onItemCheck(item.id, true);
    });
    setLastBulkUpdate(Date.now());
  };

  // Don't render empty categories
  if (productItems.length === 0) {
    return null;
  }

  return (
    <Card className="bg-dark-card border-dark-border p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-white hover:text-white"
          >
            {isExpanded ? (
              <ChevronDown className="h-4 w-4" />
            ) : (
              <ChevronRight className="h-4 w-4" />
            )}
          </Button>
          <h2 className="text-lg font-semibold text-white">{category}</h2>
          <span className="text-sm text-gray-400">({productItems.length} items)</span>
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={handleSelectAllCorrect}
          className="text-black bg-white hover:bg-gray-100"
        >
          <CheckSquare className="h-4 w-4 mr-2" />
          Mark All Correct
        </Button>
      </div>

      {isExpanded && (
        <div className="space-y-4">
          {productItems.map((item) => (
            <InventoryCheckItem 
              key={item.id} 
              item={item}
              onCheckComplete={onItemCheck}
              bulkUpdateTimestamp={lastBulkUpdate}
            />
          ))}
        </div>
      )}
    </Card>
  );
};