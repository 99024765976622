import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Settings2 } from "lucide-react";
import { useState } from "react";

interface BulkActionsMenuProps {
  selectedItems: string[];
  categories: string[];
  onUpdateCategory: (category: string) => void;
  onClose: () => void;
}

export const BulkActionsMenu = ({
  selectedItems,
  categories,
  onUpdateCategory,
  onClose,
}: BulkActionsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  if (selectedItems.length === 0) return null;

  const handleCategoryChange = async (category: string) => {
    setIsUpdating(true);
    try {
      await onUpdateCategory(category);
      setIsOpen(false);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="mb-4">
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button 
            variant="outline" 
            className="bg-dark-card text-white hover:bg-dark-muted"
            disabled={isUpdating}
          >
            <Settings2 className="w-4 h-4 mr-2" />
            {isUpdating ? 'Updating...' : `Actions (${selectedItems.length} items)`}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 bg-dark-card border-dark-border p-4">
          <div className="space-y-4">
            <h4 className="font-medium text-white">Change Category</h4>
            <Select 
              onValueChange={handleCategoryChange}
              disabled={isUpdating}
            >
              <SelectTrigger className="w-full bg-dark-card border-dark-border text-white">
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent className="bg-dark-card border-dark-border">
                {categories.map((category) => (
                  <SelectItem 
                    key={category} 
                    value={category} 
                    className="text-white hover:bg-dark-muted"
                  >
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                if (!isUpdating) {
                  onClose();
                  setIsOpen(false);
                }
              }}
              disabled={isUpdating}
              className="w-full text-white hover:bg-dark-muted"
            >
              Cancel
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};