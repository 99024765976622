import { useMonthlyPerformance } from "@/hooks/useMonthlyPerformance";
import { MonthlyPerformanceCard } from "./MonthlyPerformanceCard";

export const MonthlyPerformance = () => {
  const { monthlyPerformance, isLoading } = useMonthlyPerformance();

  return (
    <MonthlyPerformanceCard
      totalSales={monthlyPerformance?.totalSales || 0}
      totalProfit={monthlyPerformance?.netProfit || 0}
      isLoading={isLoading}
    />
  );
};