import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Building, Plus } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface CreateOrganizationDialogProps {
  onSuccess: () => Promise<void>;
}

export const CreateOrganizationDialog = ({ onSuccess }: CreateOrganizationDialogProps) => {
  const [isCreatingOrg, setIsCreatingOrg] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCreateOrganization = async () => {
    if (!newOrgName.trim()) {
      toast.error('Please enter an organization name');
      return;
    }

    setIsCreatingOrg(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      console.log('Creating organization for user:', user.id);

      // First create the organization
      const { data: org, error: orgError } = await supabase
        .from('organizations')
        .insert([
          { name: newOrgName.trim(), owner_id: user.id }
        ])
        .select()
        .single();

      if (orgError) {
        console.error('Error creating organization:', orgError);
        throw orgError;
      }

      console.log('Organization created:', org);

      // Then update the profile with the new organization ID and admin role
      const { error: profileError } = await supabase
        .from('profiles')
        .update({ 
          organization_id: org.id,
          role: 'admin'
        })
        .eq('id', user.id);

      if (profileError) {
        console.error('Error updating profile:', profileError);
        throw profileError;
      }

      // Create default user settings for the organization
      const { error: settingsError } = await supabase
        .from('user_settings')
        .insert([{
          user_id: user.id,
          organization_id: org.id
        }]);

      if (settingsError) {
        console.error('Error creating user settings:', settingsError);
        throw settingsError;
      }

      await onSuccess();
      toast.success('Organization created successfully');
      setIsDialogOpen(false);
      setNewOrgName('');
    } catch (error) {
      console.error('Error in organization creation flow:', error);
      toast.error('Failed to create organization');
    } finally {
      setIsCreatingOrg(false);
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button>
          <Building className="w-4 h-4 mr-2" />
          Create Organization
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Organization</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 mt-4">
          <Input
            placeholder="Organization Name"
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
          />
          <Button 
            onClick={handleCreateOrganization}
            disabled={isCreatingOrg}
            className="w-full"
          >
            <Plus className="w-4 h-4 mr-2" />
            {isCreatingOrg ? 'Creating...' : 'Create Organization'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};