import { AppLayout } from "@/components/layout/AppLayout";
import { DashboardMetrics } from "@/components/dashboard/DashboardMetrics";
import { MonthlyPerformance } from "@/components/dashboard/MonthlyPerformance";
import { BestSellingProducts } from "@/components/dashboard/BestSellingProducts";
import { RecentStreams } from "@/components/dashboard/RecentStreams";
import { TopPerformingStreams } from "@/components/dashboard/TopPerformingStreams";
import { useProfile } from "@/hooks/useProfile";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";

const Index = () => {
  const { data: profile, isLoading, error } = useProfile();

  const getWelcomeMessage = () => {
    if (isLoading) return 'Loading...';
    if (!profile) return 'Welcome';

    const name = profile.first_name && profile.last_name
      ? `${profile.first_name} ${profile.last_name}`
      : profile.username || 'Admin';
    const role = profile.role ? ` (${profile.role})` : '';
    return `Welcome back, ${name}${role}`;
  };
  return (
    <AppLayout>
      <div className="space-y-8 animate-fade-in w-full">
        <div className="max-w-full mx-auto">
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                There was a problem loading your profile. Please try refreshing the page.
              </AlertDescription>
            </Alert>
          )}

          <h1 className="text-4xl font-bold text-white mb-2">
            {getWelcomeMessage()}
          </h1>
          <p className="text-gray-400 mb-8">
            Here's an overview of your inventory management system
            {profile?.organization_name && ` for ${profile.organization_name}`}
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
            <div className="lg:col-span-2">
              <DashboardMetrics />
            </div>
            <div>
              <MonthlyPerformance />
            </div>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 mb-8">
            <BestSellingProducts />
            <TopPerformingStreams />
          </div>

          <div className="max-w-full">
            <RecentStreams />
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Index;