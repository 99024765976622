import React from 'react';

export interface ProductData {
  name: string;
  avgPrice: number;
  unitsSold: number;
  totalValue: number;
  progress: number;
}

interface ProductItemProps {
  product: ProductData;
  rank: number;
}

export const ProductItem = ({ product, rank }: ProductItemProps) => (
  <div className="space-y-2">
    <div className="flex justify-between items-start">
      <div>
        <div className="text-white">
          #{rank} {product.name}
        </div>
        <div className="text-sm text-gray-400">
          {product.unitsSold} units sold
        </div>
      </div>
      <div className="text-right text-sm text-gray-400">
        Cost per: ${product.avgPrice.toFixed(2)} · Total: ${product.totalValue.toFixed(2)}
      </div>
    </div>
    <div className="h-2 bg-gray-700 rounded-full">
      <div 
        className="h-2 bg-primary rounded-full" 
        style={{ width: `${product.progress}%` }} 
      />
    </div>
  </div>
);