import { Button } from "@/components/ui/button";
import { Archive, Upload, Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useState } from "react";
import { AddItemDialog } from "./AddItemDialog";
import { AwaitingItemsDialog } from "./AwaitingItemsDialog";
import { useQuery } from "@tanstack/react-query";

interface InventoryHeaderProps {
  totalValue: number;
  onStatusChange: (status: 'active' | 'archived') => void;
  currentStatus: 'active' | 'archived';
}

export const InventoryHeader = ({ totalValue, onStatusChange, currentStatus }: InventoryHeaderProps) => {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [showAwaitingDialog, setShowAwaitingDialog] = useState(false);

  const { data: archivedCount } = useQuery({
    queryKey: ['archived-count'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return 0;

      const { count } = await supabase
        .from('inventory_items')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', user.id)
        .eq('status', 'archived');

      return count || 0;
    },
  });

  // Query to get count and total value of awaiting items
  const { data: awaitingData } = useQuery({
    queryKey: ['awaiting-data'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return { count: 0, totalValue: 0 };

      const { data } = await supabase
        .from('inventory_items')
        .select('quantity, cost_basis')
        .eq('user_id', user.id)
        .eq('status', 'awaiting');

      const count = data?.length || 0;
      const totalValue = data?.reduce((sum, item) => {
        return sum + (Number(item.quantity || 0) * Number(item.cost_basis || 0));
      }, 0) || 0;

      return { count, totalValue };
    },
  });

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      toast.error('Please upload a CSV file');
      return;
    }

    setIsUploading(true);
    const loadingToast = toast.loading('Uploading inventory...');

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      // Read file content
      const fileContent = await file.text();
      
      // Use Supabase's function invocation method
      const { data, error } = await supabase.functions.invoke('process-inventory-csv', {
        body: {
          csvContent: fileContent,
          userId: user.id
        }
      });

      if (error) throw error;

      toast.dismiss(loadingToast);
      toast.success(`Successfully imported ${data.itemsProcessed} items`);

      // Refresh the page to show new items
      window.location.reload();
    } catch (error) {
      console.error('Upload error:', error);
      toast.dismiss(loadingToast);
      toast.error('Failed to upload inventory: ' + (error.message || 'Unknown error'));
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div>
          <h1 className="text-2xl font-bold text-white mb-2 md:mb-0">Inventory Management</h1>
        </div>
        <div className="flex flex-wrap gap-3">
          <Button 
            variant="outline" 
            className="bg-dark-card text-white hover:bg-dark-muted"
            onClick={() => navigate('/')}
          >
            Back to Dashboard
          </Button>
          <Button 
            variant="outline" 
            className={`bg-dark-card text-white hover:bg-dark-muted ${currentStatus === 'archived' ? 'bg-dark-muted' : ''}`}
            onClick={() => onStatusChange(currentStatus === 'active' ? 'archived' : 'active')}
          >
            <Archive className="w-4 h-4 mr-2" />
            {currentStatus === 'active' ? `Archive (${archivedCount || 0})` : 'Active Items'}
          </Button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
        <div className="flex flex-wrap gap-2">
          <AddItemDialog />
          <div className="relative">
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              disabled={isUploading}
            />
            <Button 
              variant="outline" 
              className="bg-dark-card text-white hover:bg-dark-muted whitespace-nowrap"
              disabled={isUploading}
            >
              <Upload className="w-4 h-4 mr-2" />
              {isUploading ? 'Uploading...' : 'Upload Inventory'}
            </Button>
          </div>
          <Button 
            variant="outline" 
            className="bg-dark-card text-white hover:bg-dark-muted whitespace-nowrap"
            onClick={() => setShowAwaitingDialog(true)}
          >
            Awaiting ({awaitingData?.count || 0})
          </Button>
        </div>
      </div>

      <div className="bg-dark-card border border-dark-border rounded-lg p-6">
        <h2 className="text-xl font-bold text-white mb-4">
          {currentStatus === 'active' ? 'Current Inventory' : 'Archived Items'}
        </h2>
        <div className="flex flex-col md:flex-row items-start md:items-baseline gap-4">
          <div className="flex items-baseline gap-2">
            <h3 className="text-lg text-gray-400">Total Value:</h3>
            <span className="text-3xl font-bold text-white">
              ${totalValue.toLocaleString()}
            </span>
          </div>
          {currentStatus === 'active' && (
            <span className="text-lg text-gray-400">
              Awaiting: ${(awaitingData?.totalValue || 0).toLocaleString()}
            </span>
          )}
        </div>
      </div>
      
      <AwaitingItemsDialog 
        open={showAwaitingDialog} 
        onOpenChange={setShowAwaitingDialog}
      />
    </div>
  );
};
