
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useId } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";

export const AuthForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const id = useId();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log('Starting authentication process...');
      
      // First attempt to sign in
      const { data, error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) {
        console.error('Login error:', signInError);
        throw signInError;
      }

      // If we have a valid session, proceed with navigation
      if (data?.session) {
        console.log('Login successful, session established with user ID:', data.session.user.id);
        
        // Try a simple profile query first
        console.log('Attempting basic profile fetch...');
        const { data: basicProfile, error: basicProfileError } = await supabase
          .from('profiles')
          .select('id')
          .eq('id', data.session.user.id)
          .maybeSingle();

        if (basicProfileError) {
          console.error('Basic profile fetch failed:', basicProfileError);
          toast.error('Error fetching basic profile data');
          // Still proceed with auth
        } else {
          console.log('Basic profile fetch successful:', basicProfile);
        }

        // Now try the full profile query
        console.log('Attempting full profile fetch with role...');
        const { data: fullProfile, error: profileError } = await supabase
          .from('profiles')
          .select('id, role, organization_id')
          .eq('id', data.session.user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Full profile fetch failed:', profileError);
          toast.error('Warning: Some profile data may be unavailable');
        } else {
          console.log('Full profile fetch successful:', fullProfile);
        }

        // Navigate even if profile fetch fails - the user is authenticated
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      console.error('Login process failed:', error);
      toast.error(error.message || 'Failed to sign in');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      defaultOpen 
      onOpenChange={() => navigate("/")}
    >
      <DialogContent 
        onEscapeKeyDown={(e) => e.preventDefault()} 
        onPointerDownOutside={(e) => e.preventDefault()}
        className="sm:max-w-[425px]"
      >
        <div className="flex flex-col items-center gap-6">
          <div
            className="flex items-center justify-center w-full py-2"
            aria-hidden="true"
          >
            <img
              src="/lovable-uploads/06222c28-dc26-4689-90c3-e5f51b382a30.png"
              alt="CardQuant Logo"
              className="h-20 w-auto object-contain"
            />
          </div>
          <DialogHeader>
            <DialogTitle className="sm:text-center">Welcome back</DialogTitle>
            <DialogDescription className="sm:text-center">
              Enter your credentials to login to your account.
            </DialogDescription>
          </DialogHeader>
        </div>

        <form onSubmit={handleSubmit} className="space-y-5">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor={`${id}-email`}>Email</Label>
              <Input
                id={`${id}-email`}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="hi@yourcompany.com"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor={`${id}-password`}>Password</Label>
              <Input
                id={`${id}-password`}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
            </div>
          </div>
          <div className="flex justify-between gap-2">
            <div className="flex items-center gap-2">
              <Checkbox id={`${id}-remember`} />
              <Label htmlFor={`${id}-remember`} className="font-normal text-muted-foreground">
                Remember me
              </Label>
            </div>
            <a className="text-sm underline hover:no-underline" href="#">
              Forgot password?
            </a>
          </div>
          <Button type="submit" className="w-full" disabled={loading}>
            {loading ? "Signing in..." : "Sign in"}
          </Button>
        </form>

        <div className="flex items-center gap-3 before:h-px before:flex-1 before:bg-border after:h-px after:flex-1 after:bg-border">
          <span className="text-xs text-muted-foreground">Or</span>
        </div>

        <Button variant="outline" disabled={loading} onClick={() => toast.error("Coming soon!")}>
          Login with Google
        </Button>
      </DialogContent>
    </Dialog>
  );
};
