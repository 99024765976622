import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Tables } from "@/integrations/supabase/types";
import { SelectedItemData } from "../../types/stream";

type InventoryItem = Tables<"inventory_items">;

interface SelectedItemsProps {
  selectedItems: SelectedItemData[];
  inventoryItems: InventoryItem[];
  onItemSelect: (itemId: string, quantity: number, salePrice: number) => void;
  onItemRemove: (itemId: string) => void;
}

export const SelectedItems = ({
  selectedItems,
  inventoryItems,
  onItemSelect,
  onItemRemove,
}: SelectedItemsProps) => {
  return (
    <div className="space-y-2">
      <h4 className="text-md font-medium text-white">Selected Items</h4>
      {selectedItems.map((item) => {
        const inventoryItem = inventoryItems.find(i => i.id === item.itemId);
        const costBasis = Number(inventoryItem?.cost_basis) || 0;
        
        return (
          <div key={item.itemId} className="flex items-center justify-between p-4 bg-dark-card border border-dark-border rounded-lg">
            <div className="flex-1">
              <span className="text-white">{item.name}</span>
              <div className="text-sm text-gray-400">
                Available: {item.availableQuantity} | Cost Basis: ${costBasis.toLocaleString()}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <label className="text-sm text-gray-400">Quantity:</label>
                    <Input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow empty input
                        if (value === '') {
                          onItemSelect(item.itemId, 0, item.salePrice);
                          return;
                        }
                        
                        const parsedValue = parseInt(value);
                        // Only validate and update if it's a valid number
                        if (!isNaN(parsedValue)) {
                          // Apply bounds only for negative numbers or exceeding available quantity
                          if (parsedValue < 0) {
                            onItemSelect(item.itemId, 0, item.salePrice);
                          } else if (parsedValue > item.availableQuantity) {
                            onItemSelect(item.itemId, item.availableQuantity, item.salePrice);
                          } else {
                            // Use the exact number typed by user
                            onItemSelect(item.itemId, parsedValue, item.salePrice);
                          }
                        }
                      }}
                      className="w-20 bg-dark-card border-dark-border text-white"
                      min="0"
                      max={item.availableQuantity}
                      placeholder="0"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <label className="text-sm text-gray-400">Sale Price:</label>
                    <Input
                      type="number"
                      value={item.salePrice || costBasis}
                      onChange={(e) => {
                        const newSalePrice = Math.max(0, Number(e.target.value) || 0);
                        onItemSelect(item.itemId, item.quantity, newSalePrice);
                      }}
                      className="w-20 bg-dark-card border-dark-border text-white"
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => onItemRemove(item.itemId)}
                className="text-white hover:text-white hover:bg-dark-muted"
              >
                Remove
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};