
import { AppLayout } from "@/components/layout/AppLayout";
import { AnalyticsHeader } from "@/components/analytics/AnalyticsHeader";
import { AnalyticsDashboard } from "@/components/analytics/AnalyticsDashboard";

const Analytics = () => {
  return (
    <AppLayout>
      <div className="space-y-8">
        <AnalyticsHeader />
        <AnalyticsDashboard />
      </div>
    </AppLayout>
  );
};

export default Analytics;
