import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useInventoryQueries } from "@/hooks/useInventoryQueries";
import { useQuery } from "@tanstack/react-query";

interface AddItemFormProps {
  onClose: () => void;
}

export const AddItemForm = ({ onClose }: AddItemFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { invalidateAllInventoryQueries } = useInventoryQueries();
  
  const [formData, setFormData] = useState({
    name: "",
    quantity: "",
    cost_basis: "",
    category: "",
    isAwaiting: false,
  });

  const { data: profile } = useQuery({
    queryKey: ['user-profile'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: categories = [] } = useQuery({
    queryKey: ['inventory-categories'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return [];

      const { data, error } = await supabase
        .from('inventory_items')
        .select('category')
        .eq('user_id', user.id)
        .eq('status', 'category_marker');

      if (error) {
        console.error('Error fetching categories:', error);
        return [];
      }

      // Filter out null categories and get unique values
      return [...new Set(data.map(item => item.category).filter(Boolean))];
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      if (!profile) throw new Error('Profile not found');

      console.log('Adding new item:', {
        ...formData,
        user_id: user.id,
        organization_id: profile.organization_id
      });

      const { error } = await supabase.from('inventory_items').insert({
        name: formData.name,
        quantity: parseInt(formData.quantity),
        cost_basis: parseFloat(formData.cost_basis),
        market_value: parseFloat(formData.cost_basis), // Initially set to cost basis
        category: formData.category || null,
        user_id: user.id,
        organization_id: profile.organization_id,
        status: formData.isAwaiting ? 'awaiting' : 'active'
      });

      if (error) throw error;

      await invalidateAllInventoryQueries();
      
      toast.success('Item added successfully');
      onClose();
      setFormData({
        name: "",
        quantity: "",
        cost_basis: "",
        category: "",
        isAwaiting: false,
      });
    } catch (error: any) {
      console.error('Error adding item:', error);
      toast.error(error.message || 'Failed to add item');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="name" className="text-white">Product Name</Label>
        <Input
          id="name"
          value={formData.name}
          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          className="bg-dark-card border-dark-border text-white"
          required
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="quantity" className="text-white">Quantity</Label>
        <Input
          id="quantity"
          type="number"
          min="0"
          value={formData.quantity}
          onChange={(e) => setFormData(prev => ({ ...prev, quantity: e.target.value }))}
          className="bg-dark-card border-dark-border text-white"
          required
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="cost" className="text-white">Cost ($)</Label>
        <Input
          id="cost"
          type="number"
          min="0"
          step="0.01"
          value={formData.cost_basis}
          onChange={(e) => setFormData(prev => ({ ...prev, cost_basis: e.target.value }))}
          className="bg-dark-card border-dark-border text-white"
          required
        />
      </div>
      <div className="space-y-2">
        <Label htmlFor="category" className="text-white">Category</Label>
        <Select
          value={formData.category}
          onValueChange={(value) => setFormData(prev => ({ ...prev, category: value }))}
        >
          <SelectTrigger className="bg-dark-card border-dark-border text-white">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent 
            className="bg-dark-card border-dark-border z-[1000]"
            position="popper"
            sideOffset={5}
          >
            {categories.map((category) => (
              <SelectItem 
                key={category} 
                value={category} 
                className="text-white hover:bg-dark-muted focus:bg-dark-muted cursor-pointer"
              >
                {category}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="awaiting"
          checked={formData.isAwaiting}
          onCheckedChange={(checked) => 
            setFormData(prev => ({ ...prev, isAwaiting: checked as boolean }))
          }
        />
        <Label htmlFor="awaiting" className="text-white">
          Item is awaiting arrival
        </Label>
      </div>
      <div className="flex justify-end space-x-2 pt-4">
        <Button
          type="button"
          variant="outline"
          onClick={onClose}
          className="bg-dark-card text-white hover:bg-dark-muted"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting}
          className="bg-white text-black hover:bg-gray-100"
        >
          {isSubmitting ? 'Adding...' : 'Add Item'}
        </Button>
      </div>
    </form>
  );
};
