
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const useInventoryQueries = () => {
  const queryClient = useQueryClient();

  const invalidateAllInventoryQueries = async () => {
    console.log('Invalidating inventory queries...');
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['inventory-items'] }),
      queryClient.invalidateQueries({ queryKey: ['dashboard-inventory'] }),
      queryClient.invalidateQueries({ queryKey: ['awaiting-items'] }),
      queryClient.invalidateQueries({ queryKey: ['awaiting-data'] }),
    ]);
  };

  const useCategories = (enabled: boolean) => {
    return useQuery({
      queryKey: ['inventory-categories'],
      queryFn: async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return [];

        const { data, error } = await supabase
          .from('inventory_items')
          .select('category')
          .eq('user_id', user.id)
          .eq('status', 'category_marker');

        if (error) {
          console.error('Error fetching categories:', error);
          return [];
        }

        return [...new Set(data.map(item => item.category))];
      },
      enabled,
    });
  };

  const clearAllInventory = async () => {
    try {
      console.log('Starting to clear all inventory...');
      
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError || !user) {
        toast.error('Please sign in to clear inventory');
        return false;
      }

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('organization_id')
        .eq('id', user.id)
        .maybeSingle();

      if (profileError) {
        console.error('Error fetching profile:', profileError);
        toast.error('Error loading profile data');
        return false;
      }

      // First, delete stream_items referencing inventory items
      let streamItemsQuery = supabase.from('stream_items').delete();
      
      if (profile?.organization_id) {
        // For organization users, get inventory items for the org
        const { data: inventoryItems } = await supabase
          .from('inventory_items')
          .select('id')
          .eq('organization_id', profile.organization_id);
          
        if (inventoryItems?.length) {
          // Delete stream items linked to these inventory items
          streamItemsQuery = streamItemsQuery.in('inventory_item_id', inventoryItems.map(item => item.id));
        }
      } else {
        // For individual users, get their inventory items
        const { data: inventoryItems } = await supabase
          .from('inventory_items')
          .select('id')
          .eq('user_id', user.id);
          
        if (inventoryItems?.length) {
          // Delete stream items linked to these inventory items
          streamItemsQuery = streamItemsQuery.in('inventory_item_id', inventoryItems.map(item => item.id));
        }
      }

      // Execute the stream items deletion
      const { error: streamItemsError } = await streamItemsQuery;
      if (streamItemsError) {
        console.error('Error deleting stream items:', streamItemsError);
        toast.error('Failed to clear inventory references');
        return false;
      }

      // Now delete the inventory items
      let inventoryQuery = supabase.from('inventory_items').delete();
      
      if (profile?.organization_id) {
        // For organization users, delete org items
        inventoryQuery = inventoryQuery.eq('organization_id', profile.organization_id);
      } else {
        // For individual users, delete their items
        inventoryQuery = inventoryQuery.eq('user_id', user.id);
      }

      const { error: inventoryError } = await inventoryQuery;

      if (inventoryError) {
        console.error('Error clearing inventory:', inventoryError);
        toast.error('Failed to clear inventory');
        return false;
      }

      // Invalidate all queries to refresh data
      await invalidateAllInventoryQueries();
      
      toast.success('All inventory items have been deleted');
      return true;
    } catch (error) {
      console.error('Unexpected error clearing inventory:', error);
      toast.error('An unexpected error occurred');
      return false;
    }
  };

  return {
    invalidateAllInventoryQueries,
    useCategories,
    clearAllInventory,
  };
};
