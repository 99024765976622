import { useState, useEffect } from "react";
import { InventoryItem } from "@/hooks/useInventoryData";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Check, X } from "lucide-react";

interface InventoryCheckItemProps {
  item: InventoryItem;
  onCheckComplete: (itemId: string, isCorrect: boolean, actualQuantity?: number) => void;
  bulkUpdateTimestamp: number;
}

export const InventoryCheckItem = ({ 
  item,
  onCheckComplete,
  bulkUpdateTimestamp
}: InventoryCheckItemProps) => {
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [actualQuantity, setActualQuantity] = useState<string>("");

  const handleCheck = (correct: boolean) => {
    setIsCorrect(correct);
    onCheckComplete(
      item.id, 
      correct, 
      correct ? undefined : parseInt(actualQuantity)
    );
  };

  // Handle bulk updates
  useEffect(() => {
    if (bulkUpdateTimestamp > 0) {
      setIsCorrect(true);
      setActualQuantity("");
    }
  }, [bulkUpdateTimestamp]);

  return (
    <div className="flex items-center justify-between p-4 bg-dark-muted rounded-lg">
      <div className="flex-1">
        <h3 className="font-medium text-white">{item.name}</h3>
        <p className="text-sm text-gray-400">
          Quantity: {item.quantity} | Cost: ${item.cost_basis}
        </p>
      </div>

      <div className="flex items-center gap-4">
        {!isCorrect && (
          <Input
            type="number"
            value={actualQuantity}
            onChange={(e) => setActualQuantity(e.target.value)}
            placeholder="Actual quantity"
            className="w-32 bg-dark-card border-dark-border text-white"
          />
        )}
        
        {isCorrect === null && (
          <>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleCheck(true)}
              className="text-green-500 hover:text-green-600"
            >
              <Check className="h-5 w-5" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => handleCheck(false)}
              disabled={!actualQuantity}
              className="text-red-500 hover:text-red-600"
            >
              <X className="h-5 w-5" />
            </Button>
          </>
        )}

        {isCorrect !== null && (
          <span className={`text-sm ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
            {isCorrect ? 'Correct' : `Incorrect (${actualQuantity})`}
          </span>
        )}
      </div>
    </div>
  );
};