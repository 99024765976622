
import { AppLayout } from "@/components/layout/AppLayout";
import { InventoryHeader } from "@/components/inventory/InventoryHeader";
import { InventorySearch } from "@/components/inventory/InventorySearch";
import { InventoryTable } from "@/components/inventory/InventoryTable";
import { InventorySkeleton } from "@/components/inventory/InventorySkeleton";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useInventoryData, InventoryItem } from "@/hooks/useInventoryData";
import { useInventoryFilters } from "@/hooks/useInventoryFilters";
import { Button } from "@/components/ui/button";
import { calculateCategoryValue } from "@/utils/inventoryCalculations";
import { useInventoryQueries } from "@/hooks/useInventoryQueries";

const Inventory = () => {
  const [categoryOrder, setCategoryOrder] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentStatus, setCurrentStatus] = useState<'active' | 'archived'>('active');
  const [currentPage, setCurrentPage] = useState(0);
  const { data: inventoryData, isLoading, isFetching, error } = useInventoryData(currentPage);
  
  const completeInventoryItems = useMemo(() => {
    if (!inventoryData?.items?.length) return [];
    
    return inventoryData.items.map(item => ({
      ...item,
      created_at: item.created_at || new Date().toISOString(),
      updated_at: item.updated_at || new Date().toISOString(),
      organization_id: item.organization_id || '',
      user_id: item.user_id || '',
      category: item.category || 'Uncategorized',
      quantity: item.quantity ?? 0,
      cost_basis: item.cost_basis ?? 0,
      market_value: item.market_value ?? 0,
      status: item.status || 'active'
    }));
  }, [inventoryData?.items]);

  const filteredItems = useInventoryFilters(completeInventoryItems, searchTerm, selectedCategory, currentStatus);

  useEffect(() => {
    if (categoryOrder.length === 0 && Object.keys(filteredItems).length > 0) {
      setCategoryOrder(Object.keys(filteredItems));
    }
  }, [filteredItems, categoryOrder.length]);

  const handleReorder = useCallback((draggedCategory: string, targetCategory: string) => {
    setCategoryOrder(prevOrder => {
      const newOrder = [...prevOrder];
      const draggedIndex = newOrder.indexOf(draggedCategory);
      const targetIndex = newOrder.indexOf(targetCategory);
      
      if (draggedIndex !== -1 && targetIndex !== -1) {
        newOrder.splice(draggedIndex, 1);
        newOrder.splice(targetIndex, 0, draggedCategory);
        return newOrder;
      }
      return prevOrder;
    });
  }, []);

  const totalInventoryValue = useMemo(() => {
    if (!completeInventoryItems?.length) return 0;
    
    return completeInventoryItems.reduce((sum, item) => {
      // Only include items with matching status
      if (item.status === currentStatus) {
        // The category_marker check was causing a type error because TypeScript knows
        // currentStatus can only be 'active' or 'archived', not 'category_marker'
        // Instead of directly comparing with 'category_marker', check if it's a regular item
        // that should contribute to the total value
        const quantity = Number(item.quantity || 0);
        const costBasis = Number(item.cost_basis || 0);
        return sum + (quantity * costBasis);
      }
      return sum;
    }, 0);
  }, [completeInventoryItems, currentStatus]);

  const calculateTotalValue = useCallback((items: InventoryItem[] = []) => {
    return calculateCategoryValue(items, currentStatus);
  }, [currentStatus]);

  const handleLoadMore = () => {
    setCurrentPage(prev => prev + 1);
  };

  if (isLoading && currentPage === 0) {
    return (
      <AppLayout>
        <InventorySkeleton />
      </AppLayout>
    );
  }

  if (error) {
    return (
      <AppLayout>
        <div className="text-center p-8">
          <h2 className="text-xl text-red-500">Error loading inventory</h2>
          <p className="text-gray-400">Please try refreshing the page</p>
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <InventoryHeader 
            totalValue={totalInventoryValue} 
            onStatusChange={setCurrentStatus}
            currentStatus={currentStatus}
          />
        </div>
        
        <div className="space-y-6">
          <InventorySearch 
            onSearch={setSearchTerm}
            onCategoryChange={setSelectedCategory}
            onStatusChange={setCurrentStatus}
            currentStatus={currentStatus}
          />

          {(!inventoryData?.items || inventoryData.items.length === 0) ? (
            <div className="bg-dark-card border border-dark-border rounded-lg p-6">
              <div className="text-center">
                <h3 className="text-lg font-medium text-white mb-2">No items in your inventory yet</h3>
                <p className="text-gray-400 mb-4">
                  Add your first item or upload your inventory to get started
                </p>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              {categoryOrder
                .filter(category => filteredItems[category]?.length > 0)
                .map((category, index) => (
                  <InventoryTable
                    key={category}
                    category={category}
                    items={filteredItems[category]}
                    totalValue={calculateTotalValue(filteredItems[category])}
                    onReorder={handleReorder}
                    index={index}
                  />
                ))}
                
              {isFetching && (
                <div className="flex justify-center p-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                </div>
              )}
              
              {inventoryData && inventoryData.total > inventoryData.items.length && (
                <div className="flex justify-center mt-4">
                  <Button 
                    onClick={handleLoadMore} 
                    disabled={isFetching}
                    variant="outline"
                    className="border-dark-border text-white hover:text-white hover:bg-dark-muted"
                  >
                    {isFetching ? 'Loading...' : 'Load More Items'}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Inventory;
