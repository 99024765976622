import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { Tables } from "@/integrations/supabase/types";
import { CategoryHeader } from "./CategoryHeader";
import { InventoryItem } from "./InventoryItem";

type InventoryItemType = Tables<"inventory_items">;

interface CategoryGroupProps {
  category: string;
  items: InventoryItemType[];
  isExpanded: boolean;
  onToggle: (category: string) => void;
  onAddItem: (item: InventoryItemType) => void;
}

export const CategoryGroup = ({
  category,
  items,
  isExpanded,
  onToggle,
  onAddItem,
}: CategoryGroupProps) => {
  return (
    <Collapsible
      open={isExpanded}
      onOpenChange={() => onToggle(category)}
      className="border-b border-dark-border last:border-b-0"
    >
      <CategoryHeader
        category={category}
        itemCount={items.length}
        isExpanded={isExpanded}
      />
      <CollapsibleContent>
        <div className="space-y-2 ml-6 mb-4">
          {items.map((item) => (
            <InventoryItem
              key={item.id}
              item={item}
              onAdd={onAddItem}
            />
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};