import { TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Plus, Trash2 } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useInventoryQueries } from "@/hooks/useInventoryQueries";

interface InventoryTableRowProps {
  id: string;
  name: string;
  quantity: number;
  costBasis: number;
  totalValue: number;
  isSelected: boolean;
  onSelect: (checked: boolean) => void;
}

export const InventoryTableRow = ({
  id,
  name,
  quantity,
  costBasis,
  totalValue,
  isSelected,
  onSelect,
}: InventoryTableRowProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [newQuantity, setNewQuantity] = useState("");
  const [newCost, setNewCost] = useState("");
  const [previewAverageCost, setPreviewAverageCost] = useState<number | null>(null);
  const { invalidateAllInventoryQueries } = useInventoryQueries();

  // Calculate preview average cost whenever inputs change
  useEffect(() => {
    if (newQuantity && newCost) {
      const addedQuantity = parseInt(newQuantity);
      const addedCost = parseFloat(newCost);
      const totalNewCost = addedQuantity * addedCost;
      const totalCurrentCost = quantity * costBasis;
      const newTotalQuantity = quantity + addedQuantity;
      const averageCost = (totalCurrentCost + totalNewCost) / newTotalQuantity;
      setPreviewAverageCost(averageCost);
    } else {
      setPreviewAverageCost(null);
    }
  }, [newQuantity, newCost, quantity, costBasis]);

  const handleAddStock = async () => {
    if (!newQuantity || !newCost) {
      toast.error("Please enter both quantity and cost");
      return;
    }

    setIsUpdating(true);
    try {
      const addedQuantity = parseInt(newQuantity);
      const addedCost = parseFloat(newCost);
      const totalNewCost = addedQuantity * addedCost;
      const totalCurrentCost = quantity * costBasis;
      const newTotalQuantity = quantity + addedQuantity;
      const averageCost = (totalCurrentCost + totalNewCost) / newTotalQuantity;

      const { error } = await supabase
        .from('inventory_items')
        .update({ 
          quantity: newTotalQuantity,
          cost_basis: averageCost,
          market_value: averageCost, // Update market value to match new cost basis
          status: newTotalQuantity > 0 ? 'active' : 'archived' // Update status based on quantity
        })
        .eq('id', id);

      if (error) throw error;

      await invalidateAllInventoryQueries();
      toast.success('Stock updated successfully');
      setNewQuantity("");
      setNewCost("");
      setPreviewAverageCost(null);
    } catch (error) {
      console.error('Error updating stock:', error);
      toast.error('Failed to update stock');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <TableRow className="border-dark-border hover:bg-dark-muted">
      <TableCell>
        <Checkbox 
          checked={isSelected}
          onCheckedChange={onSelect}
        />
      </TableCell>
      <TableCell className="font-medium text-white">{name}</TableCell>
      <TableCell className="text-white">{quantity}</TableCell>
      <TableCell className="text-white">${Number(costBasis).toLocaleString()}</TableCell>
      <TableCell className="text-white">${totalValue.toLocaleString()}</TableCell>
      <TableCell>
        <div className="flex gap-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="ghost" size="sm" className="text-white hover:text-white hover:bg-dark-muted">
                <Plus className="w-4 h-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80 bg-dark-card border-dark-border">
              <div className="grid gap-4">
                <div className="space-y-2">
                  <h4 className="font-medium leading-none text-white">Add Stock</h4>
                  <p className="text-sm text-gray-400">
                    Add more items to update quantity and average cost
                  </p>
                </div>
                <div className="grid gap-2">
                  <div className="grid grid-cols-3 items-center gap-4">
                    <Label htmlFor="quantity" className="text-white">
                      Quantity
                    </Label>
                    <Input
                      id="quantity"
                      type="number"
                      className="col-span-2 bg-dark-card border-dark-border text-white"
                      value={newQuantity}
                      onChange={(e) => setNewQuantity(e.target.value)}
                      min="1"
                    />
                  </div>
                  <div className="grid grid-cols-3 items-center gap-4">
                    <Label htmlFor="cost" className="text-white">
                      Cost per item
                    </Label>
                    <Input
                      id="cost"
                      type="number"
                      className="col-span-2 bg-dark-card border-dark-border text-white"
                      value={newCost}
                      onChange={(e) => setNewCost(e.target.value)}
                      min="0.01"
                      step="0.01"
                    />
                  </div>
                  {previewAverageCost !== null && (
                    <div className="mt-2 p-2 bg-dark-muted rounded-md">
                      <p className="text-sm text-white">
                        New Average Cost: ${previewAverageCost.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </p>
                    </div>
                  )}
                </div>
                <Button 
                  onClick={handleAddStock}
                  disabled={isUpdating}
                  className="bg-white text-black hover:bg-gray-100"
                >
                  {isUpdating ? 'Updating...' : 'Update Stock'}
                </Button>
              </div>
            </PopoverContent>
          </Popover>
          <Button variant="ghost" size="sm" className="text-white hover:text-white hover:bg-dark-muted">
            <Trash2 className="w-4 h-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};