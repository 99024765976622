import { format, differenceInHours } from "date-fns";
import { Eye } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Tables } from "@/integrations/supabase/types";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { calculateStreamFinancials } from "@/utils/streamFinancials";

type Stream = Tables<"streams">;

interface StreamMonthGroupProps {
  monthYear: string;
  streams: Stream[];
  onStreamSelect: (stream: Stream) => void;
}

export const StreamMonthGroup = ({ monthYear, streams, onStreamSelect }: StreamMonthGroupProps) => {
  const { data: settings } = useQuery({
    queryKey: ['user-settings'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: allStreamItems } = useQuery({
    queryKey: ['month-stream-items', monthYear],
    enabled: streams.length > 0,
    queryFn: async () => {
      const streamIds = streams.map(s => s.id);
      const { data, error } = await supabase
        .from('stream_items')
        .select(`
          *,
          inventory_item:inventory_items(*)
        `)
        .in('stream_id', streamIds);

      if (error) throw error;
      return data;
    },
  });

  const getStreamItems = (streamId: string) => {
    if (!allStreamItems) return [];
    return allStreamItems.filter(item => item.stream_id === streamId);
  };

  // Calculate stream financials with items
  const streamFinancials = streams.map(stream => {
    const streamItems = getStreamItems(stream.id);
    const totalProductCosts = streamItems.reduce((sum, item) => {
      const costBasis = Number(item.inventory_item?.cost_basis) || 0;
      return sum + ((Number(item.quantity_sold) || 0) * costBasis);
    }, 0);

    const streamDurationHours = stream.start_time && stream.end_time
      ? differenceInHours(new Date(stream.end_time), new Date(stream.start_time))
      : 0;

    return {
      stream,
      financials: calculateStreamFinancials({
        totalSales: Number(stream.total_sales || 0),
        productCosts: totalProductCosts,
        platform: stream.platform,
        settings,
        streamDurationHours
      })
    };
  });

  // Calculate month totals by summing up the individual stream financials
  const monthTotals = streamFinancials.reduce((totals, { stream, financials }) => ({
    sales: totals.sales + Number(stream.total_sales || 0),
    netProfit: totals.netProfit + financials.netProfit,
    totalProductCosts: totals.totalProductCosts + financials.productCosts
  }), { sales: 0, netProfit: 0, totalProductCosts: 0 });

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-white">{monthYear}</h2>
        <div className="text-gray-400">
          <span className="mr-4">
            Sales: ${monthTotals.sales.toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </span>
          <span className="mr-4">
            Product Cost: ${monthTotals.totalProductCosts.toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </span>
          <span>
            Net Profit: <span className={monthTotals.netProfit >= 0 ? 'text-green-500' : 'text-red-500'}>
              ${monthTotals.netProfit.toLocaleString('en-US', { minimumFractionDigits: 2 })}
            </span>
          </span>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left text-gray-400">
              <th className="p-4">Date</th>
              <th className="p-4">Streamer</th>
              <th className="p-4">Platform</th>
              <th className="p-4">Duration</th>
              <th className="p-4">Sales</th>
              <th className="p-4">Product Cost</th>
              <th className="p-4">Net Profit</th>
              <th className="p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {streamFinancials.map(({ stream, financials }) => (
              <tr key={stream.id} className="border-t border-dark-border">
                <td className="p-4 text-white">
                  {format(new Date(stream.start_time), "MMM dd (yyyy)")}
                </td>
                <td className="p-4 text-white">{stream.streamer}</td>
                <td className="p-4 text-white">{stream.platform}</td>
                <td className="p-4 text-white">
                  {stream.end_time ? 
                    `${differenceInHours(new Date(stream.end_time), new Date(stream.start_time))} hours` : 
                    "In progress"
                  }
                </td>
                <td className="p-4 text-white">
                  ${Number(stream.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </td>
                <td className="p-4 text-red-500">
                  -${financials.productCosts.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </td>
                <td className={`p-4 ${financials.netProfit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  ${Math.abs(financials.netProfit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </td>
                <td className="p-4">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => onStreamSelect(stream)}
                    className="text-gray-400 hover:text-white"
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};