import { useEffect, useState } from "react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { CreateOrganizationDialog } from "./dialogs/CreateOrganizationDialog";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";

interface NoOrganizationAlertProps {
  onOrganizationCreated: () => Promise<void>;
}

export const NoOrganizationAlert = ({ onOrganizationCreated }: NoOrganizationAlertProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const createDefaultOrganization = async () => {
      try {
        setIsCreating(true);
        setError(null);
        
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError) {
          console.error('Error getting user:', userError);
          throw userError;
        }
        
        if (!user) {
          console.error('No authenticated user found');
          setError('Not authenticated');
          return;
        }

        console.log('Starting organization setup for user:', user.id);

        // First check if user already has an organization
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('organization_id, organization_name, role')
          .eq('id', user.id)
          .single();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
          throw profileError;
        }

        console.log('Fetched profile:', profile);

        if (profile?.organization_id) {
          console.log('User already has organization:', profile.organization_id);
          await onOrganizationCreated();
          return;
        }

        // Create new organization
        const orgName = profile?.organization_name || 'CardQuant';
        console.log('Creating organization with name:', orgName);
        
        const { data: org, error: orgError } = await supabase
          .from('organizations')
          .insert([
            { name: orgName, owner_id: user.id }
          ])
          .select()
          .single();

        if (orgError) {
          console.error('Error creating organization:', orgError);
          throw orgError;
        }

        console.log('Created organization:', org);

        // Update profile with organization and admin role
        const { error: profileUpdateError } = await supabase
          .from('profiles')
          .update({ 
            organization_id: org.id,
            role: 'admin',
            organization_name: orgName
          })
          .eq('id', user.id);

        if (profileUpdateError) {
          console.error('Error updating profile:', profileUpdateError);
          throw profileUpdateError;
        }

        console.log('Updated profile with organization');

        // Create default user settings
        const { error: settingsError } = await supabase
          .from('user_settings')
          .insert([{
            user_id: user.id,
            organization_id: org.id,
            tiktok_fee: 0,
            fanatics_fee: 0,
            shipping_cost_type: 'disabled',
            shipping_cost_value: 0,
            streamer_pay_type: 'disabled',
            streamer_pay_value: 0
          }]);

        if (settingsError) {
          console.error('Error creating user settings:', settingsError);
          throw settingsError;
        }

        console.log('Created user settings');

        await onOrganizationCreated();
        toast.success('Organization setup completed successfully');
      } catch (error: any) {
        console.error('Error in organization setup:', error);
        setError(error.message || 'Failed to setup organization');
        toast.error('Failed to setup organization');
      } finally {
        setIsCreating(false);
      }
    };

    createDefaultOrganization();
  }, [onOrganizationCreated]);

  if (isCreating) {
    return (
      <div className="space-y-6 max-w-2xl mx-auto mt-8">
        <Alert>
          <AlertDescription className="flex items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin" />
            Setting up your organization...
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  if (error) {
    return (
      <div className="space-y-6 max-w-2xl mx-auto mt-8">
        <Alert variant="destructive">
          <AlertDescription>
            {error}
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="space-y-6 max-w-2xl mx-auto mt-8">
      <Alert>
        <AlertDescription className="flex flex-col gap-4">
          <p>You need to create or join an organization to access settings.</p>
          <div className="flex justify-end">
            <CreateOrganizationDialog onSuccess={onOrganizationCreated} />
          </div>
        </AlertDescription>
      </Alert>
    </div>
  );
};