import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";

interface StreamItem {
  id: string;
  quantity_sold: number | null;
  sale_price: number | null;
  inventory_item: {
    name: string;
    cost_basis: number | null;
  } | null;
}

interface StreamItemsListProps {
  items: StreamItem[] | null;
}

export const StreamItemsList = ({ items }: StreamItemsListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!items?.length) return null;

  const totalQuantity = items.reduce((sum, item) => sum + (item.quantity_sold || 0), 0);
  const totalCost = items.reduce((sum, item) => 
    sum + ((item.quantity_sold || 0) * (item.inventory_item?.cost_basis || 0)), 0
  );

  return (
    <div>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full px-4 py-2 bg-dark-muted rounded-lg hover:bg-dark-muted/80 transition-colors"
      >
        <span className="font-medium">Stream Items ({totalQuantity} items)</span>
        {isExpanded ? (
          <ChevronUp className="h-5 w-5" />
        ) : (
          <ChevronDown className="h-5 w-5" />
        )}
      </button>

      {isExpanded && (
        <Card className="mt-2 bg-dark-muted border-dark-border">
          <CardContent className="p-4">
            <div className="space-y-4">
              {items.map((item) => {
                const costBasis = item.inventory_item?.cost_basis || 0;
                const quantity = item.quantity_sold || 0;
                const totalItemCost = quantity * costBasis;

                return (
                  <div key={item.id} className="flex justify-between items-start">
                    <div>
                      <p className="font-medium">{item.inventory_item?.name}</p>
                      <p className="text-sm text-gray-400">
                        Quantity Sold: {quantity}
                      </p>
                      <p className="text-sm text-gray-400">
                        Cost Basis: ${costBasis.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-gray-400">
                        Total Cost: ${totalItemCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="border-t border-dark-border pt-4 mt-4">
                <div className="flex justify-between items-center">
                  <span className="font-medium">Totals</span>
                  <div className="text-right">
                    <p className="text-sm text-gray-400">
                      Total Cost: ${totalCost.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};