import { useState } from "react";  
import { AppSidebar } from "./AppSidebar";  
import { Button } from "@/components/ui/button";  
import { ChevronLeft, ChevronRight } from "lucide-react";  

interface AppLayoutProps {  
  children: React.ReactNode;  
}  

export const AppLayout = ({ children }: AppLayoutProps) => {  
  const [sidebarVisible, setSidebarVisible] = useState(true);  

  const toggleSidebar = () => {  
    setSidebarVisible((prev) => !prev);  
  };  

  return (  
    <div className="min-h-screen bg-background">  
      <div className="flex min-h-screen relative">  
        <div  
          className={`h-screen transition-transform duration-300 ease-in-out flex ${  
            sidebarVisible ? "translate-x-0" : "-translate-x-full"  
          }`}  
          style={{ width: sidebarVisible ? "280px" : "0px", overflow: "hidden" }}  
        >  
          <AppSidebar />  
        </div>  
        <Button  
          variant="ghost"  
          size="icon"  
          onClick={toggleSidebar}  
          className="relative top-4 -right-4 z-50 h-8 w-8 rounded-full border shadow-md bg-background"  
          aria-label={sidebarVisible ? "Hide sidebar" : "Show sidebar"}  
        >  
          {sidebarVisible ? <ChevronLeft className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}  
        </Button>  
        <div className={`flex-1 h-[100vh] overflow-auto transition-all duration-600`}>  
          <div className="min-h-screen pt-16">  
            <div className="container mx-auto px-8 py-8 max-w-[1600px]">  
              {children}  
            </div>  
          </div>  
        </div>  
      </div>  
    </div>  
  );  
};  