import { format } from "date-fns";
import { Tables } from "@/integrations/supabase/types";
import { differenceInHours, differenceInMinutes } from "date-fns";

type Stream = Tables<"streams">;

interface StreamDetailsHeaderProps {
  stream: Stream;
}

export const StreamDetailsHeader = ({ stream }: StreamDetailsHeaderProps) => {
  const calculateDuration = (start: string, end: string | null) => {
    if (!end) return "In progress";
    const startDate = new Date(start);
    const endDate = new Date(end);
    const hours = differenceInHours(endDate, startDate);
    const minutes = differenceInMinutes(endDate, startDate) % 60;
    return `${hours}.${minutes.toString().padStart(2, '0')} hours`;
  };

  const formatPlatform = (platform: string) => {
    if (platform.toLowerCase() === 'tiktok') return 'TikTok';
    return platform.charAt(0).toUpperCase() + platform.slice(1);
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-sm font-medium text-gray-400">Date</h3>
          <p className="text-white">
            {format(new Date(stream.start_time), "MMM dd, yyyy")}
          </p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-400">Platform</h3>
          <p className="text-white">{formatPlatform(stream.platform)}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-400">Duration</h3>
          <p className="text-white">
            {calculateDuration(stream.start_time, stream.end_time)}
          </p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-400">Total Sales</h3>
          <p className="text-white">
            ${Number(stream.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-400">Streamer</h3>
          <p className="text-white">{stream.streamer}</p>
        </div>
        {stream.sorter && (
          <div>
            <h3 className="text-sm font-medium text-gray-400">Sorter</h3>
            <p className="text-white">{stream.sorter}</p>
          </div>
        )}
      </div>
    </div>
  );
};