import { useInventoryData, InventoryItem } from "@/hooks/useInventoryData";
import { InventoryCheckCategory } from "./InventoryCheckCategory";
import { InventoryCheckReport } from "./InventoryCheckReport";
import { useState } from "react";
import { InventorySkeleton } from "../inventory/InventorySkeleton";

interface CheckResult {
  itemId: string;
  isCorrect: boolean;
  actualQuantity?: number;
}

export const InventoryCheckList = () => {
  const { data, isLoading } = useInventoryData();
  const [showReport, setShowReport] = useState(false);
  const [checkResults, setCheckResults] = useState<CheckResult[]>([]);

  if (isLoading) {
    return <InventorySkeleton />;
  }

  if (!data?.items || data.items.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-white">No inventory items found.</p>
      </div>
    );
  }

  // Filter out archived items and items with zero quantity
  const activeItems = data.items.filter(item => 
    item.status === 'active' && 
    (item.quantity || 0) > 0
  );

  if (!activeItems.length) {
    return (
      <div className="text-center py-8">
        <p className="text-white">No active items with available quantity found.</p>
      </div>
    );
  }

  // Group items by category
  const groupedItems = activeItems.reduce<Record<string, InventoryItem[]>>((acc, item) => {
    const category = item.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  // Create a map of item details for the report
  const itemsMap = activeItems.reduce<Record<string, { name: string; quantity: number; cost_basis: number }>>((acc, item) => {
    acc[item.id] = {
      name: item.name,
      quantity: item.quantity || 0,
      cost_basis: item.cost_basis || 0
    };
    return acc;
  }, {});

  const handleItemCheck = (itemId: string, isCorrect: boolean, actualQuantity?: number) => {
    setCheckResults(prev => {
      // Remove any existing check for this item
      const filtered = prev.filter(check => check.itemId !== itemId);
      // Add the new check
      return [...filtered, { itemId, isCorrect, actualQuantity }];
    });
  };

  const handleSubmitComplete = () => {
    setShowReport(false);
    setCheckResults([]);
  };

  return (
    <div className="space-y-6">
      {Object.entries(groupedItems).map(([category, items]) => (
        <InventoryCheckCategory 
          key={category} 
          category={category} 
          items={items}
          onItemCheck={handleItemCheck}
        />
      ))}
      <div className="flex justify-end">
        <InventoryCheckReport 
          open={showReport} 
          onOpenChange={setShowReport}
          results={checkResults}
          onSubmit={handleSubmitComplete}
          itemsMap={itemsMap}
        />
      </div>
    </div>
  );
};