import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { Plus } from "lucide-react";
import { useState } from "react";
import { AddItemForm } from "./AddItemForm";
import { useInventoryQueries } from "@/hooks/useInventoryQueries";

export const AddItemDialog = () => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="default" className="bg-white text-black hover:bg-gray-100">
          <Plus className="w-4 h-4 mr-2" />
          Add New Item
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-dark-card border-dark-border">
        <DialogHeader>
          <DialogTitle className="text-white">Add New Item</DialogTitle>
          <DialogDescription className="text-gray-400">
            Add a new item to your inventory. Items marked as awaiting will be tracked separately.
          </DialogDescription>
        </DialogHeader>
        <AddItemForm onClose={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};