import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";

interface InventoryCheckReport {
  id: string;
  report_date: string;
  total_items_checked: number;
  correct_items: number;
  incorrect_items: number;
  total_missing_value: number;
}

export const InventoryCheckHistory = () => {
  const [reports, setReports] = useState<InventoryCheckReport[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        console.log('Fetching inventory check reports...');
        const { data, error } = await supabase
          .from('inventory_check_reports')
          .select('*')
          .order('report_date', { ascending: false });

        if (error) throw error;
        setReports(data || []);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  if (reports.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-400">No inventory check reports found.</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {reports.map((report) => (
        <div key={report.id} className="bg-dark-muted rounded-lg p-4">
          <div className="flex justify-between items-start mb-2">
            <div>
              <h3 className="text-white font-medium">
                Check Report - {format(new Date(report.report_date), 'MMM d, yyyy h:mm a')}
              </h3>
              <p className="text-sm text-gray-400">
                {report.total_items_checked} items checked
              </p>
            </div>
            <div className="text-right">
              <p className="text-sm text-green-500">{report.correct_items} correct</p>
              {report.incorrect_items > 0 && (
                <p className="text-sm text-red-500">{report.incorrect_items} incorrect</p>
              )}
            </div>
          </div>
          {report.total_missing_value > 0 && (
            <p className="text-sm text-red-400">
              Total missing value: ${report.total_missing_value.toFixed(2)}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};