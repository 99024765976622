
import { useState, useEffect, useMemo } from "react";
import { WholesaleCustomer } from "../types";
import { Button } from "@/components/ui/button";
import { PlusCircle, Search, ChevronDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { CustomerForm } from "../CustomerForm";
import { useWholesaleCustomers } from "@/hooks/useWholesaleCustomers";

interface CustomerSelectorProps {
  selectedCustomerId: string | undefined;
  onSelect: (customerId: string) => void;
  disabled?: boolean;
}

export const CustomerSelector = ({
  selectedCustomerId,
  onSelect,
  disabled = false,
}: CustomerSelectorProps) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isNewCustomerDialogOpen, setIsNewCustomerDialogOpen] = useState(false);
  
  const { customers, isLoading, createCustomer } = useWholesaleCustomers();
  
  // Memoize the selected customer to avoid unnecessary re-renders
  const selectedCustomer = useMemo(() => 
    customers?.find(c => c.id === selectedCustomerId), 
    [customers, selectedCustomerId]
  );

  // Ensure we have an array even if customers is undefined
  const safeCustomers = useMemo(() => customers || [], [customers]);

  // Memoize filtered customers for better performance
  const filteredCustomers = useMemo(() => {
    if (!searchValue) return safeCustomers;
    
    const lowerSearchValue = searchValue.toLowerCase();
    return safeCustomers.filter(customer => 
      customer.name.toLowerCase().includes(lowerSearchValue) ||
      (customer.email && customer.email.toLowerCase().includes(lowerSearchValue)) ||
      (customer.phone && customer.phone.includes(searchValue))
    );
  }, [safeCustomers, searchValue]);

  const handleSelectCustomer = (customerId: string) => {
    onSelect(customerId);
    setOpen(false);
  };

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between bg-[#1E1E1E] border-gray-700 text-white"
            disabled={disabled || isLoading}
          >
            {isLoading 
              ? "Loading customers..." 
              : selectedCustomer 
                ? selectedCustomer.name 
                : "Select customer..."}
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[300px] p-0 bg-[#1E1E1E] border-gray-700 z-[103]" align="start">
          <Command className="w-full bg-[#1E1E1E]">
            <div className="flex items-center border-b border-gray-700 px-3">
              <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
              <CommandInput 
                placeholder="Search customers..." 
                value={searchValue}
                onValueChange={setSearchValue}
                className="border-none focus:ring-0 text-white bg-transparent"
              />
            </div>
            
            <CommandList className="max-h-[200px] overflow-y-auto">
              <CommandEmpty className="py-3 text-center text-sm text-gray-400">
                {safeCustomers.length === 0 ? "No customers yet" : "No customers found"}
              </CommandEmpty>
              
              {filteredCustomers.length > 0 && (
                <CommandGroup>
                  {filteredCustomers.map(customer => (
                    <CommandItem
                      key={customer.id}
                      value={customer.id}
                      onSelect={() => handleSelectCustomer(customer.id)}
                      className="cursor-pointer text-white hover:bg-gray-700"
                    >
                      <div className="flex flex-col items-start">
                        <span>{customer.name}</span>
                        {customer.email && (
                          <span className="text-xs text-gray-400">{customer.email}</span>
                        )}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
            </CommandList>
            
            <div className="p-1 border-t border-gray-700">
              <Button
                className="w-full gap-2 justify-start pl-2 text-white hover:bg-gray-700"
                type="button"
                variant="ghost"
                onClick={() => {
                  setOpen(false);
                  setIsNewCustomerDialogOpen(true);
                }}
              >
                <PlusCircle className="h-4 w-4" />
                Add New Customer
              </Button>
            </div>
          </Command>
        </PopoverContent>
      </Popover>
      
      {/* New Customer Dialog */}
      <Dialog open={isNewCustomerDialogOpen} onOpenChange={setIsNewCustomerDialogOpen}>
        <DialogContent className="sm:max-w-[600px] bg-[#121212] z-[103] border-gray-800">
          <DialogTitle className="text-white">Add New Customer</DialogTitle>
          <CustomerForm 
            onSubmit={async (data) => {
              const result = await createCustomer.mutateAsync(data);
              setIsNewCustomerDialogOpen(false);
              if (result) {
                onSelect(result.id);
              }
            }}
            onCancel={() => setIsNewCustomerDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
