import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { SignUpFormFields } from "./signup/SignUpFormFields";
import { useSignUpForm } from "./signup/useSignUpForm";

interface SignUpFormProps {
  open: boolean;
  onClose: () => void;
}

export const SignUpForm = ({ open, onClose }: SignUpFormProps) => {
  const { register, handleSubmit, errors, isLoading, onSubmit } = useSignUpForm(onClose);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="bg-dark-card border-dark-border sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold text-white">Create an account</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <SignUpFormFields register={register} errors={errors} />
          <Button
            type="submit"
            disabled={isLoading}
            className="w-full bg-white text-black hover:bg-gray-100"
          >
            {isLoading ? "Creating account..." : "Create account"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};