
import { useState, useEffect } from "react";
import { useInventoryData, InventoryItem } from "@/hooks/useInventoryData";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { Search, Plus, Minus, Trash2 } from "lucide-react";
import { Label } from "@/components/ui/label";

interface InventorySelectorProps {
  selectedItems: Array<{inventoryItem: InventoryItem, quantity: number, price: number}>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Array<{inventoryItem: InventoryItem, quantity: number, price: number}>>>;
}

export const InventorySelector = ({ 
  selectedItems, 
  setSelectedItems 
}: InventorySelectorProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [availableItems, setAvailableItems] = useState<InventoryItem[]>([]);
  const { data: inventoryData, isLoading } = useInventoryData();

  // Filter inventory items
  useEffect(() => {
    if (inventoryData?.items) {
      // Filter items based on search and only show active items that aren't category markers
      const filtered = inventoryData.items.filter(item => {
        const matchesSearch = item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.category?.toLowerCase().includes(searchTerm.toLowerCase());
        
        // Make these separate conditions to avoid the type error
        const isActive = item.status === 'active';
        const isNotCategoryMarker = item.status !== 'category_marker';
        
        return matchesSearch && isActive && isNotCategoryMarker;
      });
      setAvailableItems(filtered);
    }
  }, [inventoryData, searchTerm]);

  const addItemToSelection = (item: InventoryItem) => {
    // Check if already selected
    const existingIndex = selectedItems.findIndex(
      selected => selected.inventoryItem.id === item.id
    );
    
    if (existingIndex >= 0) {
      // Increment quantity if already selected
      const updated = [...selectedItems];
      updated[existingIndex].quantity += 1;
      setSelectedItems(updated);
    } else {
      // Add new item with default values - set selling price to cost basis by default
      setSelectedItems([
        ...selectedItems, 
        {
          inventoryItem: item,
          quantity: 1,
          // Use cost_basis as default price, ensure it's a number
          price: Number(item.cost_basis) || 0
        }
      ]);
    }
  };

  const removeItem = (index: number) => {
    const updated = [...selectedItems];
    updated.splice(index, 1);
    setSelectedItems(updated);
  };

  const updateItemQuantity = (index: number, newQuantity: number) => {
    if (newQuantity <= 0) return;
    
    // Check available inventory
    const item = selectedItems[index];
    const maxAvailable = Number(item.inventoryItem.quantity) || 0;
    
    // Don't allow selecting more than available inventory
    if (newQuantity > maxAvailable) {
      newQuantity = maxAvailable;
    }
    
    const updated = [...selectedItems];
    updated[index].quantity = newQuantity;
    setSelectedItems(updated);
  };

  const updateItemPrice = (index: number, newPrice: number) => {
    if (newPrice < 0) return;
    
    const updated = [...selectedItems];
    updated[index].price = newPrice;
    setSelectedItems(updated);
  };

  const calculateItemProfit = (item: {inventoryItem: InventoryItem, quantity: number, price: number}) => {
    const costBasis = Number(item.inventoryItem.cost_basis) || 0;
    const salePrice = item.price;
    return (salePrice - costBasis) * item.quantity;
  };

  return (
    <div className="space-y-4">
      {/* Selected Items */}
      {selectedItems.length > 0 && (
        <div className="border rounded-md overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Product</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Quantity</TableHead>
                <TableHead>Cost (each)</TableHead>
                <TableHead>Sale Price</TableHead>
                <TableHead>Total</TableHead>
                <TableHead>Profit</TableHead>
                <TableHead className="w-[60px]"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedItems.map((item, index) => (
                <TableRow key={item.inventoryItem.id}>
                  <TableCell className="font-medium">
                    {item.inventoryItem.name}
                  </TableCell>
                  <TableCell>
                    {item.inventoryItem.category || "Uncategorized"}
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center space-x-1">
                      <Button 
                        variant="outline" 
                        size="icon" 
                        className="h-7 w-7"
                        onClick={() => updateItemQuantity(index, item.quantity - 1)}
                      >
                        <Minus className="h-3 w-3" />
                      </Button>
                      <Input
                        type="number"
                        min="1"
                        max={item.inventoryItem.quantity}
                        value={item.quantity}
                        onChange={(e) => updateItemQuantity(index, parseInt(e.target.value) || 1)}
                        className="w-16 text-center"
                      />
                      <Button 
                        variant="outline" 
                        size="icon" 
                        className="h-7 w-7"
                        onClick={() => updateItemQuantity(index, item.quantity + 1)}
                      >
                        <Plus className="h-3 w-3" />
                      </Button>
                    </div>
                  </TableCell>
                  <TableCell>
                    ${Number(item.inventoryItem.cost_basis || 0).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <span className="mr-1">$</span>
                      <Input
                        type="number"
                        min="0"
                        step="0.01"
                        value={item.price}
                        onChange={(e) => updateItemPrice(index, parseFloat(e.target.value) || 0)}
                        className="w-20"
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    ${(item.quantity * item.price).toFixed(2)}
                  </TableCell>
                  <TableCell className={calculateItemProfit(item) >= 0 ? "text-green-500" : "text-red-500"}>
                    ${calculateItemProfit(item).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="ghost" 
                      size="icon" 
                      className="text-red-500 hover:text-red-600 h-7 w-7"
                      onClick={() => removeItem(index)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {/* Search for items */}
      <div className="space-y-2">
        <Label>Add Inventory Items</Label>
        <div className="relative">
          <Search className="absolute left-3 top-1/2 h-4 w-4 text-gray-400 -translate-y-1/2" />
          <Input
            placeholder="Search inventory items..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10"
          />
        </div>
      </div>

      {/* Inventory Items */}
      <div className="max-h-48 overflow-y-auto border rounded-md">
        {isLoading ? (
          <div className="p-4 text-center text-gray-400">Loading inventory...</div>
        ) : availableItems.length === 0 ? (
          <div className="p-4 text-center text-gray-400">
            {searchTerm ? "No items match your search" : "No items available"}
          </div>
        ) : (
          <Table>
            <TableHeader className="sticky top-0">
              <TableRow>
                <TableHead>Product</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Stock</TableHead>
                <TableHead>Cost</TableHead>
                <TableHead className="w-[80px]"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {availableItems.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className="font-medium">
                    {item.name}
                  </TableCell>
                  <TableCell>
                    {item.category || "Uncategorized"}
                  </TableCell>
                  <TableCell>
                    {item.quantity || 0}
                  </TableCell>
                  <TableCell>
                    ${Number(item.cost_basis || 0).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-8 w-8 p-0"
                      onClick={() => addItemToSelection(item)}
                      disabled={(item.quantity || 0) <= 0}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};
