
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, BarChart, Bar, Legend
} from "recharts";
import { PlatformDistributionChart } from "./charts/PlatformDistributionChart";
import { SalesBreakdownTable } from "./tables/SalesBreakdownTable";

export const SalesAnalytics = () => {
  const { data: monthlySales, isLoading: monthlySalesLoading } = useQuery({
    queryKey: ['analytics-monthly-sales'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('streams')
        .select('start_time, total_sales');
      
      if (error) throw error;
      
      // Group sales by month
      const salesByMonth = data.reduce((acc: Record<string, number>, stream) => {
        const date = new Date(stream.start_time);
        const monthYear = date.toLocaleString('default', { month: 'short', year: 'numeric' });
        
        if (!acc[monthYear]) acc[monthYear] = 0;
        acc[monthYear] += Number(stream.total_sales) || 0;
        
        return acc;
      }, {});
      
      // Convert to array for recharts
      return Object.entries(salesByMonth).map(([month, sales]) => ({
        month,
        sales
      })).sort((a, b) => {
        // Sort by date (assuming format "MMM YYYY")
        const dateA = new Date(a.month);
        const dateB = new Date(b.month);
        return dateA.getTime() - dateB.getTime();
      });
    }
  });

  const { data: salesByPlatform, isLoading: platformSalesLoading } = useQuery({
    queryKey: ['analytics-platform-sales'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('streams')
        .select('platform, total_sales');
      
      if (error) throw error;
      
      // Group sales by platform
      const salesByPlatform = data.reduce((acc: Record<string, number>, stream) => {
        const platform = stream.platform || 'Other';
        
        if (!acc[platform]) acc[platform] = 0;
        acc[platform] += Number(stream.total_sales) || 0;
        
        return acc;
      }, {});
      
      // Convert to array for recharts
      return Object.entries(salesByPlatform).map(([platform, value]) => ({
        platform,
        value
      }));
    }
  });

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card className="bg-dark-card border-dark-border">
          <CardHeader>
            <CardTitle className="text-lg font-medium text-gray-400">
              Monthly Sales Trend
            </CardTitle>
          </CardHeader>
          <CardContent className="h-[400px]">
            {monthlySalesLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={monthlySales || []}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                  <XAxis dataKey="month" stroke="#666" />
                  <YAxis stroke="#666" />
                  <Tooltip 
                    contentStyle={{ backgroundColor: '#222', border: '1px solid #444' }}
                    labelStyle={{ color: '#ddd' }}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="sales" 
                    stroke="#8884d8" 
                    activeDot={{ r: 8 }} 
                    name="Sales ($)"
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>

        <PlatformDistributionChart data={salesByPlatform} isLoading={platformSalesLoading} />
      </div>

      <SalesBreakdownTable />
    </div>
  );
};
