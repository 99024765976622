import { useState } from "react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { SelectedItemData, StreamFormData } from "../types/stream";
import { Tables } from "@/integrations/supabase/types";

type InventoryItem = Tables<"inventory_items">;

export const useStreamForm = (inventoryItems: InventoryItem[] = [], onSuccess: () => void) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [startTime, setStartTime] = useState("12:00");
  const [endTime, setEndTime] = useState("13:00");
  const [selectedItems, setSelectedItems] = useState<SelectedItemData[]>([]);
  const [formData, setFormData] = useState<StreamFormData>({
    platform: "",
    streamer: "",
    sorter: "",
    totalSales: "",
  });

  const handleInitialItemSelect = () => {
    if (inventoryItems) {
      const initialItems = inventoryItems.map(item => ({
        itemId: item.id,
        quantity: 0,
        salePrice: Number(item.cost_basis) || 0,
        name: item.name,
        availableQuantity: item.quantity || 0
      }));
      setSelectedItems(initialItems);
    }
  };

  const handleItemSelect = (itemId: string, quantity: number, salePrice: number) => {
    const item = inventoryItems.find(i => i.id === itemId);
    if (!item) return;

    setSelectedItems(prev => {
      const existing = prev.find(i => i.itemId === itemId);
      if (existing) {
        return prev.map(i =>
          i.itemId === itemId
            ? { ...i, quantity, salePrice }
            : i
        );
      }
      return [...prev, {
        itemId,
        quantity,
        salePrice,
        name: item.name,
        availableQuantity: item.quantity || 0
      }];
    });
  };

  const handleItemRemove = (itemId: string) => {
    setSelectedItems(prev => prev.filter(item => item.itemId !== itemId));
  };

  const createNewStream = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!startDate) {
      toast.error("Please select a start date");
      return;
    }

    if (selectedItems.length === 0) {
      toast.error("Please select at least one item");
      return;
    }

    if (!formData.totalSales) {
      toast.error("Please enter total sales amount");
      return;
    }

    setIsSubmitting(true);
    try {
      // Get current user and their organization
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data: profile } = await supabase
        .from('profiles')
        .select('organization_id')
        .eq('id', user.id)
        .single();

      if (!profile?.organization_id) {
        throw new Error('No organization found');
      }

      // Combine date and time for start
      const startDateTime = new Date(startDate);
      const [startHours, startMinutes] = startTime.split(':');
      startDateTime.setHours(parseInt(startHours), parseInt(startMinutes));

      // Combine date and time for end if available
      let endDateTime = undefined;
      if (endDate) {
        endDateTime = new Date(endDate);
        const [endHours, endMinutes] = endTime.split(':');
        endDateTime.setHours(parseInt(endHours), parseInt(endMinutes));
      }

      // Create stream with organization_id
      const { data: stream, error: streamError } = await supabase
        .from("streams")
        .insert({
          platform: formData.platform,
          streamer: formData.streamer,
          start_time: startDateTime.toISOString(),
          end_time: endDateTime?.toISOString() || null,
          status: "active",
          user_id: user.id,
          organization_id: profile.organization_id,
          sorter: formData.sorter,
          total_sales: Number(formData.totalSales),
        })
        .select()
        .single();

      if (streamError) throw streamError;

      // Create stream items with their individual sale prices
      const streamItems = selectedItems
        .filter(item => item.quantity > 0)
        .map(item => ({
          stream_id: stream.id,
          inventory_item_id: item.itemId,
          quantity_sold: item.quantity,
          sale_price: item.salePrice,
        }));

      if (streamItems.length > 0) {
        const { error: itemsError } = await supabase
          .from("stream_items")
          .insert(streamItems);

        if (itemsError) throw itemsError;

        // Update inventory quantities for the organization
        for (const item of selectedItems) {
          if (item.quantity > 0) {
            // Get the current inventory item to ensure we have the latest quantity
            const { data: currentItem, error: fetchError } = await supabase
              .from("inventory_items")
              .select("quantity")
              .eq("id", item.itemId)
              .eq("organization_id", profile.organization_id)
              .single();

            if (fetchError) {
              console.error('Error fetching current inventory:', fetchError);
              throw fetchError;
            }

            const newQuantity = (currentItem.quantity || 0) - item.quantity;

            // Update inventory item with organization context
            const { error: updateError } = await supabase
              .from("inventory_items")
              .update({
                quantity: newQuantity,
                status: newQuantity === 0 ? 'archived' : 'active'
              })
              .eq("id", item.itemId)
              .eq("organization_id", profile.organization_id);

            if (updateError) {
              console.error('Error updating inventory:', updateError);
              throw updateError;
            }
          }
        }
      }

      toast.success("Stream created successfully");
      onSuccess();

      // Reset form
      setSelectedItems([]);
      setStartDate(undefined);
      setEndDate(undefined);
      setStartTime("12:00");
      setEndTime("13:00");
      setFormData({
        platform: "",
        streamer: "",
        sorter: "",
        totalSales: "",
      });
    } catch (error) {
      console.error("Error creating stream:", error);
      toast.error("Failed to create stream");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    startDate,
    endDate,
    startTime,
    endTime,
    selectedItems,
    formData,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setFormData,
    handleInitialItemSelect,
    handleItemSelect,
    handleItemRemove,
    createNewStream,
  };
};