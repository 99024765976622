import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Switch } from "@/components/ui/switch";
import { toast } from "sonner";

interface RolesManagementCardProps {
  organizationId: string | undefined;
}

export const RolesManagementCard = ({ organizationId }: RolesManagementCardProps) => {
  const { data: users, error: usersError } = useQuery({
    queryKey: ['organization-users', organizationId],
    enabled: !!organizationId,
    queryFn: async () => {
      console.log('Fetching users for roles management, organization:', organizationId);
      const { data: { user } } = await supabase.auth.getUser();
      console.log('Current user:', user?.id);

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('organization_id', organizationId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching users for roles:', error);
        throw error;
      }
      
      console.log('Fetched users for roles:', data);
      return data;
    },
  });

  const { data: settings, error: settingsError, refetch: refetchSettings } = useQuery({
    queryKey: ['organization-settings', organizationId],
    enabled: true, // Always enabled since we need user settings regardless of org
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      console.log('Fetching settings for user:', user.id);

      let query = supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id);

      // Handle organization_id filter properly for null and non-null cases
      if (organizationId) {
        query = query.eq('organization_id', organizationId);
      } else {
        query = query.is('organization_id', null);
      }

      const { data, error } = await query.maybeSingle();

      if (error && error.code !== 'PGRST116') {
        console.error('Error fetching settings:', error);
        throw error;
      }
      
      console.log('Fetched settings:', data);
      return data || {
        streamers_view_all: false,
        user_id: user.id,
        organization_id: organizationId
      };
    },
  });

  if (usersError || settingsError) {
    console.error('Errors:', { usersError, settingsError });
    return (
      <Card className="bg-dark-card border-dark-border">
        <CardContent>
          <div className="p-4 text-red-500">
            Error loading roles data. Please try again.
            {usersError instanceof Error && (
              <pre className="mt-2 text-sm">{usersError.message}</pre>
            )}
            {settingsError instanceof Error && (
              <pre className="mt-2 text-sm">{settingsError.message}</pre>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }

  const roleDescriptions = {
    admin: "Full access to all organization settings and user management",
    manager: "Can manage inventory and streams",
    streamer: "Can view inventory and manage streams",
    user: "Basic access to view and participate",
  };

  const handleStreamerViewToggle = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      console.log('Updating streamer view settings for user:', user.id);

      const { error } = await supabase
        .from('user_settings')
        .upsert({
          user_id: user.id,
          organization_id: organizationId,
          streamers_view_all: !settings?.streamers_view_all,
        }, {
          onConflict: 'user_id,organization_id'
        });

      if (error) throw error;

      await refetchSettings();
      toast.success('Streamer view settings updated');
    } catch (error) {
      console.error('Error updating streamer view settings:', error);
      toast.error('Failed to update streamer view settings');
    }
  };

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader>
        <CardTitle className="text-xl font-semibold text-white">Role Management</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="flex items-center space-x-4 p-4 bg-dark-muted rounded-lg">
            <div className="flex-1">
              <h3 className="text-lg font-medium text-white">Streamer View Access</h3>
              <p className="text-sm text-gray-400">Allow streamers to view all organization streams</p>
            </div>
            <Switch
              checked={settings?.streamers_view_all || false}
              onCheckedChange={handleStreamerViewToggle}
            />
          </div>

          <div className="grid gap-4">
            {Object.entries(roleDescriptions).map(([role, description]) => (
              <div 
                key={role} 
                className="p-4 bg-dark-muted rounded-lg"
              >
                <h3 className="text-lg font-medium text-white capitalize">{role}</h3>
                <p className="text-gray-400 mt-1">{description}</p>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Users with this role: {users?.filter(user => user.role === role).length || 0}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};