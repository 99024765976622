import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tables } from "@/integrations/supabase/types";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { StreamFinancialBreakdown } from "./StreamFinancialBreakdown";
import { StreamDetailsHeader } from "./details/StreamDetailsHeader";
import { StreamItemsList } from "./details/StreamItemsList";

type Stream = Tables<"streams">;

interface StreamDetailsDialogProps {
  stream: Stream | null;
  onClose: () => void;
}

export const StreamDetailsDialog = ({ stream, onClose }: StreamDetailsDialogProps) => {
  const { data: settings } = useQuery({
    queryKey: ['user-settings'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      // Get user's profile to get organization_id
      const { data: profile } = await supabase
        .from('profiles')
        .select('organization_id')
        .eq('id', user.id)
        .single();

      if (!profile) throw new Error('Profile not found');

      // Query settings with both user_id and organization_id
      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', user.id)
        .eq('organization_id', profile.organization_id)
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error;
      
      return data || {
        tiktok_fee: 0,
        fanatics_fee: 0,
        shipping_cost_type: 'disabled',
        shipping_cost_value: 0,
        streamer_pay_type: 'disabled',
        streamer_pay_value: 0
      };
    },
  });

  const { data: streamItems } = useQuery({
    queryKey: ['stream-items', stream?.id],
    enabled: !!stream,
    queryFn: async () => {
      if (!stream) return [];
      const { data, error } = await supabase
        .from('stream_items')
        .select(`
          *,
          inventory_item:inventory_items(*)
        `)
        .eq('stream_id', stream.id);

      if (error) throw error;
      return data;
    },
  });

  if (!stream) return null;

  return (
    <Dialog open={!!stream} onOpenChange={onClose}>
      <DialogContent className="bg-dark-card text-white border-dark-border max-w-2xl">
        <DialogHeader>
          <DialogTitle>Stream Details</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-6">
          <StreamDetailsHeader stream={stream} />

          <div>
            <h3 className="text-xl font-semibold mb-4">Financial Breakdown</h3>
            <StreamFinancialBreakdown 
              stream={stream}
              streamItems={streamItems}
              settings={settings}
            />
          </div>

          <StreamItemsList items={streamItems} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
