
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SalesAnalytics } from "./SalesAnalytics";
import { InventoryAnalytics } from "./InventoryAnalytics";
import { PerformanceAnalytics } from "./PerformanceAnalytics";

export const AnalyticsDashboard = () => {
  return (
    <Tabs defaultValue="sales" className="space-y-6">
      <TabsList className="bg-dark-card border border-dark-border">
        <TabsTrigger
          value="sales"
          className="text-gray-400 data-[state=active]:bg-dark-muted data-[state=active]:text-white"
        >
          Sales
        </TabsTrigger>
        <TabsTrigger
          value="inventory"
          className="text-gray-400 data-[state=active]:bg-dark-muted data-[state=active]:text-white"
        >
          Inventory
        </TabsTrigger>
        <TabsTrigger
          value="performance"
          className="text-gray-400 data-[state=active]:bg-dark-muted data-[state=active]:text-white"
        >
          Performance
        </TabsTrigger>
      </TabsList>

      <TabsContent value="sales" className="space-y-6">
        <SalesAnalytics />
      </TabsContent>

      <TabsContent value="inventory" className="space-y-6">
        <InventoryAnalytics />
      </TabsContent>

      <TabsContent value="performance" className="space-y-6">
        <PerformanceAnalytics />
      </TabsContent>
    </Tabs>
  );
};
