import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { ProductItem, ProductData } from "./ProductItem";
import { Package } from "lucide-react";

interface ProductsDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  products: ProductData[];
  sortByValue: boolean;
  setSortByValue: (value: boolean) => void;
}

export const ProductsDialog = ({
  isOpen,
  onOpenChange,
  products,
  sortByValue,
  setSortByValue
}: ProductsDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="bg-dark-card border-dark-border text-white">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold flex items-center gap-2">
            <Package className="h-5 w-5" />
            Top 10 Best Selling Products
          </DialogTitle>
          <DialogDescription className="text-gray-400">
            View your best performing products by quantity or total value
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2 mb-6">
          <Switch
            id="sort-mode"
            checked={sortByValue}
            onCheckedChange={setSortByValue}
          />
          <Label htmlFor="sort-mode" className="text-gray-400">
            Sort by {sortByValue ? 'Total Value' : 'Quantity Sold'}
          </Label>
        </div>
        <div className="space-y-6">
          {products.map((product, index) => (
            <ProductItem key={product.name} product={product} rank={index + 1} />
          ))}
          {products.length === 0 && (
            <p className="text-gray-400">No products data available</p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};