import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CalendarDays } from "lucide-react";

export type TimeRange = 'current_month' | 'last_7_days' | 'last_30_days' | 'all_time';

interface TimeRangeSelectorProps {
  selectedRange: TimeRange;
  onRangeChange: (range: TimeRange) => void;
}

export const TimeRangeSelector = ({ selectedRange, onRangeChange }: TimeRangeSelectorProps) => {
  const ranges = {
    current_month: 'Current Month',
    last_7_days: 'Last 7 Days',
    last_30_days: 'Last 30 Days',
    all_time: 'All Time',
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          size="sm"
          className="h-8 text-gray-400 hover:text-white"
        >
          <CalendarDays className="h-4 w-4 mr-2" />
          {ranges[selectedRange]}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="bg-dark-card border-dark-border">
        {Object.entries(ranges).map(([value, label]) => (
          <DropdownMenuItem
            key={value}
            onClick={() => onRangeChange(value as TimeRange)}
            className={`${
              selectedRange === value ? 'bg-primary/20' : ''
            } cursor-pointer`}
          >
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};