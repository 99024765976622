
import { useState } from "react";
import { useForm } from "react-hook-form";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { rolePermissions } from "@/components/settings/types/permissions";

interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const useSignUpForm = (onClose: () => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormData>();

  const onSubmit = async (data: SignUpFormData) => {
    try {
      setIsLoading(true);
      console.log('Starting signup process...');

      // 1. Create auth user
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          data: {
            first_name: data.firstName,
            last_name: data.lastName,
          },
        },
      });

      if (authError) throw authError;
      if (!authData.user) throw new Error('No user data returned');

      console.log('Auth user created successfully');

      // 2. Create or get organization
      const { data: orgData, error: orgError } = await supabase
        .from('organizations')
        .insert([
          {
            name: `${data.firstName}'s Organization`,
            owner_id: authData.user.id,
          },
        ])
        .select()
        .single();

      if (orgError && !orgError.message.includes('duplicate key value')) throw orgError;
      console.log('Organization created/retrieved successfully');

      // 3. Create or update profile
      // For organization owners/creators, they still get admin role
      const { error: profileError } = await supabase
        .from('profiles')
        .upsert({
          id: authData.user.id,
          first_name: data.firstName,
          last_name: data.lastName,
          username: data.email,
          organization_id: orgData.id,
          role: 'admin', // Organization creators still get admin role
        });

      if (profileError) throw profileError;
      console.log('Profile created/updated successfully');

      // Note: We don't need to create user_settings anymore as it's handled by the database trigger

      toast.success("Account created successfully!");
      onClose();
    } catch (error) {
      console.error('Signup error:', error);
      toast.error(error.message || "Failed to create account");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    isLoading,
    onSubmit,
  };
};
