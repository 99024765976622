
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SidebarProvider } from "@/components/ui/sidebar";
import Settings from './pages/Settings';
import App from './App.tsx';
import './index.css';

// Create routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        async lazy() {
          const { default: Component } = await import("./pages/Index");
          return { Component };
        },
      },
      {
        path: "/login",
        async lazy() {
          const { default: Component } = await import("./pages/Login");
          return { Component };
        },
      },
      {
        path: "/inventory",
        async lazy() {
          const { default: Component } = await import("./pages/Inventory");
          return { Component };
        },
      },
      {
        path: "/inventory-check",
        async lazy() {
          const { default: Component } = await import("./pages/InventoryCheck");
          return { Component };
        },
      },
      {
        path: "/streams",
        async lazy() {
          const { default: Component } = await import("./pages/Streams");
          return { Component };
        },
      },
      {
        path: "/wholesale",
        async lazy() {
          const { default: Component } = await import("./pages/Wholesale");
          return { Component };
        },
      },
      {
        path: "/settings", 
        async lazy() {
          const { default: Component } = await import("./pages/Settings");
          return { Component };
        },
      },
      {
        path: "/analytics",
        async lazy() {
          const { default: Component } = await import("./pages/Analytics");
          return { Component };
        },
      }
    ]
  }
]);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={queryClient}>
    <SidebarProvider>
      <RouterProvider router={router} />
    </SidebarProvider>
  </QueryClientProvider>
);
