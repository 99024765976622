import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || 'https://vlwtzunqagybwdcsmjgh.supabase.co';
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZsd3R6dW5xYWd5YndkY3NtamdoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQwMDk0ODMsImV4cCI6MjA0OTU4NTQ4M30.FFr67Zw2ya4NrFJjF7KadN9N5murIdYl_wJnRjgLLPQ';
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});