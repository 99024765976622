
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, Legend 
} from "recharts";

export const StreamerPerformanceChart = () => {
  const { data: streamerData, isLoading } = useQuery({
    queryKey: ['analytics-streamer-performance'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('streams')
        .select(`
          streamer,
          total_sales,
          stream_items (
            quantity_sold,
            inventory_item:inventory_items (
              cost_basis
            )
          )
        `);
      
      if (error) throw error;
      
      // Calculate metrics by streamer
      const streamerPerformance = data.reduce((acc: Record<string, any>, stream: any) => {
        const streamer = stream.streamer || 'Unknown';
        
        if (!acc[streamer]) {
          acc[streamer] = {
            streamer,
            streams: 0,
            totalSales: 0,
            avgSales: 0,
            costs: 0,
            profit: 0
          };
        }
        
        const productCosts = stream.stream_items.reduce((sum: number, item: any) => {
          const costBasis = Number(item.inventory_item?.cost_basis) || 0;
          return sum + ((Number(item.quantity_sold) || 0) * costBasis);
        }, 0);
        
        acc[streamer].streams += 1;
        acc[streamer].totalSales += Number(stream.total_sales) || 0;
        acc[streamer].costs += productCosts;
        acc[streamer].profit += (Number(stream.total_sales) || 0) - productCosts;
        
        return acc;
      }, {});
      
      // Calculate averages and convert to array
      return Object.values(streamerPerformance).map((data: any) => ({
        ...data,
        avgSales: data.totalSales / data.streams
      })).sort((a: any, b: any) => b.profit - a.profit).slice(0, 5);
    }
  });

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader>
        <CardTitle className="text-lg font-medium text-gray-400">
          Top Streamer Performance
        </CardTitle>
      </CardHeader>
      <CardContent className="h-[400px]">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart 
              data={streamerData || []}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#333" />
              <XAxis dataKey="streamer" stroke="#666" />
              <YAxis stroke="#666" />
              <Tooltip 
                contentStyle={{ backgroundColor: '#222', border: '1px solid #444' }}
                labelStyle={{ color: '#ddd' }}
              />
              <Legend />
              <Bar dataKey="totalSales" fill="#8884d8" name="Total Sales" />
              <Bar dataKey="profit" fill="#82ca9d" name="Profit" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};
