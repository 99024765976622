import { AppLayout } from "@/components/layout/AppLayout";
import { InventoryCheckList } from "@/components/inventory-check/InventoryCheckList";
import { InventoryCheckHistory } from "@/components/inventory-check/InventoryCheckHistory";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const InventoryCheck = () => {
  const navigate = useNavigate();

  return (
    <AppLayout>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate(-1)}
              className="text-white hover:text-white"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <h1 className="text-2xl font-bold text-white">Inventory Check</h1>
          </div>
        </div>

        <Tabs defaultValue="check" className="space-y-6">
          <TabsList className="bg-dark-card border-dark-border">
            <TabsTrigger 
              value="check" 
              className="text-white data-[state=active]:bg-dark-muted data-[state=active]:text-white"
            >
              New Check
            </TabsTrigger>
            <TabsTrigger 
              value="history" 
              className="text-white data-[state=active]:bg-dark-muted data-[state=active]:text-white"
            >
              History
            </TabsTrigger>
          </TabsList>

          <TabsContent value="check" className="space-y-6">
            <InventoryCheckList />
          </TabsContent>

          <TabsContent value="history">
            <InventoryCheckHistory />
          </TabsContent>
        </Tabs>
      </div>
    </AppLayout>
  );
};

export default InventoryCheck;