import { Tables } from "@/integrations/supabase/types";

type Stream = Tables<"streams">;
type StreamItem = {
  quantity_sold: number | null;
  inventory_item: {
    cost_basis: number | null;
  } | null;
};

interface FinancialCalculationParams {
  totalSales: number;
  productCosts: number;
  platform: string;
  settings: {
    tiktok_fee?: number | null;
    fanatics_fee?: number | null;
    shipping_cost_type?: string | null;
    shipping_cost_value?: number | null;
    streamer_pay_type?: string | null;
    streamer_pay_value?: number | null;
    sorter_pay_type?: string | null;
    sorter_pay_value?: number | null;
  } | null;
  customPlatforms?: {
    name: string;
    fee: number | null;
  }[] | null;
  streamDurationHours?: number;
}

interface StreamFinancials {
  grossProfit: number;
  platformFee: number;
  platformFeePercentage: number;
  shippingCost: number;
  streamerPay: number;
  sorterPay: number;
  netProfit: number;
  productCosts: number;
  intermediateProfit: number;
}

export const calculateStreamFinancials = ({
  totalSales,
  productCosts,
  platform,
  settings,
  customPlatforms,
  streamDurationHours = 0,
}: FinancialCalculationParams): StreamFinancials => {
  // Step 1: Calculate Gross Profit
  const grossProfit = totalSales - productCosts;

  // Calculate platform fee
  let platformFeePercentage = 0;
  if (platform === 'tiktok') {
    platformFeePercentage = settings?.tiktok_fee || 0;
  } else if (platform === 'fanatics') {
    platformFeePercentage = settings?.fanatics_fee || 0;
  } else if (customPlatforms) {
    const customPlatform = customPlatforms.find(
      p => p.name.toLowerCase() === platform.toLowerCase()
    );
    platformFeePercentage = customPlatform?.fee || 0;
  }
  
  const platformFee = (totalSales * platformFeePercentage) / 100;

  // Calculate shipping cost
  let shippingCost = 0;
  if (settings?.shipping_cost_type === 'fixed') {
    shippingCost = settings.shipping_cost_value || 0;
  } else if (settings?.shipping_cost_type === 'percentage') {
    shippingCost = (totalSales * (settings.shipping_cost_value || 0)) / 100;
  }

  // Calculate sorter pay (hourly rate * stream duration)
  let sorterPay = 0;
  if (settings?.sorter_pay_type === 'fixed') {
    sorterPay = (settings.sorter_pay_value || 0) * streamDurationHours;
  }

  // Step 2: Calculate Intermediate Profit (after platform fees, shipping, and sorter pay)
  const intermediateProfit = grossProfit - platformFee - shippingCost - sorterPay;

  // Step 3: Calculate Streamer Pay based on settings
  let streamerPay = 0;
  if (settings?.streamer_pay_type === 'percentage') {
    // Percentage of total sales
    streamerPay = totalSales * (settings.streamer_pay_value || 0) / 100;
  } else if (settings?.streamer_pay_type === 'profit_percentage') {
    // Percentage of intermediate profit
    streamerPay = intermediateProfit * (settings.streamer_pay_value || 0) / 100;
  }

  // Step 4: Calculate Final Net Profit
  const netProfit = intermediateProfit - streamerPay;

  return {
    grossProfit,
    platformFee,
    platformFeePercentage,
    shippingCost,
    streamerPay,
    sorterPay,
    netProfit,
    productCosts,
    intermediateProfit
  };
};

export const calculateProductCost = (streamItems: StreamItem[] | null) => {
  if (!streamItems) return 0;
  return streamItems.reduce((sum, item) => {
    const costBasis = item.inventory_item?.cost_basis || 0;
    return sum + (Number(costBasis) * (item.quantity_sold || 0));
  }, 0);
};

export const calculateTransactionFee = (
  stream: Stream,
  settings: { tiktok_fee?: number | null; fanatics_fee?: number | null } | null
) => {
  if (!settings) return 0;
  
  const totalSales = Number(stream.total_sales) || 0;
  let feePercentage = 0;

  if (stream.platform === 'tiktok') {
    feePercentage = settings.tiktok_fee || 0;
  } else if (stream.platform === 'fanatics') {
    feePercentage = settings.fanatics_fee || 0;
  }

  return (totalSales * feePercentage) / 100;
};

export const calculateNetProfit = (
  stream: Stream,
  productCosts: number,
  transactionFee: number
) => {
  const totalSales = Number(stream.total_sales) || 0;
  return totalSales - productCosts - transactionFee;
};