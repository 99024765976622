import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

interface LandingHeroProps {
  onGetStarted: () => void;
  onSignIn: () => void;
}

export const LandingHero = ({ onGetStarted, onSignIn }: LandingHeroProps) => {
  return (
    <div className="relative z-10 min-h-[80vh] flex flex-col justify-center items-center text-center px-4 lg:px-6 container mx-auto max-w-[1920px]">
      <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 md:mb-8">Say goodbye to spreadsheets.</h1>
      <p className="text-xl md:text-2xl text-gray-400 max-w-3xl mx-auto mb-8 md:mb-12">
        Built by Streamers for Streamers, CardQuant simplifies your sports card breaking business with an intuitive, all-in-one platform.
      </p>
      <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
        <Button
          onClick={onGetStarted}
          className="bg-white text-black hover:bg-gray-200 text-lg px-6 md:px-8 py-5 md:py-6 w-full sm:w-auto"
        >
          Get Started <ArrowRight className="ml-2 h-5 w-5" />
        </Button>
        <Button
          variant="outline"
          onClick={onSignIn}
          className="border-gray-600 hover:bg-gray-800 text-lg px-6 md:px-8 py-5 md:py-6 w-full sm:w-auto"
        >
          Sign In
        </Button>
      </div>
    </div>
  );
};