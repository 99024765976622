import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { useState } from "react";
import { Tables } from "@/integrations/supabase/types";
import { SelectedItemData } from "../types/stream";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CategoryGroup } from "./inventory-selector/CategoryGroup";
import { SelectedItems } from "./inventory-selector/SelectedItems";

type InventoryItem = Tables<"inventory_items">;

interface StreamInventorySelectorProps {
  inventoryItems?: InventoryItem[];
  selectedItems: SelectedItemData[];
  onItemSelect: (itemId: string, quantity: number, salePrice: number) => void;
  onItemRemove: (itemId: string) => void;
  onInitialSelect: () => void;
}

export const StreamInventorySelector = ({
  inventoryItems = [],
  selectedItems,
  onItemSelect,
  onItemRemove,
}: StreamInventorySelectorProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({});

  // Filter and sort available items
  const availableItems = inventoryItems
    .filter(item => {
      // First, exclude category markers and archived items
      if (item.status !== 'active') {
        return false;
      }

      // Check if item name matches search term
      const matchesSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase());

      // Check if item is not already selected
      const isNotSelected = !selectedItems.some(selected => selected.itemId === item.id);

      // Check if item has available quantity
      const hasQuantity = typeof item.quantity === 'number' && item.quantity > 0;

      return matchesSearch && isNotSelected && hasQuantity;
    })
    .sort((a, b) => {
      // First sort by category
      if (a.category && b.category) {
        return a.category.localeCompare(b.category);
      }
      if (a.category) return -1;
      if (b.category) return 1;

      // Then by name
      return a.name.localeCompare(b.name);
    });

  const handleAddItem = (item: InventoryItem) => {
    // Initialize with quantity 1 and cost_basis as the default sale price
    onItemSelect(
      item.id,
      1, // Set initial quantity to 1
      Number(item.cost_basis) || 0
    );
  };

  // Group items by category
  const groupedItems = availableItems.reduce((acc, item) => {
    const category = item.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {} as Record<string, InventoryItem[]>);

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-white">Selected Items</h3>

      {/* Search and Available Items */}
      <div className="space-y-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
          <Input
            placeholder="Search inventory..."
            className="pl-10 bg-dark-card border-dark-border text-white placeholder:text-gray-400"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Available Items List */}
        <div className="space-y-2">
          <h4 className="text-md font-medium text-white">Available Items</h4>
          {Object.keys(groupedItems).length === 0 ? (
            <div className="p-4 text-center text-gray-400 bg-dark-card border border-dark-border rounded-lg">
              No active items with available quantity found
            </div>
          ) : (
            <ScrollArea className="h-[300px] rounded-md border border-dark-border">
              <div className="divide-y divide-dark-border">
                {Object.entries(groupedItems).map(([category, items]) => (
                  <CategoryGroup
                    key={category}
                    category={category}
                    items={items}
                    isExpanded={expandedCategories[category]}
                    onToggle={toggleCategory}
                    onAddItem={handleAddItem}
                  />
                ))}
              </div>
            </ScrollArea>
          )}
        </div>

        {/* Selected Items List */}
        <SelectedItems
          selectedItems={selectedItems}
          inventoryItems={inventoryItems}
          onItemSelect={onItemSelect}
          onItemRemove={onItemRemove}
        />
      </div>
    </div>
  );
};