import { InventoryItem } from "@/hooks/useInventoryData";

export const calculateInventoryValues = (items: InventoryItem[]) => {
  console.log('Calculating values for items:', items?.length || 0);
  
  const totalInventoryValue = items.reduce((sum, item) => {
    // Check if item is a category marker using string comparison
    if (item.status === 'category_marker') return sum;
    
    const quantity = Number(item.quantity) || 0;
    const costBasis = Number(item.cost_basis) || 0;
    const itemValue = quantity * costBasis;
    console.log(`Item ${item.name}: quantity=${quantity}, cost=${costBasis}, value=${itemValue}`);
    return sum + itemValue;
  }, 0);

  const awaitingInventoryValue = items
    .filter(item => item.status === 'awaiting')
    .reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const costBasis = Number(item.cost_basis) || 0;
      const itemValue = quantity * costBasis;
      console.log(`Awaiting item ${item.name}: value=${itemValue}`);
      return sum + itemValue;
    }, 0);

  console.log('Final calculations:', { totalInventoryValue, awaitingInventoryValue });
  return {
    totalInventoryValue,
    awaitingInventoryValue
  };
};

// Updated to handle category markers safely
export const calculateCategoryValue = (items: InventoryItem[] = [], status: 'active' | 'archived' = 'active') => {
  if (!items?.length) return 0;
  
  return items.reduce((sum, item) => {
    // Skip items that don't match the current status filter
    if (item.status !== status) return sum;
    
    // Skip category markers - checking if it's a category marker without direct type comparison
    // The 'as string' cast tells TypeScript we know what we're doing with this comparison
    if ((item.status as string) === 'category_marker') return sum;
    
    const quantity = Number(item.quantity) || 0;
    const costBasis = Number(item.cost_basis) || 0;
    return sum + (quantity * costBasis);
  }, 0);
};
