import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Clock } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useProfile } from "@/hooks/useProfile";

export const LastCheckCard = () => {
  const { data: profile } = useProfile();

  const { data: lastCheck } = useQuery({
    queryKey: ['lastInventoryCheck', profile?.organization_id],
    enabled: !!profile,
    queryFn: async () => {
      console.log('Fetching last inventory check for organization:', profile?.organization_id);
      
      let query = supabase
        .from('inventory_check_reports')
        .select('*')
        .order('report_date', { ascending: false })
        .limit(1);

      // If user is in an organization, include org's reports
      if (profile?.organization_id) {
        query = query.or(`user_id.eq.${profile.id},organization_id.eq.${profile.organization_id}`);
      } else {
        // Otherwise only show user's personal reports
        query = query.eq('user_id', profile.id);
      }

      const { data: reports, error } = await query;
      
      if (error) {
        console.error('Error fetching last check:', error);
        return null;
      }

      const lastReport = reports?.[0] || null;
      console.log('Last check report:', lastReport);
      return lastReport;
    }
  });

  if (!lastCheck) {
    return (
      <Card className="bg-dark-card border-dark-border">
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-lg font-medium text-gray-400">
            Last Inventory Check
          </CardTitle>
          <Clock className="h-4 w-4 text-gray-400" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold text-white">No checks yet</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="text-lg font-medium text-gray-400">
          Last Inventory Check
        </CardTitle>
        <Clock className="h-4 w-4 text-gray-400" />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold text-white">
          {formatDistanceToNow(new Date(lastCheck.report_date), { 
            addSuffix: true,
            includeSeconds: true 
          })}
        </div>
        <div className="text-sm text-gray-400 mt-1">
          {new Date(lastCheck.report_date).toLocaleDateString()} at {new Date(lastCheck.report_date).toLocaleTimeString()}
        </div>
        <div className="mt-2 space-y-1">
          <p className="text-sm text-gray-400">
            Items checked: {lastCheck.total_items_checked}
          </p>
          <p className="text-sm text-green-500">
            Correct: {lastCheck.correct_items}
          </p>
          {lastCheck.incorrect_items > 0 && (
            <p className="text-sm text-red-500">
              Incorrect: {lastCheck.incorrect_items}
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
