
import { useState } from "react";
import { WholesaleOrder, WholesaleOrderWithDetails, OrderStatus, PaymentStatus } from "./types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { 
  Edit, Trash2, ExternalLink, TruckIcon, CheckCircle, 
  AlertCircle, Clock, DollarSign, Package, ShoppingBag 
} from "lucide-react";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { OrderForm } from "./OrderForm";
import { useWholesaleOrders } from "@/hooks/useWholesaleOrders";
import { useWholesaleCustomers } from "@/hooks/useWholesaleCustomers";
import { format } from "date-fns";
import { OrderDetails } from "./OrderDetails";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";

interface OrdersListProps {
  orders: WholesaleOrderWithDetails[];
  isLoading: boolean;
}

export const OrdersList = ({ orders, isLoading }: OrdersListProps) => {
  const [selectedOrder, setSelectedOrder] = useState<WholesaleOrderWithDetails | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { customers } = useWholesaleCustomers();
  const { updateOrder, deleteOrder } = useWholesaleOrders();

  const handleEdit = (order: WholesaleOrderWithDetails) => {
    setSelectedOrder(order);
    setIsEditDialogOpen(true);
  };

  const handleView = (order: WholesaleOrderWithDetails) => {
    setSelectedOrder(order);
    setIsViewDialogOpen(true);
  };

  const handleDelete = (order: WholesaleOrderWithDetails) => {
    setSelectedOrder(order);
    setIsDeleteDialogOpen(true);
  };

  const getStatusIcon = (status: OrderStatus) => {
    switch (status) {
      case "confirmed":
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case "shipped":
        return <TruckIcon className="h-4 w-4 text-blue-500" />;
      case "delivered":
        return <Package className="h-4 w-4 text-green-700" />;
      case "cancelled":
        return <AlertCircle className="h-4 w-4 text-red-500" />;
      default:
        return <Clock className="h-4 w-4 text-gray-400" />;
    }
  };

  const getPaymentIcon = (status: PaymentStatus) => {
    switch (status) {
      case "paid":
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case "partial":
        return <DollarSign className="h-4 w-4 text-yellow-500" />;
      case "overdue":
        return <AlertCircle className="h-4 w-4 text-red-500" />;
      default:
        return <Clock className="h-4 w-4 text-gray-400" />;
    }
  };

  const getStatusBadge = (status: OrderStatus) => {
    switch (status) {
      case "draft":
        return <Badge variant="outline" className="text-gray-400 border-gray-500">Draft</Badge>;
      case "confirmed":
        return <Badge className="bg-green-600 hover:bg-green-700">Confirmed</Badge>;
      case "shipped":
        return <Badge className="bg-blue-600 hover:bg-blue-700">Shipped</Badge>;
      case "delivered":
        return <Badge className="bg-green-800 hover:bg-green-900">Delivered</Badge>;
      case "cancelled":
        return <Badge className="bg-red-600 hover:bg-red-700">Cancelled</Badge>;
      default:
        return <Badge variant="outline">Unknown</Badge>;
    }
  };

  const getPaymentBadge = (status: PaymentStatus) => {
    switch (status) {
      case "pending":
        return <Badge variant="outline" className="text-gray-400 border-gray-500">Pending</Badge>;
      case "partial":
        return <Badge className="bg-yellow-600 hover:bg-yellow-700">Partial</Badge>;
      case "paid":
        return <Badge className="bg-green-600 hover:bg-green-700">Paid</Badge>;
      case "overdue":
        return <Badge className="bg-red-600 hover:bg-red-700">Overdue</Badge>;
      default:
        return <Badge variant="outline">Unknown</Badge>;
    }
  };

  if (isLoading) {
    return (
      <Card className="bg-dark-card border-dark-border">
        <CardHeader>
          <CardTitle>Wholesale Orders</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="animate-pulse space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-12 bg-dark-muted rounded-md" />
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card className="bg-dark-card border-dark-border">
        <CardHeader>
          <CardTitle>Wholesale Orders ({orders.length})</CardTitle>
        </CardHeader>
        <CardContent>
          {orders.length === 0 ? (
            <div className="text-center py-6">
              <p className="text-gray-400">No orders found</p>
              <p className="text-sm text-gray-500 mt-1">
                Create your first wholesale order to get started
              </p>
            </div>
          ) : (
            <div className="rounded-md border border-dark-border">
              <Table>
                <TableHeader className="bg-dark-muted">
                  <TableRow>
                    <TableHead className="text-white">Order Date</TableHead>
                    <TableHead className="text-white">Customer</TableHead>
                    <TableHead className="text-white">Status</TableHead>
                    <TableHead className="text-white">Payment</TableHead>
                    <TableHead className="text-white">Total</TableHead>
                    <TableHead className="text-white">Items</TableHead>
                    <TableHead className="text-white text-right">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow 
                      key={order.id} 
                      className="border-dark-border cursor-pointer hover:bg-dark-muted/40"
                      onClick={() => handleView(order)}
                    >
                      <TableCell className="font-medium">
                        {format(new Date(order.order_date), "MMM d, yyyy")}
                      </TableCell>
                      <TableCell>
                        {order.customer?.name || "Unknown"}
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          {getStatusBadge(order.status as OrderStatus)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          {getPaymentBadge(order.payment_status as PaymentStatus)}
                        </div>
                      </TableCell>
                      <TableCell>
                        ${Number(order.total_amount).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-1">
                          <ShoppingBag className="h-4 w-4 text-gray-400" />
                          <span>{order.total_items || 0}</span>
                        </div>
                      </TableCell>
                      <TableCell className="text-right" onClick={(e) => e.stopPropagation()}>
                        <div className="flex justify-end gap-2">
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleView(order);
                            }}
                          >
                            <ExternalLink className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(order);
                            }}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(order);
                            }}
                            className="text-red-500 hover:text-red-600"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>

      {/* View Order Dialog */}
      <Dialog open={isViewDialogOpen} onOpenChange={setIsViewDialogOpen}>
        <DialogContent className="sm:max-w-[900px] max-h-[90vh] overflow-y-auto bg-[#121212] border-gray-800">
          {selectedOrder && (
            <OrderDetails 
              order={selectedOrder} 
              onClose={() => setIsViewDialogOpen(false)}
              onEdit={() => {
                setIsViewDialogOpen(false);
                setIsEditDialogOpen(true);
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Edit Order Dialog */}
      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent className="sm:max-w-[900px] max-h-[90vh] overflow-y-auto bg-[#121212] border-gray-800">
          {selectedOrder && (
            <OrderForm
              order={selectedOrder}
              customers={customers}
              onSubmit={async (data) => {
                await updateOrder.mutateAsync({
                  orderId: selectedOrder.id,
                  orderData: data.order,
                  addItems: data.items,
                  // We'll handle item updates in a more complex way in a real app
                });
                setIsEditDialogOpen(false);
              }}
              onCancel={() => setIsEditDialogOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent className="bg-dark-card border-dark-border">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-white">Confirm Deletion</AlertDialogTitle>
            <AlertDialogDescription className="text-gray-400">
              Are you sure you want to delete this order?
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="border-dark-border text-white">Cancel</AlertDialogCancel>
            <AlertDialogAction
              className="bg-red-500 hover:bg-red-600"
              onClick={async () => {
                if (selectedOrder) {
                  await deleteOrder.mutateAsync(selectedOrder.id);
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
