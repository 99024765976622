interface StreamSummaryMetricProps {
  label: string;
  value: number;
  type?: 'default' | 'positive' | 'negative';
}

export const StreamSummaryMetric = ({ 
  label, 
  value, 
  type = 'default' 
}: StreamSummaryMetricProps) => {
  const getValueColor = () => {
    switch (type) {
      case 'positive':
        return 'text-green-500';
      case 'negative':
        return 'text-red-500';
      default:
        return 'text-white';
    }
  };

  const formattedValue = type === 'negative' ? `-$${Math.abs(value).toLocaleString()}` : `$${value.toLocaleString()}`;

  return (
    <div>
      <h3 className="text-lg text-gray-400">{label}</h3>
      <p className={`text-3xl font-bold ${getValueColor()}`}>
        {formattedValue}
      </p>
    </div>
  );
};