
import { FormItem, FormLabel, FormControl, FormDescription } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";

interface PlatformFeeInputProps {
  platform: {
    id: string;
    name: string;
    fee: number;
  };
  onUpdateFee: (id: string, fee: string) => void;
  onDelete: (id: string) => void;
  isDisabled: boolean;
}

export const PlatformFeeInput = ({
  platform,
  onUpdateFee,
  onDelete,
  isDisabled
}: PlatformFeeInputProps) => {
  return (
    <FormItem>
      <div className="flex items-center gap-2">
        <div className="flex-1">
          <FormLabel className="text-gray-200">{platform.name} Fee (%)</FormLabel>
          <FormControl>
            <Input
              type="number"
              step="0.01"
              defaultValue={platform.fee || 0}
              onChange={(e) => onUpdateFee(platform.id, e.target.value)}
              disabled={isDisabled}
              className="bg-dark-muted text-white border-dark-border"
            />
          </FormControl>
          <FormDescription className="text-gray-400">
            The percentage fee charged by {platform.name} for sales
          </FormDescription>
        </div>
        {!isDisabled && (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button 
                variant="ghost" 
                size="icon"
                className="h-8 w-8 hover:bg-red-500/10 hover:text-red-500"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="bg-dark-card border-dark-border">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-white">Delete Platform</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to delete {platform.name}? This action cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  onClick={() => onDelete(platform.id)}
                  className="bg-red-500 hover:bg-red-600"
                >
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
    </FormItem>
  );
};
