
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useState } from "react";
import { 
  Table, TableBody, TableCell, TableHead, 
  TableHeader, TableRow 
} from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const PerformanceMetricsTable = () => {
  const [sortColumn, setSortColumn] = useState("profitMargin");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const { data: metricsData, isLoading } = useQuery({
    queryKey: ['analytics-performance-metrics'],
    queryFn: async () => {
      // Get streamers and their metrics
      const { data: streamers, error } = await supabase
        .from('streams')
        .select(`
          streamer,
          platform,
          total_sales,
          stream_items (
            quantity_sold,
            inventory_item:inventory_items (
              cost_basis
            )
          )
        `);
      
      if (error) throw error;
      
      // Group by streamer and platform
      const metrics = streamers.reduce((acc: Record<string, any>, stream: any) => {
        const key = `${stream.streamer}-${stream.platform}`;
        
        if (!acc[key]) {
          acc[key] = {
            streamer: stream.streamer,
            platform: stream.platform,
            streamCount: 0,
            totalSales: 0,
            totalCost: 0,
            profit: 0,
            profitMargin: 0,
            itemsSold: 0
          };
        }
        
        const productCost = stream.stream_items.reduce((sum: number, item: any) => {
          const costBasis = Number(item.inventory_item?.cost_basis) || 0;
          return sum + ((Number(item.quantity_sold) || 0) * costBasis);
        }, 0);
        
        const itemsSold = stream.stream_items.reduce((sum: number, item: any) => {
          return sum + (Number(item.quantity_sold) || 0);
        }, 0);
        
        acc[key].streamCount += 1;
        acc[key].totalSales += Number(stream.total_sales) || 0;
        acc[key].totalCost += productCost;
        acc[key].itemsSold += itemsSold;
        acc[key].profit = acc[key].totalSales - acc[key].totalCost;
        acc[key].profitMargin = acc[key].totalSales > 0 
          ? (acc[key].profit / acc[key].totalSales) * 100 
          : 0;
        
        return acc;
      }, {});
      
      return Object.values(metrics);
    }
  });

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedData = metricsData ? [...metricsData].sort((a, b) => {
    const directionModifier = sortDirection === "asc" ? 1 : -1;
    
    if (a[sortColumn as keyof typeof a] < b[sortColumn as keyof typeof b]) {
      return -1 * directionModifier;
    }
    if (a[sortColumn as keyof typeof a] > b[sortColumn as keyof typeof b]) {
      return 1 * directionModifier;
    }
    return 0;
  }) : [];

  return (
    <Card className="bg-dark-card border-dark-border">
      <CardHeader>
        <CardTitle className="text-lg font-medium text-gray-400">
          Performance Metrics by Streamer & Platform
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer"
                  onClick={() => handleSort("streamer")}
                >
                  Streamer
                  {sortColumn === "streamer" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer"
                  onClick={() => handleSort("platform")}
                >
                  Platform
                  {sortColumn === "platform" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("streamCount")}
                >
                  Streams
                  {sortColumn === "streamCount" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("itemsSold")}
                >
                  Items Sold
                  {sortColumn === "itemsSold" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("totalSales")}
                >
                  Total Sales
                  {sortColumn === "totalSales" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("profit")}
                >
                  Profit
                  {sortColumn === "profit" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
                <TableHead 
                  className="text-gray-400 hover:text-white cursor-pointer text-right"
                  onClick={() => handleSort("profitMargin")}
                >
                  Profit Margin
                  {sortColumn === "profitMargin" && (
                    <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-4">
                    <div className="flex justify-center">
                      <div className="animate-pulse h-4 w-24 bg-gray-700 rounded"></div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : sortedData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-4 text-gray-400">
                    No performance data available
                  </TableCell>
                </TableRow>
              ) : (
                sortedData.map((item, index) => (
                  <TableRow key={index} className="border-t border-dark-border">
                    <TableCell className="text-white">{item.streamer}</TableCell>
                    <TableCell className="text-white">{item.platform}</TableCell>
                    <TableCell className="text-white text-right">{item.streamCount}</TableCell>
                    <TableCell className="text-white text-right">{item.itemsSold}</TableCell>
                    <TableCell className="text-white text-right">
                      ${item.totalSales.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell className={`text-right ${item.profit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      ${Math.abs(item.profit).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell className={`text-right ${item.profitMargin >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {item.profitMargin.toFixed(2)}%
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};
