import { StreamBasicInfo } from "./StreamBasicInfo";
import { StreamDateTime } from "./form/StreamDateTime";
import { StreamInventorySelector } from "./form/StreamInventorySelector";
import { Button } from "@/components/ui/button";
import { Tables } from "@/integrations/supabase/types";
import { useStreamForm } from "./hooks/useStreamForm";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { StreamFinancialBreakdown } from "./history/StreamFinancialBreakdown";
import { useEffect, useState } from "react";

type InventoryItem = Tables<"inventory_items">;

interface StreamFormProps {
  onSuccess: () => void;
}

export const StreamForm = ({ onSuccess }: StreamFormProps) => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const {
    isSubmitting,
    startDate,
    endDate,
    startTime,
    endTime,
    selectedItems,
    formData,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setFormData,
    handleInitialItemSelect,
    handleItemSelect,
    handleItemRemove,
    createNewStream,
  } = useStreamForm(inventoryItems, onSuccess);

  // First get the user's profile to get the organization_id
  const { data: profile } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data, error } = await supabase
        .from('profiles')
        .select('*, organizations(id, name)')
        .eq('id', user.id)
        .maybeSingle();
      if (error) throw error;
      return data;
    },
  });

  // Then fetch organization's inventory items and settings
  const { data: organizationItems } = useQuery({
    queryKey: ['organization-inventory', profile?.organization_id],
    enabled: !!profile?.organization_id,
    queryFn: async () => {
      if (!profile?.organization_id) return [];

      const { data, error } = await supabase
        .from('inventory_items')
        .select('*')
        .eq('organization_id', profile.organization_id)
        .eq('status', 'active')
        .gt('quantity', 0);

      if (error) {
        console.error('Error fetching inventory:', error);
        return [];
      }

      return data || [];
    }
  });
  useEffect(() => {
    setInventoryItems(organizationItems);
  }, [organizationItems])
  // Then fetch the organization's settings
  const { data: settings } = useQuery({
    queryKey: ['organization-settings', profile?.organization_id],
    enabled: !!profile?.organization_id,
    queryFn: async () => {
      try {
        if (!profile?.organization_id) {
          throw new Error('No organization ID available');
        }

        // Get all admin profiles for the organization
        const { data: adminProfiles, error: adminError } = await supabase
          .from('profiles')
          .select('id')
          .eq('organization_id', profile.organization_id)
          .eq('role', 'admin');

        if (adminError) {
          console.error('Error fetching admin profiles:', adminError);
          throw adminError;
        }

        let adminSettings = null;
        if (adminProfiles && adminProfiles.length > 0) {
          // Try each admin until we find settings
          for (const adminProfile of adminProfiles) {
            if (!adminProfile.id) continue;

            const { data: settings, error: settingsError } = await supabase
              .from('user_settings')
              .select('*')
              .eq('user_id', adminProfile.id)
              .eq('organization_id', profile.organization_id)
              .maybeSingle();

            if (settingsError) {
              console.error('Error fetching admin settings:', settingsError);
              continue;
            }

            if (settings) {
              adminSettings = settings;
              break;
            }
          }
        }

        // If no admin settings found and we have a valid user ID, try to get user's own settings
        if (!adminSettings && profile.id) {
          const { data: userSettings, error: userSettingsError } = await supabase
            .from('user_settings')
            .select('*')
            .eq('user_id', profile.id)
            .eq('organization_id', profile.organization_id)
            .maybeSingle();

          if (userSettingsError) {
            console.error('Error fetching user settings:', userSettingsError);
          } else {
            adminSettings = userSettings;
          }
        }

        // Return default settings if nothing found
        return adminSettings || {
          tiktok_fee: 0,
          fanatics_fee: 0,
          shipping_cost_type: 'disabled',
          shipping_cost_value: 0,
          streamer_pay_type: 'disabled',
          streamer_pay_value: 0,
          sorter_pay_type: 'disabled',
          sorter_pay_value: 0
        };
      } catch (error) {
        console.error('Error fetching settings:', error);
        return {
          tiktok_fee: 0,
          fanatics_fee: 0,
          shipping_cost_type: 'disabled',
          shipping_cost_value: 0,
          streamer_pay_type: 'disabled',
          streamer_pay_value: 0,
          sorter_pay_type: 'disabled',
          sorter_pay_value: 0
        };
      }
    }
  });

  const handleTotalSalesChange = (value: string) => {
    // Remove any non-numeric characters except decimal point
    const numericValue = value.replace(/[^0-9.]/g, '');

    // Ensure only one decimal point
    const parts = numericValue.split('.');
    const formattedValue = parts.length > 2 ? `${parts[0]}.${parts[1]}` : numericValue;

    setFormData(prev => ({ ...prev, totalSales: formattedValue }));
  };

  // Map selected items to the format expected by StreamFinancialBreakdown
  const mappedStreamItems = selectedItems.map(item => ({
    quantity_sold: item.quantity,
    sale_price: item.salePrice,
    inventory_item: organizationItems?.find(i => i.id === item.itemId)
  }));

  // Use organizationItems instead of inventoryItems prop
  const itemsToShow = organizationItems || [];
  return (
    <form onSubmit={createNewStream} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="md:col-span-2">
          <StreamBasicInfo
            platform={formData.platform}
            streamer={formData.streamer}
            sorter={formData.sorter}
            onPlatformChange={(value) => setFormData(prev => ({ ...prev, platform: value }))}
            onStreamerChange={(value) => setFormData(prev => ({ ...prev, streamer: value }))}
            onSorterChange={(value) => setFormData(prev => ({ ...prev, sorter: value }))}
          />
        </div>
        <div className="space-y-2">
          <Label className="text-sm font-medium text-gray-200">Stream Total Sales</Label>
          <Input
            type="text"
            value={formData.totalSales ? `$${Number(formData.totalSales).toLocaleString('en-US')}` : ''}
            onChange={(e) => handleTotalSalesChange(e.target.value)}
            onBlur={() => {
              if (formData.totalSales) {
                const hasDecimal = formData.totalSales.includes('.');
                const value = hasDecimal ? Number(formData.totalSales) : Number(formData.totalSales);
                setFormData(prev => ({
                  ...prev,
                  totalSales: hasDecimal ? value.toFixed(2) : value.toString()
                }));
              }
            }}
            className="bg-dark-card border-dark-border text-white"
            placeholder="$0"
          />
        </div>
      </div>

      <StreamDateTime
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onStartTimeChange={setStartTime}
        onEndTimeChange={setEndTime}
      />

      <StreamInventorySelector
        inventoryItems={itemsToShow}
        selectedItems={selectedItems}
        onItemSelect={handleItemSelect}
        onItemRemove={handleItemRemove}
        onInitialSelect={handleInitialItemSelect}
      />

      <Card className="bg-dark-card border-dark-border">
        <CardContent className="pt-6">
          <h3 className="text-lg font-semibold text-white mb-4">Financial Breakdown</h3>
          <StreamFinancialBreakdown
            stream={{
              total_sales: Number(formData.totalSales || 0),
              platform: formData.platform
            }}
            streamItems={mappedStreamItems}
            settings={settings}
          />
        </CardContent>
      </Card>

      <Button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-white text-black hover:bg-gray-100"
      >
        {isSubmitting ? "Creating Stream..." : "Create Stream"}
      </Button>
    </form>
  );
};
