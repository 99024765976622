import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useState } from "react";
import { calculateStreamFinancials } from "@/utils/streamFinancials";
import { Eye } from "lucide-react";
import { Button } from "@/components/ui/button";
import { StreamDetailsDialog } from "@/components/streams/history/StreamDetailsDialog";
import { Tables } from "@/integrations/supabase/types";
import { useProfile } from "@/hooks/useProfile";

type Stream = Tables<"streams">;

export const RecentStreams = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedStreamForDetails, setSelectedStreamForDetails] = useState<Stream | null>(null);
  const { data: profile } = useProfile();

  const { data: settings } = useQuery({
    queryKey: ['userSettings', profile?.organization_id],
    enabled: !!profile?.organization_id && !!profile?.id,
    queryFn: async () => {
      try {
        if (!profile?.organization_id) {
          throw new Error('No organization ID available');
        }

        // First try to get admin settings
        const { data: adminProfiles, error: adminError } = await supabase
          .from('profiles')
          .select('id')
          .eq('organization_id', profile.organization_id)
          .eq('role', 'admin');

        if (adminError) {
          console.error('Error fetching admin profiles:', adminError);
          throw adminError;
        }

        let adminSettings = null;
        if (adminProfiles && adminProfiles.length > 0) {
          // Try each admin until we find settings
          for (const adminProfile of adminProfiles) {
            if (!adminProfile.id) continue;
            
            const { data: settings, error: settingsError } = await supabase
              .from('user_settings')
              .select('*')
              .eq('user_id', adminProfile.id)
              .eq('organization_id', profile.organization_id)
              .maybeSingle();
            
            if (settingsError) {
              console.error('Error fetching admin settings:', settingsError);
              continue;
            }
            
            if (settings) {
              adminSettings = settings;
              break;
            }
          }
        }

        // If no admin settings found, try to get user's own settings
        if (!adminSettings && profile.id) {
          const { data: userSettings, error: userSettingsError } = await supabase
            .from('user_settings')
            .select('*')
            .eq('user_id', profile.id)
            .eq('organization_id', profile.organization_id)
            .maybeSingle();

          if (userSettingsError) {
            console.error('Error fetching user settings:', userSettingsError);
          } else {
            adminSettings = userSettings;
          }
        }

        // Return default settings if nothing found
        return adminSettings || {
          tiktok_fee: 0,
          fanatics_fee: 0,
          shipping_cost_type: 'disabled',
          shipping_cost_value: 0,
          streamer_pay_type: 'disabled',
          streamer_pay_value: 0,
          sorter_pay_type: 'disabled',
          sorter_pay_value: 0
        };
      } catch (error) {
        console.error('Error fetching settings:', error);
        // Return default settings on error
        return {
          tiktok_fee: 0,
          fanatics_fee: 0,
          shipping_cost_type: 'disabled',
          shipping_cost_value: 0,
          streamer_pay_type: 'disabled',
          streamer_pay_value: 0,
          sorter_pay_type: 'disabled',
          sorter_pay_value: 0
        };
      }
    }
  });

  const { data: streams = [], isLoading } = useQuery({
    queryKey: ['recent-streams', profile?.organization_id, profile?.role, profile?.id, profile?.canViewAllStreams],
    enabled: !!profile,
    queryFn: async () => {
      console.log('Fetching streams for organization:', profile?.organization_id);
      
      let query = supabase
        .from('streams')
        .select(`
          *,
          stream_items (
            quantity_sold,
            sale_price,
            inventory_items (
              cost_basis
            )
          )
        `)
        .order('start_time', { ascending: false })
        .limit(10);

      // If user can view all streams in organization
      if (profile?.canViewAllStreams && profile?.organization_id) {
        query = query.eq('organization_id', profile.organization_id);
      } 
      // Users who can only see their own streams
      else if (profile?.id) {
        query = query.eq('user_id', profile.id);
      }

      const { data: streamsData, error } = await query;

      if (error) {
        console.error('Error fetching streams:', error);
        return [];
      }

      console.log('Raw streams data:', streamsData);

      if (!streamsData || streamsData.length === 0) {
        console.log('No streams found');
        return [];
      }

      return streamsData.map(stream => {
        const productCosts = stream.stream_items?.reduce((sum, item) => {
          const costBasis = Number(item.inventory_items?.cost_basis || 0);
          return sum + (Number(item.quantity_sold || 0) * costBasis);
        }, 0) || 0;

        const financials = calculateStreamFinancials({
          totalSales: Number(stream.total_sales || 0),
          productCosts,
          platform: stream.platform,
          settings
        });

        return {
          ...stream,
          financials
        };
      });
    }
  });

  if (isLoading) {
    return (
      <Card className="bg-dark-card border-dark-border">
        <CardHeader>
          <CardTitle className="text-xl font-semibold text-white">
            Recent Streams
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-700 rounded w-3/4" />
            <div className="h-4 bg-gray-700 rounded w-1/2" />
          </div>
        </CardContent>
      </Card>
    );
  }

  const StreamItem = ({ stream, showDetails = false }: { stream: any, showDetails?: boolean }) => (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-white font-medium">{stream.streamer}</p>
          <p className="text-sm text-gray-400">
            {format(new Date(stream.start_time), "MMM dd (yyyy)")}
          </p>
          <p className="text-sm text-gray-400">{stream.platform}</p>
        </div>
        <div className="flex items-center gap-4">
          <div className="text-right">
            <p className="text-white">Sales: ${Number(stream.total_sales).toLocaleString()}</p>
            <p className={stream.financials.netProfit >= 0 ? "text-green-500" : "text-red-500"}>
              Profit: ${Math.abs(stream.financials.netProfit).toLocaleString()}
            </p>
          </div>
          {showDetails && (
            <Button
              variant="ghost"
              size="icon"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedStreamForDetails(stream);
              }}
              className="text-gray-400 hover:text-white"
            >
              <Eye className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  const sortedStreams = [...(streams || [])].sort(
    (a, b) => new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
  );

  return (
    <>
      <Card 
        className="bg-dark-card border-dark-border cursor-pointer hover:scale-[1.02] transition-transform duration-200"
        onClick={() => setIsDialogOpen(true)}
      >
        <CardHeader>
          <CardTitle className="text-xl font-semibold text-white">
            Recent Streams
          </CardTitle>
        </CardHeader>
        <CardContent>
          {sortedStreams && sortedStreams.length > 0 ? (
            <div className="space-y-4">
              {sortedStreams.slice(0, 3).map((stream) => (
                <StreamItem key={stream.id} stream={stream} showDetails={false} />
              ))}
            </div>
          ) : (
            <div className="text-center py-6">
              <p className="text-gray-400">No streams yet</p>
              <p className="text-sm text-gray-500">Your recent streams will appear here</p>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="bg-dark-card border-dark-border text-white max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">
              Recent 10 Streams
            </DialogTitle>
            <DialogDescription className="text-gray-400">
              View details of your most recent streaming sessions
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 mt-4">
            {sortedStreams?.map((stream) => (
              <div key={stream.id} className="p-4 bg-dark-muted rounded-lg">
                <StreamItem stream={stream} showDetails={true} />
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>

      <StreamDetailsDialog
        stream={selectedStreamForDetails}
        onClose={() => setSelectedStreamForDetails(null)}
      />
    </>
  );
};
