
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { WholesaleCustomer } from "@/components/wholesale/types";

export const useWholesaleCustomers = () => {
  const queryClient = useQueryClient();

  // Fetch all customers
  const customersQuery = useQuery({
    queryKey: ['wholesale-customers'],
    queryFn: async () => {
      try {
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError || !user) {
          console.error('Authentication error:', authError);
          throw new Error('Please sign in to view customers');
        }

        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('organization_id')
          .eq('id', user.id)
          .maybeSingle();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
          throw new Error('Error loading profile data');
        }

        let query = supabase
          .from('wholesale_customers')
          .select('*')
          .order('name');

        if (profile?.organization_id) {
          query = query.eq('organization_id', profile.organization_id);
        } else {
          query = query.eq('user_id', user.id);
        }

        const { data, error } = await query;

        if (error) {
          console.error('Error fetching customers:', error);
          throw error;
        }

        return data as WholesaleCustomer[];
      } catch (error: any) {
        console.error('Error in useWholesaleCustomers:', error);
        throw error;
      }
    },
    staleTime: 60000,
  });

  // Create new customer
  const createCustomer = useMutation({
    mutationFn: async (customerData: Partial<WholesaleCustomer>) => {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('You must be logged in to create a customer');
      }

      const { data: profile } = await supabase
        .from('profiles')
        .select('organization_id')
        .eq('id', user.id)
        .maybeSingle();

      const { data, error } = await supabase
        .from('wholesale_customers')
        .insert({
          ...customerData, 
          user_id: user.id,
          organization_id: profile?.organization_id || null
        })
        .select()
        .single();

      if (error) {
        console.error('Error creating customer:', error);
        throw error;
      }

      return data;
    },
    onSuccess: () => {
      toast.success('Customer created successfully');
      queryClient.invalidateQueries({ queryKey: ['wholesale-customers'] });
    },
    onError: (error: any) => {
      toast.error(`Failed to create customer: ${error.message}`);
    },
  });

  // Update existing customer
  const updateCustomer = useMutation({
    mutationFn: async (customerData: Partial<WholesaleCustomer>) => {
      const { id, ...updateData } = customerData;
      
      if (!id) {
        throw new Error('Customer ID is required for updates');
      }

      const { data, error } = await supabase
        .from('wholesale_customers')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Error updating customer:', error);
        throw error;
      }

      return data;
    },
    onSuccess: () => {
      toast.success('Customer updated successfully');
      queryClient.invalidateQueries({ queryKey: ['wholesale-customers'] });
    },
    onError: (error: any) => {
      toast.error(`Failed to update customer: ${error.message}`);
    },
  });

  // Delete customer
  const deleteCustomer = useMutation({
    mutationFn: async (customerId: string) => {
      const { error } = await supabase
        .from('wholesale_customers')
        .delete()
        .eq('id', customerId);

      if (error) {
        console.error('Error deleting customer:', error);
        throw error;
      }

      return customerId;
    },
    onSuccess: () => {
      toast.success('Customer deleted successfully');
      queryClient.invalidateQueries({ queryKey: ['wholesale-customers'] });
    },
    onError: (error: any) => {
      toast.error(`Failed to delete customer: ${error.message}`);
    },
  });

  return {
    customers: customersQuery.data || [],
    isLoading: customersQuery.isLoading,
    error: customersQuery.error,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    refetch: customersQuery.refetch,
  };
};
